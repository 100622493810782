'use strict';
var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util'),
	progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
	carousel = require('./../../carousel'),
	dialog = require('./../../dialog');

var $cache = {},
	self = {},
	markersArray = [],
	citiesArray = [],
	selectedStoreMarker = {},
	markerCluster,
	isSearchMarker = false,
	storeMap,
	directionsService,
	directionsDisplay,
	mapOptions = {
		center: {lat: 45.464211, lng: 9.191383},
		zoom: 6,
		scrollwheel: false,
		disableDefaultUI: true,
		zoomControl: true,
		styles: []
	},
	options = {
		storeLocatorElements: '.js-storelocator-elements',
		storeLocatorDetails: '.js-storedetails-view',
		searchStoresForm: '.js-search-stores-form',
		searchMapHolder: '.js-map-holder',
		searchResultHolder: '.js-store-search-result-holder',
		searchNoResultHolder: '.js-storelocator-no-results',
		countrySelect: '.js-sa-country select',
		citySelect: '.js-sa-city select'
	};

self.init = function() {
	if (pageContext && pageContext.ns && pageContext.ns == 'storelocator') {
		initCache();
		initMapStyles();
		initMapLoadListener();
		initStorelocatorEvents();
		initGeolocationCheck();
		initStoremapDirectView();
		initHistoryEvents();
		initPrintEvents();
	}
	return this;
}

function initCache() {
	$cache.body = $('body');
	$cache.storeLocatorElements = $(options.storeLocatorElements);
	$cache.storeLocatorDetails = $(options.storeLocatorDetails);
	$cache.searchStoresForm = $(options.searchStoresForm);
	$cache.searchMapHolder = $(options.searchMapHolder);
	$cache.searchResultHolder = $(options.searchResultHolder);
	$cache.searchNoResultHolder = $(options.searchNoResultHolder);
	$cache.countrySelect = $(options.countrySelect);
	$cache.citySelect = $(options.citySelect);
}

function initHistoryEvents(){
	if('history' in window && 'pushState' in window.history){
		$(document).ready(function(){
			if(!window.history.state || !window.history.state.storelocatorURL){
				var url = window.location.href;
				window.history.replaceState({storelocatorURL: url}, 'stores search', url);
			}
		});
		window.onpopstate = function(event) {
			if(event.state && event.state.storelocatorURL){
				window.location = event.state.storelocatorURL;
			}
		};
		window.onpushstate = function(event) {
			if(event.state && event.state.storelocatorURL){
				window.location = event.state.storelocatorURL;
			}
		};
	}
}

function initPrintEvents() {
	var mapWidth = $cache.searchMapHolder.outerWidth(),
		mapHeight = $cache.searchMapHolder.outerHeight();

	$(window).on('resize.print', function(e) {
		mapWidth = $cache.searchMapHolder.outerWidth(),
		mapHeight = $cache.searchMapHolder.outerHeight();
	});

	// Use CSS-zoom to fit a map into a page, because Google Map API does not work on print page
	util.bindPrintEvents(
		function() {
			var zoom = $cache.searchMapHolder.outerWidth() / mapWidth;
			$('#map').css({
				'width': mapWidth,
				'height': mapHeight,
				'zoom': zoom
			});
		},
		function() {
			$('#map').css({
				'width': '',
				'height': '',
				'zoom': ''
			});
		}
	);
}

function initStoremapDirectView(){
	if(!$('#map').length){
		progress.show();

		initGoogleMapAPI(function() {
			var storeJSONData = $('.js-store-data');
			if(storeJSONData.length){
				var storeObj = JSON.parse(storeJSONData.html());
				initStoreMiniMap(storeObj);
			}

			progress.hide();
		});
	}
}
function getMapSelector(){
	return (location.hash) ? "#storemap" : "#map";
}
function initStorelocatorEvents(){
	$cache.searchStoresForm.on('submit', searchStores);
	$cache.countrySelect.on('change', countryChanged);

	$cache.body.on('click', '.js-getnearest-action', checkNearestStores);
	$cache.body.on('click', '.js-store_result-item', focusStoreMarkerItem);
	$cache.body.on('click', '.js-store-getdetails', showStoreDetails);
	$cache.body.on('click', '.js-storedetail-back', backToSearch);
	$cache.body.on('click', '.js-direction-mode div', selectDirectionMode);
	$cache.body.on('click', '.js-store-getdirection', getDirection);
	$cache.body.on('click', '.js-change-direction-address', backToDirectionForm);
	$cache.body.on('click', '.js-print-stores-search', printStoreDetails);
	$cache.body.on('click', '.js-share-stores-search', getShareStoreDialog);
}

function getShareStoreDialog(e){
	e.preventDefault();
	var dialogUrl = Urls.shareStoreDialogURL,
		storeID = $(this).data('storeid');
	if(dialogUrl && storeID){
		dialog.open({
			url: dialogUrl,
			options: {
				dialogClass: 'dialog-store_share js-share-dialog-ui',
				closeOnEscape: true,
				title: '',
				buttons:[{
						text: Resources.CONFIRM,
						'class': 'b-button js-confirm-btn',
						click: function(){
							sendShareStoreMail(storeID);
						}
					},{
						text: Resources.CLOSE,
						'class': 'b-button m-white js-close-btn',
						click: function(){
							$(this).dialog("close");
						}
					}
				]
			}
		});
	}

}

function sendShareStoreMail(storeID){
	var $shareStoreForm = $('.js-share-store-form');
	if($shareStoreForm.valid() && storeID){
		var shareUrl = $shareStoreForm.attr('action'),
			email = $('.js-storesearch-share-email').val();
		var sharingData = {email: email, storeid: storeID};
		$.ajax({
			url: shareUrl,
			method: 'GET',
			data: sharingData
		}).done(function (data) {
			if(data && data.success){
				$('.js-share-result-success').removeClass('hidden');
				$('.js-share-form-holder').addClass('hidden');
				$('.js-share-dialog-ui .js-confirm-btn').hide();
			}
		});
	}
}

function printStoreDetails(e){
	e.preventDefault();
	window.print();
}

function backToDirectionForm(e){
	e.preventDefault();
	$(this).addClass('hidden');
	$('.js-get-direction-form').show();
	$('.js-direction-route').addClass('hidden');
}

function selectDirectionMode(e){
	e.preventDefault();
	$('.js-direction-mode div').removeClass('active');
	$(this).addClass('active');
}

function backToSearch(e) {
	if ($cache.storeLocatorElements.length) {
		e.preventDefault();

		$cache.storeLocatorElements.removeClass('hidden');
		$cache.storeLocatorDetails.addClass('hidden');

		// force redraw search map to avoid problems after opening print window on store details page
		google.maps.event.trigger(self.getGoogleMap('#map'), 'resize');

		if('history' in window && 'pushState' in window.history){
			var url = $(this).attr('href');
			window.history.pushState({storelocatorURL: url}, 'stores search', url);
		}
	}
}

function initStoreMiniMap(storeObj){
	storeMap = self.getGoogleMap('#storemap', true);
	directionsService = new google.maps.DirectionsService;
	directionsDisplay = new google.maps.DirectionsRenderer;
	directionsDisplay.setMap(storeMap);
	selectedStoreMarker = {};
	if(storeObj){
		var storePosition = {lat: storeObj.lat, lng: storeObj.lng};
		var bounds = new google.maps.LatLngBounds();
		bounds.extend(storePosition);
		var marker = new google.maps.Marker({
			id: storeObj.ID,
			position: storePosition,
			map: storeMap,
			title: storeObj.name
		});
		marker.setMap(storeMap);
		storeMap.fitBounds(bounds);
		normalizeMapZoom(storeMap);
		prefillDirection(storeObj);
		selectedStoreMarker.marker = marker;
		selectedStoreMarker.pos = storePosition;
	}
}

function prefillDirection(storeObj){
	if(storeObj){
		var storeFullAddress = [];
		if('postalCode' in storeObj && storeObj.postalCode){
			storeFullAddress.push(storeObj.postalCode);
		}
		if('city' in storeObj && storeObj.city){
			storeFullAddress.push(storeObj.city);
		}
		if('address1' in storeObj && storeObj.address1){
			storeFullAddress.push(storeObj.address1);
		}
		if('address2' in storeObj && storeObj.address2){
			storeFullAddress.push(storeObj.address2);
		}
		if('countryCode' in storeObj && storeObj.countryCode){
			storeFullAddress.push(storeObj.countryCode);
		}
		storeFullAddress = storeFullAddress.join(', ');
		$('.js-destination-end').val(storeFullAddress);
	}
}

function showStoreDetails(e){
	e.preventDefault();
	var storeID = $(this).data('id');
	getStoreDetails(storeID, function() {
		util.scrollBrowser(0);
		$cache.storeLocatorDetails.removeClass('hidden');
		$cache.storeLocatorElements.addClass('hidden');
		addStoreUrlToHistory(storeID);
	});
}

function addStoreUrlToHistory(storeID){
	if('history' in window && 'pushState' in window.history && storeID){
		var storeUrlWithParams = Urls.getStore + '?storeid=' + storeID + '#storedetails';
		window.history.pushState({storelocatorURL: storeUrlWithParams}, storeID, storeUrlWithParams);
	}
}

function getStoreDetails(storeID, callback){
	var storeDetailURL = Urls.getStore,
		requestData = {storeid: storeID, format: 'ajax'},
		storeJSONData,
		storeObj;
	progress.show();
	$.ajax({
		url: storeDetailURL,
		method: 'GET',
		data: requestData
	}).done(function (data) {
		if (data && data.length) {
			$cache.storeLocatorDetails.html($(data));
			storeJSONData = $(data).find('.js-store-data');
			if(storeJSONData.length){
				storeObj = JSON.parse(storeJSONData.html());
				initStoreMiniMap(storeObj);
				carousel.init({
					"container": $cache.storeLocatorDetails,
					"autoplay": true
				});
			}
			(typeof callback == 'function') && callback();
		}
		progress.hide();
	});
}

function initGeolocationCheck(){
	if(navigator && navigator.geolocation && window.location.protocol.indexOf('https') > -1){
		$('.js-search-by-distance').removeClass('hidden');
	}
}

function handleLocationError() {

}

function searchNearestStores(position){
	var	searchURL = $cache.searchStoresForm.attr('action'),
	formData = $cache.searchStoresForm.serialize();
	formData += '&isNearestSearch=true';
	formData += '&lat='+ position.coords.latitude;
	formData += '&lng=' +position.coords.longitude;
	progress.show();
	$.ajax({
		url: searchURL,
		method: 'POST',
		data: formData
	}).done(function (data) {
		if(data){
			updateSearchView(data);
		}
		progress.hide();
	});
}

function checkNearestStores(e){
	e.preventDefault();
	if(navigator && navigator.geolocation) {
		navigator.geolocation.getCurrentPosition(function(position) {
			if(position.coords.latitude && position.coords.longitude){
				searchNearestStores(position);
			}
		}, function() {
			handleLocationError();
		});
	} else {
		// Browser doesn't support Geolocation
		handleLocationError();
	}
}

function getClasterWithCurrentMarker() {
	var storeName = $('.js-store_result-item.selected .b-store-name').text(),
		storeClusters = markerCluster.clusters_.filter(function(cluster) {
			if (cluster.markers_ && cluster.markers_.length > 1) {
				var storeMarker = cluster.markers_.filter(function(marker) {
					return marker.title == storeName;
				});
				return storeMarker.length;
			}
			return false;
		});
	return storeClusters.length ? storeClusters[0] : null;
}

function getMarkerPosition(marker) {
	if (markersArray && markersArray.length && marker) {
		var map = marker.getMap() || self.getGoogleMap('#map'),
			markerPosition = marker.getPosition(),
			normalize = checkNormalizeMapView();
		if (normalize) {
			var storeListPosition = $cache.searchResultHolder.position(),
				markerPoint = latLngToPoint(markerPosition, map);
			markerPosition = pixelToLatlng(
					markerPoint.x + (normalize.result ? ($cache.searchMapHolder.outerWidth() - storeListPosition.left) / 2 : 0),
					markerPoint.y - (normalize.search ? storeListPosition.top / 2 : 0),
					map
			);
		}
		return markerPosition;
	}
	return null;
}

function focusStoreMarkerItem(e) {
	e.preventDefault();
	var storeIndex = $(this).data('index'),
		currentMarker = markersArray[storeIndex],
		markerPosition = getMarkerPosition(currentMarker);
	if (markerPosition) {
		var map = currentMarker.getMap() || self.getGoogleMap('#map');
		map.panTo(markerPosition);
		isSearchMarker = true;
		google.maps.event.trigger(currentMarker, 'click', {});
	}
}

self.getGoogleMap = function(mapSelector, forceInit) {

	var $mapElement = $(mapSelector);

	if (!$mapElement.length) return false;

	var map = $mapElement.data('map');

	if (typeof map == 'undefined' || !!forceInit) {
		initMapStyles();
		map = new google.maps.Map($mapElement.get(0), mapOptions);
		$mapElement.data('map', map);
	}

	return map;
}

function getSelectedCountryCode() {
	return $cache.countrySelect.val();
}

function countryChanged() {
	fillCitySelect(getSelectedCountryCode());
}

function searchStores(e){
	e.preventDefault();

	var	searchURL = $(this).attr('action');
	var $formObj = $(this);
	var formData = $formObj.serialize();
	progress.show();

	$.ajax({
		url: searchURL,
		method: 'POST',
		data: formData
	}).done(function (data) {
		if(data){
			updateSearchView(data);
		}
		progress.hide();
	});
}

function updateSearchView(responseData){
	var searchResultJSONHolder = $(responseData).find('.js-stores-search-result-data');

	if (searchResultJSONHolder.length) {
		$cache.searchResultHolder.removeClass('hidden');
		$cache.searchNoResultHolder.addClass('hidden');

		$cache.searchResultHolder.html($(responseData).find('.js-stores-result-list').html());

		var map = self.getGoogleMap('#map', true);
		markersArray = self.updateMapMarkers(map, searchResultJSONHolder);
		markerCluster = initMarkerClusterer(map, markersArray);

		google.maps.event.addListener(map, 'idle', function () {
			if (isSearchMarker) {
				var storeCluster = getClasterWithCurrentMarker();
				if (storeCluster) {
					storeCluster.clusterIcon_.triggerClusterClick();
				} else {
					var storeIndex = $('.js-store_result-item.selected').data('index'),
						currentMarker = markersArray[storeIndex],
						markerPosition = getMarkerPosition(currentMarker);
					isSearchMarker = false;
					if (markerPosition) {
						map.panTo(markerPosition);
					}
				}
			}
		});
	} else {
		$cache.searchResultHolder.addClass('hidden');
		$cache.searchNoResultHolder.removeClass('hidden');
		initMap();
	}
}

self.updateMapMarkers = function(map, storesJSONHolder) {
	var markersArray = [];

	if (storesJSONHolder.length) {
		try {
			var stores = JSON.parse(storesJSONHolder.html());
			var bounds = new google.maps.LatLngBounds();
			var infowindow = new google.maps.InfoWindow();

			for(var i = 0; i < stores.length; i += 1){
				var storeObj = stores[i];
				var storePosition = {lat: storeObj.lat, lng: storeObj.lng};
				var markerContent = self.generateStoreIconContent(storeObj);

				bounds.extend(storePosition);

				var marker = new google.maps.Marker({
					id: i,
					position: storePosition,
					map: map,
					title: storeObj.name,
					icon: SitePreferences.STORE_LOCATOR_PIN,
					content: markerContent
				});

				marker.setMap(map);
				markersArray.push(marker);

				google.maps.event.addListener(marker, 'click', function () {
					infowindow.setContent(this.content);
					infowindow.open(map, this);
					unfocusStoreListItems();
					focusStoreListItem(this.id);
				});
			}
			map.fitBounds(bounds);
			normalizeMapView(map, bounds);
			normalizeMapZoom(map);
		} catch(e) { }
	}

	return markersArray;
}

function clearCitySelect() {
	$cache.citySelect.empty();
	$cache.citySelect.attr('disabled', 'disabled');
}

function fillCitySelect(countryCode) {
	clearCitySelect();
	$cache.citySelect.append( $("<option></option>").attr("value", '').text(Resources.CHOOSE_CITY) );

	if (!!citiesArray[countryCode] && citiesArray[countryCode].length) {
		$.each(citiesArray[countryCode], function(index, item) {
			$cache.citySelect.append( $("<option></option>").attr("value", item).text(item));
		});
		$cache.citySelect.removeAttr('disabled');
	}
}

function initMap(){
	self.getGoogleMap('#map', true);
	markersArray = [];
}

function initGoogleMapAPI(callback) {
	if ('google' in window) {
		callback();
	} else {
		$('#gmap-api').load(function(){
			callback();
		});
	}
}

function initMapLoadListener(){
	progress.show();

	initGoogleMapAPI(function() {
		initMap();
		initDefaultCountryStores();
		progress.hide();
	});
}

function initMapStyles() {
	try {
		mapOptions.styles = JSON.parse(SitePreferences.GOOGLE_MAP_STYLES);
	} catch(e) { }
}

function focusStoreListItem(index){
	var $storeElement = $('.js-store_result-item.item-' + index);
	if($storeElement.length){
		var $storeList = $('.js-store_result-list');
		$storeList.animate({scrollTop: $storeList.scrollTop() + $storeElement.position().top});
		$storeElement.addClass('selected');
	}
}

function unfocusStoreListItems(){
	var activeStoreListElement = $('.js-store_result-item.selected');
	if(activeStoreListElement.length){
		activeStoreListElement.removeClass('selected');
	}
}

self.generateStoreIconContent = function(storeObj){
	var htmlContentData = '<div class="b-store">';
	if(storeObj){
		if(storeObj.image){
			htmlContentData += '<div class="b-store-image"><img src="'+storeObj.image+'"/></div>';
		}
		if(storeObj.name){
			htmlContentData += '<div class="b-store-name">'+storeObj.name+'</div>';
		}
		if(storeObj.address1){
			htmlContentData += '<div class="b-store-address">'+storeObj.address1
			if(storeObj.address2){
				htmlContentData += ', ' + storeObj.address2;
			}
			htmlContentData += '</div>';
		}
		if(storeObj.storeHours){
			htmlContentData += '<div class="b-store-hours">'+storeObj.storeHours+'</div>';
		}
		if(storeObj.ID){
			htmlContentData += "<button class='b-store-link js-store-getdetails' data-id='" + storeObj.ID + "' type='button'>" + Resources.VIEW_STORE_DETAILS + "</button>";
		}
	}
	htmlContentData += '</div>';
	return htmlContentData;
}

function getDirection(e){
	e.preventDefault();
	var directionStart = $('.js-destination-start'),
		directionEnd = $('.js-destination-end'),
		directionType = $('.js-direction-mode .active').data('type');
	toggleDirectionsFieldsError();
	if(directionStart.val().length && directionEnd.val().length){
		calculateAndDisplayRoute(directionsService, directionsDisplay, directionStart.val(), directionEnd.val(), directionType.toUpperCase(), storeMap);
	}
}

function toggleDirectionsFieldsError(){
	var directionStart = $('.js-destination-start'),
		directionEnd = $('.js-destination-end'),
		directionStartError = $('.js-nostartpoint'),
		directionEndError = $('.js-noendpoint');
	if(!directionStart.val().length){
		directionStart.addClass('error');
		directionStartError.addClass('error');
		directionStartError.removeClass('hidden');
	}else{
		directionStart.removeClass('error');
		directionStartError.removeClass('error');
		directionStartError.addClass('hidden');
	}
	if(!directionEnd.val().length){
		directionEnd.addClass('error');
		directionEndError.addClass('error');
		directionEndError.removeClass('hidden');
	}else{
		directionEnd.removeClass('error');
		directionEndError.removeClass('error');
		directionEndError.addClass('hidden');
	}
}

function calculateAndDisplayRoute(directionsService, directionsDisplay, start, end, type, map) {
	directionsService.route({
		origin: start,
		destination: end,
		travelMode: type,
		drivingOptions: {
			departureTime: new Date(Date.now()),
			trafficModel: 'optimistic'
		},
		unitSystem: google.maps.UnitSystem.METRIC
	}, function(response, status) {
		var bounds = new google.maps.LatLngBounds();
		if (status === 'OK') {
			var responseBounds = response.routes[0].bounds;

			bounds.extend(responseBounds.getNorthEast());
			bounds.extend(responseBounds.getSouthWest());

			setCopyRight(response.routes[0].copyrights);
			showDirectionSteps(response.routes[0].legs[0]);
			directionsDisplay.setDirections(response);
			$('.js-get-direction-form').hide();
			$('.js-change-direction-address').removeClass('hidden');
			$('.js-no-result-direction').addClass('hidden');
			$('.js-direction-route').removeClass('hidden');
		} else {
			$('.js-no-result-direction').removeClass('hidden');
			$('.js-direction-route').addClass('hidden');
			$('.js-get-direction-form').show();
			$('.js-change-direction-address').addClass('hidden');
		}
		selectedStoreMarker.marker.setMap(map);
		bounds.extend(selectedStoreMarker.pos);
		map.fitBounds(bounds);
		normalizeMapZoom(map);
	});
}

function latLngToPoint(latLng, map) {
	var projection = map.getProjection(),
		topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast()),
		bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest()),
		scale = Math.pow(2, getNormalizedZoom(map.getZoom())),
		worldPoint = projection.fromLatLngToPoint(latLng);

	return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
}

function pixelToLatlng(xcoor, ycoor, map) {
	var projection = map.getProjection(),
		topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast()),
		bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest()),
		scale = Math.pow(2, getNormalizedZoom(map.getZoom())),
		worldPoint = new google.maps.Point(xcoor / scale + bottomLeft.x, ycoor / scale + topRight.y);

	return projection.fromPointToLatLng(worldPoint);
}

function normalizeMapView(map, bounds) {
	var normalize = checkNormalizeMapView();
	if (normalize) {
		// if the bounds are not initialized postpone normalize map view to their initialization
		if (!map.getBounds()) {
			google.maps.event.addListenerOnce(map, 'bounds_changed', function() {
				// if the projection are not initialized postpone normalize map view to their initialization
				if (map.getProjection()) {
					normalizeMapView(map, bounds);
					normalizeMapZoom(map);
				} else {
					google.maps.event.addListenerOnce(map, 'projection_changed', function() {
						normalizeMapView(map, bounds);
						normalizeMapZoom(map);
					});
				}
			});
		} else {
			var storeListPosition = $cache.searchResultHolder.position();
			if (map.getZoom() > 3) {
				var northEast = latLngToPoint(map.getBounds().getNorthEast(), map),
					newNorthEast = pixelToLatlng(
						northEast.x + (normalize.result ? $cache.searchMapHolder.outerWidth() - storeListPosition.left : 0),
						northEast.y - (normalize.search ? storeListPosition.top : 0),
						map
					);
				bounds.extend({lat: newNorthEast.lat(), lng: newNorthEast.lng()});
				map.fitBounds(bounds);
			} else {
				var center = latLngToPoint(map.getCenter(), map),
					newCenter = pixelToLatlng(
						center.x + (normalize.result ? ($cache.searchMapHolder.outerWidth() - storeListPosition.left) / 2 : 0),
						center.y - (normalize.search ? storeListPosition.top / 2 : 0),
						map
					);
				map.setCenter(newCenter);
			}
		}
	}
}

function checkNormalizeMapView() {
	// normalize view only if search form or result overlapped map
	var searchOverlap = util.elementsOverlapped($cache.searchStoresForm, $cache.searchMapHolder),
		resultOverlap = util.elementsOverlapped($cache.searchResultHolder, $cache.searchMapHolder);

	if (searchOverlap || resultOverlap) {
		return {'search': searchOverlap, 'result': resultOverlap};
	}
	return false;
}

function getNormalizedZoom(zoom) {
	return zoom > 14 ? 14 : zoom;
}

function normalizeMapZoom(map) {
	map.setZoom(getNormalizedZoom(map.getZoom()));
}

function showDirectionSteps(directionsData){
	var $stepsHolder = $('.js-direction-steps'),
		stepsHtml = '';
	if(directionsData && directionsData.steps){
		stepsHtml += '<div class="b-direction-route_header">';
		stepsHtml += '<span class="b-direction-route_distance">'+directionsData.distance.text+'</span> ';
		stepsHtml += '<span class="b-direction-route_duration">'+directionsData.duration.text+'</span>';
		stepsHtml += '</div>';
		stepsHtml += '<table class="b-direction-route_table">';
		for(var i = 0; i < directionsData.steps.length; i += 1){
			var stepObj = directionsData.steps[i];
			var instructions ='';
			if(stepObj.html_instructions){
				instructions = stepObj.html_instructions;
			}else if(stepObj.instructions){
				instructions = stepObj.instructions;
			}
			stepsHtml += '<tr class="b-route">';
			stepsHtml += '<td class="b-route-icon">' + (stepObj.maneuver? '<span class="b-maneuver '+stepObj.maneuver+'"></span>' : '') + '</td>';
			stepsHtml += '<td class="b-route-distance">' + (stepObj.distance ? stepObj.distance.text : '') + '</td>';
			stepsHtml += '<td class="b-route-duration">' + (stepObj.duration ? stepObj.duration.text : '') + '</td>';
			stepsHtml += '<td class="b-route-instructions">' + (instructions) + '</td>';
			stepsHtml += '</tr>';
		}
		stepsHtml += '</table>';
		$stepsHolder.html(stepsHtml);
	}
}

function setCopyRight(copyRightString){
	$('.js-copyright-map').text(copyRightString);
}

function initMarkerClusterer(map, markersArray) {
	if ((window.markerClusterer === undefined) && window.markerClusterer.MarkerClusterer) {
		if ($('.loader')) {
			console.log('Some important map scripts are blocked in private browsing mode or by your ad blocker, please open this page in a regular window to use the map!');
			$('.loader').addClass('private-mode-error');
		}
	}

	return new window.markerClusterer.MarkerClusterer(map, markersArray);
}

function initDefaultCountryStores() {
	var $storesDataHolder = $('.js-default-stores-data');
	var mapSelector = getMapSelector(),
		map = self.getGoogleMap(mapSelector, true);
	markersArray = self.updateMapMarkers(map, $storesDataHolder);
	markerCluster = initMarkerClusterer(map, markersArray);
	try {
		var stores = JSON.parse($storesDataHolder.html());
		stores.sort(function(a, b) {
			return (a.city > b.city) ? 1 : ((b.city > a.city) ? -1 : 0);}
		);

		$.each(stores, function(index, store) {
			if (!(store.countryCode in citiesArray)) {
				citiesArray[store.countryCode] = [];
			}
			if (!!store.city && ($.inArray(store.city, citiesArray[store.countryCode]) == -1)) {
				citiesArray[store.countryCode].push(store.city);
			}
		});
	} catch(e) { }
}

module.exports = self;
