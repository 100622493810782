'use strict';

var util = require('./util');

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */
function initializeEvents() {
	$(document).tooltip({
		items: util.isMobile() ? '.js-tooltip' : '.js-tooltip, .js-tooltip-desktop',
		tooltipClass: $('.js-tooltip').find('.js-tooltip-content').length ? 'b-tooltip' : 'b-tooltip-title',
		track: true,
		content: function () {
			var $tooltipContent = $(this).find('.js-tooltip-content');
			return $tooltipContent.length ? $tooltipContent.html() : $(this).attr('title');
		}
	});
}


module.exports = {
	init : function () {
		initializeEvents();
	}
}
