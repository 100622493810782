'use strict';

var ajax = require('./../../../app_storefront_core/cartridge/js/ajax'),
	minicart = require('./minicart'),
	util = require('./util'),
	$cache = {};


var setAddToCartHandler = function (e) {
	e.preventDefault();
	var form = $(this).closest('form');
	
	
	if (form.valid()) {
		var options = {
			url: util.ajaxUrl(form.attr('action')),
			method: 'POST',
			cache: false,
			data: form.serialize()
		};
		$.ajax(options).done(function (response) {
			if (response.success) {
				$.get(Urls.minicartGC, {
					lineItemId: response.result.lineItemId
				}, function (response) {
					form.find('span.error').hide();
					$cache.document.trigger("product.addedToCart", [response]);
				});
			} else {
				form.find('span.error').hide();
				for (var id in response.errors.FormErrors) {
					var $errorEl = $('#' + id).addClass('error').removeClass('valid').next('.error');
					if (!$errorEl || $errorEl.length === 0) {
						$errorEl = $('<label for="' + id + '" generated="true" class="error" style=""></label>');
						$('#' + id).after($errorEl);
					}
					$errorEl.text(response.errors.FormErrors[id].replace(/\\'/g, '\'')).show();
				}
			}
		});
	}
};


function initializeCache() {
	$cache.document = $(document);
	$cache.body = $('body');
}


function initializeEvents() {
	$cache.body
		.on('submit', '.js-check_gift_cert-form', function(e) {
			var $form = $(this),
				$messageElement = $('.js-check_gift_cert-message_container');
			$.post(Urls.checkGiftCert, {
				format: 'ajax',
				giftCertID: $form.find('input').val()
			},function(data) {
				if(data.success !== 'true') {
					$messageElement.addClass('error');
				}
				$messageElement.html(data.message);
			});
			e.preventDefault();
		})
		.on('click', '#AddToBasketButton', setAddToCartHandler);
	
	$('.js-gift_certificate-radio')
		.each(function(e) {
			$(this).find('.js-gift_certificate-value').html($(this).find('input:radio:checked').next('.f-label').text());
		})
		.on('change', ' input:radio', function(e) {
			$(e.delegateTarget).find('.js-gift_certificate-value').html($(this).next('.f-label').text());
		});
	
	initGiftCertificateAmount();
}

function initGiftCertificateAmount() {
	var $gcForm = $("#GiftCertificateForm"),
		$gcAmount = $gcForm.find('.js-gift_certificate-amount'),
		$gcAmountValue = $gcAmount.find('.js-gift_certificate-value'),
		$gcAmountField = $gcAmount.find('input:text'),
		$gcAmountOptions = $gcAmount.find('input:radio'),
		$gcCheckedOption = $gcAmountOptions.filter(':checked');
	
	if ($gcForm.size() > 0) { 
		$gcForm.validate();
		$gcAmountField
			.on('change', function(e) {
				if (!!$gcAmountField.val() && $gcAmountField.valid()) {
					$gcAmountOptions.prop('checked', false);
					$gcAmountValue.html($gcAmountField.val());
				} else if (!$gcAmountOptions.filter(':checked').size()) {
					$gcAmountValue.html($gcCheckedOption.prop('checked', true).val());
				}
			})
			.rules('add', {
				fractional_numbers: true,
				required: function() {
					return !$gcAmountOptions.filter(':checked').val();
				}
			});
		
		$gcAmountOptions.on('change', function(e) {
			$gcAmountField.val('').valid();
			$gcCheckedOption = $(this);
			$gcAmountValue.html($gcCheckedOption.val());
		});
		
		if ($gcCheckedOption) $gcAmountValue.html($gcCheckedOption.val());
	}
}


exports.init = function () {
	initializeCache();
	initializeEvents();
};
