'use strict';

var util = require('./../../util'),
	carousel = require('./../../carousel'),
	dialog = require('./../../dialog'),
	variant = require('./variant'),
	$cache = {};

/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
	
}


/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeEvents() {
	$('body').on('click', '.js-color_selection-link', function(e) {
		e.preventDefault();
		dialog.open({
			html: $('.js-color_selection-dialog').clone(),
			options: {
				'dialogClass': 'dialog-color_selection',
				'title': $(this).attr('title'),
				'open': function() {
					var $dialog = $(this),
						$items = $dialog.find('.js-color_selection-item'),
						currentIndex = $items.filter('.selected').index(),
						$current = $('<span class="owl-current">' + (currentIndex + 1) + '</span>');
					
					variant.init(this);
					
					$dialog
						.on('click', '.js-color_selection-select', function(e) {
							e.preventDefault();
							$dialog.find('.active .js-swatchanchor').click();
							$dialog.dialog('close');
						})
					.find('.js-color_selection-carousel')
						.after($('<div class="owl-counter"><span class="owl-total">' + $items.size() + '</span></div>').prepend($current))
						.on('initialized.owl.carousel changed.owl.carousel', function(e) {
							carouselCheckPosition(e);
						});
					
					carousel.init({
						"container": $dialog,
						"baseClass" : "js-color_selection-carousel",
						"containerSelector" : ".js-color_selection-wrapper",
						"loop": false,
						"startPosition": currentIndex
					});
					
					function carouselCheckPosition(e) {
						var currentIndex = e.item.index + 1;
						$current.text(currentIndex);
						
						if (currentIndex >= e.item.count) {
							$(e.target).find('.owl-next').addClass('h-hidden');
						} else {
							$(e.target).find('.owl-next').removeClass('h-hidden');
						}
						
						if (currentIndex <= 1) {
							$(e.target).find('.owl-prev').addClass('h-hidden');
						} else {
							$(e.target).find('.owl-prev').removeClass('h-hidden');
						}
					}
				}
			}
		});
	})

}


module.exports = {
	init : function () {
		initializeCache();
		initializeEvents();
	}
}
