'use strict';

var util = require('./util'),
	$cache = {},
	options = {
		scrollTrigger: 200,
		showTooltip: false,
		insertBlockSelector: 'body'
	};


function initializeParams(params) {
	options = $.extend(true, {}, options, params);
}


function insertButton() {
	var $buttonContainer = $('<div/>', {
			class: 'b-back_to_top-container'
		}),
		$button = $('<div class="js-back_to_top b-back_to_top-button ' + (options.showTooltip ? 'js-tooltip-desktop' : '') + '"><span class="b-back_to_top-tooltip js-tooltip-content">' + Resources.BACK_TO_TOP + '</span></div>');
	$buttonContainer.append($button);
	$(options.insertBlockSelector).append($buttonContainer)
}


function initializeCache() {
	$cache.window = $(window);
	$cache.backToTopBtn = $('.js-back_to_top');
}


function initializeEvents() {
	if ($cache.backToTopBtn.length) {
		$cache.window.on('load scroll', function() {
			backToTop();
		});
		$cache.backToTopBtn.on('click', function(e) {
			e.preventDefault();
			util.scrollBrowser(0);
		});
	}
}


function backToTop() {
	var scrollTop = $cache.window.scrollTop();

	if (scrollTop > options.scrollTrigger) {
		$cache.backToTopBtn.addClass('js-showed');
	} else {
		$cache.backToTopBtn.removeClass('js-showed');
	}
}


module.exports = {
	init: function(params) {
		initializeParams(params);
		insertButton();
		initializeCache();
		initializeEvents();
	}
};
