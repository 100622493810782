'use strict';

var addtocart = require('./product/addtocart'),
	page = require('./../page.js'),
	quickview = require('./../quickview'),
	dialog = require('./../dialog'),
	validator = require('./../../../../app_storefront_core/cartridge/js/validator'),
	util = require('./../../../../app_storefront_core/cartridge/js/util');

exports.init = function () {
	$('.js-editAddress').on('change', function () {
		page.redirect(util.appendParamsToUrl(Urls.wishlistAddress,
				{'AddressID': $(this).val(),
				'productlistid': $(this).data('currentwishlist')}
		));
	});

	//add js logic to remove the , from the qty feild to pass regex expression on client side
	$('.option-quantity-desired input').on('focusout', function () {
		$(this).val($(this).val().replace(',', ''));
	});

	$('.js-wishlist_list').on('click', '.js-setitem-availability input', function(e){
		var $triggeredElement = $(this),
			itemAvailabilityForm = $triggeredElement.closest('form'),
			formData,
			availabilityStatus = $triggeredElement.prop('checked') ? '_setItemPublic=true' : '_setItemPrivate=true',
			triggeredElementActionName;
			if(itemAvailabilityForm.length){
				triggeredElementActionName = itemAvailabilityForm.attr('name');
				formData = itemAvailabilityForm.serialize()+ '&'+triggeredElementActionName + availabilityStatus;
				$.post(Urls.wishlistSetPrivetURL, formData, function(){});
			}
	});
	$('.js-wishlist_list').on('click', '.js-wishlist-item_edit', function(e){
		e.preventDefault();
		quickview.show({
			url: this.href,
			source: 'wishlist'
		});
	});

	$('.js-wishlist').on('click', '#create-wishlist', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				open: function () {
					validator.init();
					$(dialog.$container).on('click', '.js-add_wishlist_submit', handleWishListCreation);
				}
			}
		});
	});

	function handleWishListCreation(e) {
		e.preventDefault();

		var $createNewWishlistForm = $(this).closest('.js-create_wishlist-form');
		if ($createNewWishlistForm.valid()) {
			var formData = $createNewWishlistForm.serializeArray();
			formData.push({
				name: this.name,
				value: this.value
			});

			$.ajax({
				type: 'POST',
				url: util.ajaxUrl($createNewWishlistForm.attr('action')),
				data: formData,
				success: function (result) {
					if (result.length) {
						dialog.replace({
							html: result
						});
						$createNewWishlistForm = dialog.$container.find('.js-create_wishlist-form');
						validator.initForm($createNewWishlistForm);
						$createNewWishlistForm.find('.js-wishlist_name input').one('change', function() {
							$(this).removeClass('error').siblings('.error-message').remove();
						});
					} else {
						window.location.href = Urls.wishlistShow;
					}
				}
			});
		} else {
			return;
		}
	}

	$('.js-move_item').on('click', function () {
		var moveTo = $(this).closest('.js-wishlist-item_field').find('.js-wishlist-select').val();
		var moveFrom = $(this).data('currentwishlist');
		var itemID = $(this).data('item');
		var productID = $(this).data('product');
		page.redirect(util.appendParamsToUrl(Urls.moveToWishlist,
			{'uuid': itemID,
			'productlistid': moveFrom,
			'newproductlistid': moveTo,
			'pid' : productID}
		));

	});

};
