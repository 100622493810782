'use strict';

function initializeDom() {
	$('.js-timer').each(function() {
		initializeTimer($(this));
	});
}


function getTimeObject(time) {
	var t = parseInt(time / 1000);
	return {
		'total': t,
		'days': Math.floor(t / (60 * 60 * 24)),
		'hours': Math.floor((t / (60 * 60)) % 24),
		'minutes':  Math.floor((t / 60) % 60),
		'seconds': Math.floor(t % 60)
	};
}

function getTimeRemaining(endTime) {
	return getTimeObject(endTime - Date.parse(new Date()));
}

function initializeTimer($timer) {
	var timerInterval,
		timerEndTime = Date.parse($timer.data('time')),
		$timerDays = $timer.find('.js-timer-days'),
		$timerHours = $timer.find('.js-timer-hours'),
		$timerMinutes = $timer.find('.js-timer-minutes'),
		$timerSeconds = $timer.find('.js-timer-seconds');
	
	checkClock();
	timerInterval = setInterval(checkClock, 1000);
	
	$timer.closest('.js-loading').removeClass('js-loading');
	
	function checkClock() {
		var t = getTimeRemaining(timerEndTime);

		if (t.total > 0) {
			updateClock(t);
		} else {
			clearInterval(timerInterval);
			updateClock(getTimeObject(0));
			
			var $timerShowElement = $($timer.data('show')),
				$timerHideElement = $($timer.data('hide'));
				
			$timerShowElement.removeClass('hidden');
			$timerHideElement.addClass('hidden');
		}
	}
	
	function updateClock(t) {
		$timerDays.html(t.days < 10 ? '0' + t.days : t.days);
		$timerHours.html(t.hours < 10 ? '0' + t.hours : t.hours);
		$timerMinutes.html(t.minutes < 10 ? '0' + t.minutes : t.minutes);
		$timerSeconds.html(t.seconds < 10 ? '0' + t.seconds : t.seconds);
	}
}


module.exports = {
	init: function () {
		initializeDom();
	}
};
