var util = require('./util'),
    tls = require('./tls'),
    searchsuggest = require('./searchsuggest'),
    dialog = require('./dialog'),
    $cache = {};

/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
    $cache = {
        window: $(window),
        document: $(document),
        html: $("html"),
        body: $("body"),
    };
}

/**
 * @description Moved from SG app.js
 */
function initializeEvents() {
    var controlKeys = [
        "8",
        "13",
        "46",
        "45",
        "36",
        "35",
        "38",
        "37",
        "40",
        "39",
    ];

    $cache.body
        .on("keydown", "textarea[maxlength]", function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).attr("maxlength"),
                charsUsed = text.length;

            if (
                charsUsed >= charsLimit &&
                controlKeys.indexOf(e.which.toString()) < 0
            ) {
                e.preventDefault();
            }
        })
        .on("change keyup mouseup", "textarea[maxlength]", function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).attr("maxlength"),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this)
                .siblings("div.char-count")
                .find(".char-remain-count")
                .html(charsRemain);
        })
        // add generic toggle functionality
        .on("click", ".toggle", function () {
            if (!$(this).hasClass("expanded")) {
                $(this)
                    .closest(".toggle-wrapper")
                    .find(".toggle.expanded")
                    .removeClass("expanded");
            }

            $(this).toggleClass("expanded");
        })
        .on("keypress", ".toggle", function (e) {
            if (
                e.charCode === 32 ||
                e.charCode === 13 ||
                ($(this).hasClass("expanded") && e.keyCode === 27)
            ) {
                e.preventDefault();
                $(this).click();
            }
        });

    $cache.body
        .find(".js-sticky_header .js-navigation-item a.has-sub-menu")
        .on("keyup", function (e) {
            if (e.keyCode === 32) {
                $(this)
                    .siblings(".js-main_navigation-dropdown")
                    .toggleClass("navigation-dropdown-visible");
            } else if (e.keyCode === 27) {
                $(this)
                    .siblings(".js-main_navigation-dropdown")
                    .removeClass("navigation-dropdown-visible");
            }
        })
        .on("keypress", function (e) {
            if (e.charCode === 32) {
                e.preventDefault();
            }
        });
    $cache.body
        .find(".js-sticky_header .js-main_navigation-dropdown a")
        .on("blur", function (e) {
            if (
                !$(e.relatedTarget).parents(".js-main_navigation-dropdown")
                    .length
            ) {
                $(this)
                    .parents(".js-main_navigation-dropdown")
                    .removeClass("navigation-dropdown-visible");
            }
        })
        .on("keyup", function (e) {
            if (e.keyCode === 27) {
                $(this)
                    .parents(".js-main_navigation-dropdown")
                    .removeClass("navigation-dropdown-visible")
                    .siblings("a")
                    .focus();
            }
        });
    $cache.body
        .find("#product-nav-container")
        .on("focus", "a.b-pdp-navigation_item", function (e) {
            $(this)
                .find(".b-pdp-navigation_image")
                .addClass("navigation-dropdown-visible");
        })
        .on("blur", "a.b-pdp-navigation_item", function (e) {
            $(this)
                .find(".b-pdp-navigation_image")
                .removeClass("navigation-dropdown-visible");
        });

    util.limitCharacters();
    var $searchContainer = $(".js-search-form-holder");
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    // Set links & sublinks as active for top menu
    function setCurrentMenuItem(item, link){
        if(item && link) {
            $(item).each(function (i) {
                var itemLink = $(this).find(link);
                var linkHref = itemLink ? itemLink.attr("href") : null;
                var regexp = linkHref ? new RegExp(linkHref) : null;
                if (
                    window.location.href.indexOf(linkHref) !== -1 ||
                    (regexp ? regexp.test(window.location.href) : false)
                ) {
                    $(this).addClass("b-navigation-active");
                }
            });
        }
    }
    setCurrentMenuItem(".b-navigation-top_item", ".b-navigation-top_link");

    if (util.isMobile()) {
        var windowTop;

        $cache.document
            //save current scroll position
            .on("dialogopen", function (event, ui) {
                windowTop = $(window).scrollTop();
                $("body").addClass("js-scroll-disabled");
                $("#wrapper").scrollTop(windowTop);
            })
            //set scroll position to prevent jumping to top of the page
            .on("dialogclose", function (event, ui) {
                $("body").removeClass("js-scroll-disabled");
                $(window).scrollTop(windowTop);
            })
            .on(
                "focus",
                "[type=email], [type=text], [type=password], textarea",
                function () {
                    $cache.body.addClass("js-keyboard_opened");
                }
            )
            .on(
                "blur",
                "[type=email], [type=text], [type=password], textarea",
                function () {
                    $cache.body.removeClass("js-keyboard_opened");
                }
            );
    }

    $(".js-navigation-mobile").each(function () {
        var $nav = $(this);

        $nav.on("click", ".active", function (e) {
            e.preventDefault();

            if ($nav.hasClass("js-inited")) {
                $nav.toggleClass("js-open");
            }
        });
    });

    if ($cache.window.innerWidth() < 1025) {
        initNavigationMobile();
    }

    $cache.window.on("resize", initNavigationMobile);

    function initNavigationMobile() {
        if ($cache.window.innerWidth() < 1025) {
            $(".js-navigation-mobile").each(function () {
                var $nav = $(this);

                if ($nav.find(".active").size() > 1) {
                    $nav.addClass("js-without-palceholder");
                }
                $nav.addClass("js-inited");
            });
        } else {
            $(".js-navigation-mobile").each(function () {
                var $nav = $(this);

                if ($nav.find(".active").size() > 1) {
                    $nav.removeClass("js-without-palceholder");
                }
                $nav.removeClass("js-inited");
            });
        }
    }

    // for correct hiding keyboard after closing dialogs with iframes on iOS-devices
    if (util.isIOS()) {
        $cache.document.on("dialogbeforeclose", function (event, ui) {
            if ($(event.target).find("iframe").length) {
                var $temporaryInput = $(
                    '<input type="text" class="visually-hidden" />'
                );
                $(event.target).prepend($temporaryInput);
                $temporaryInput.focus().blur().remove();
            }
        });
    }

    // Show random asset
    var $randomAssets = $(".js-random-asset"),
        randomAssetsSize = $randomAssets.size();

    if (randomAssetsSize > 1) {
        var prevIndex = tls.getCookie("previusAssetIndex"),
            currIndex = Math.floor(Math.random() * randomAssetsSize);

        if (currIndex == prevIndex) {
            currIndex++;
            currIndex >= randomAssetsSize && (currIndex = 0);
        }

        $randomAssets.eq(currIndex).show();
        tls.setCookie("previusAssetIndex", currIndex);
    } else if (randomAssetsSize == 1) $randomAssets.show();

    // Init spinner for number inputs
    initNumberInputs();
    registrationConsent();
}

/**
 * @function
 * @description Auto-check a mandatory email consent checkbox in register form
 * at registration and after placing order
 */
function registrationConsent() {
  var createAccountForm = $('form.f-create_account');
  if (createAccountForm) {
    var regSubmit = createAccountForm.find(".b-button");
    regSubmit.click(function() {
      $('#dwfrm_profile_customer_addtoemaillist').prop("checked", true);
    });
  }
}

/**
 * @function
 * @description Init spinner for number inputs
 */
function initNumberInputs(container) {
    var $container = $(container || "body");

    $numberInputs = $container.find('input[type="number"]');
    if ($numberInputs.size() > 0) {
        // Extend widget jQuery UI Spinner to remove from it focusing on field when clicking on plus/minus buttons
        $.widget("custom.customSpinner", $.ui.spinner, {
            _events: {
                "mousedown .ui-spinner-button": function (event) {
                    // This code is copied from jquery-ui.js
                    event.preventDefault();
                    if (this._start(event) === false) {
                        return;
                    }
                    this._repeat(
                        null,
                        $(event.currentTarget).hasClass("ui-spinner-up")
                            ? 1
                            : -1,
                        event
                    );
                },
            },
        });
        $numberInputs.customSpinner({
            icons: { down: "icon-minus", up: "icon-plus" },
            spin: function (event) {
                $(event.target).change();
            },
            stop: function (event) {
                var $spinerElement = $(event.target),
                    elValue = $spinerElement.val(),
                    elMaxVal = $spinerElement.attr("max");
                if (+elValue == +elMaxVal) {
                    $spinerElement
                        .parent()
                        .find("a.ui-spinner-up")
                        .addClass("js-disabled-spiner");
                } else {
                    $spinerElement
                        .parent()
                        .find("a.ui-spinner-up")
                        .removeClass("js-disabled-spiner");
                }
                $spinerElement.trigger("focusout");
            },
        });
    }

    $numberInputs.on("input", function () {
        if (this.maxLength !== -1 && this.value.length > this.maxLength) {
            this.value = this.value.slice(0, this.maxLength);
        }
        return;
    });
}

/**
 * @description Moved from SG app.js
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $cache.html.addClass("js");

    if (document.cookie.length === 0) {
        $("<div/>")
            .addClass("browser-compatibility-alert")
            .append(
                $("<p/>")
                    .addClass("browser-error b-cookies-error")
                    .html(Resources.COOKIES_DISABLED)
            )
            .appendTo("#browser-check:first");
    }
}

/**
 * @public
 * @function
 * @description Loads "lazy" iframes by copying document URL from "data-src" attribute to "src" attribute.
 */
function loadLazyIframes(container) {
    var $container = $(container || "body"),
        $lazyIframes = $container.find("iframe[data-src]");

    $lazyIframes.each(function (i, iframe) {
        var $iframe = $(iframe);

        if (!$iframe.attr("src") && $iframe.data("src")) {
            $iframe.attr("src", $iframe.data("src"));
        }
    });
}

module.exports = {
    init: function () {
        initializeCache();
        initializeDom();
        initializeEvents();
    },
    initNumberInputs: initNumberInputs,
    loadLazyIframes: loadLazyIframes,
};
