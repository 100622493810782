'use strict';

var $cache = {},
	layout = require('./layout'),
	initialized = false,
	options = {
		'container' : '',
		'options' : {
			'inner_scrolling' : false,
			'parent' : 'body'
		}
	};

function initializeParams(params) {
	options = $.extend(true, {}, options, params);
}

function initializeCache() {
	$cache.document = $(document);
	$cache.elements = $(options['container']);
}

function initializeEvents() {
	elementsInitialization();

	$cache.document
	.on('window.resize layout.reload', elementsReCalculation)
	.on('search.update', function (event, data) {
		elementsInitialization(data && !!data['container'] ? data['container'] : document);
	});
}

function extendDeviceConfig(data) {
	var options = {},
		dataNamePattern = /(desktop|tablet|mobile)(\w+)/,
		deviceMode = layout.getMode(),
		matched = [];
	
	for (var name in data) {
		if (matched = name.match(dataNamePattern)) {
			if (matched[1] !== deviceMode) continue;
			options[matched[2].toLowerCase()] = data[name];
		} else {
			options[name] = data[name];
		}
	}
	
	return options;
}

function elementsInitialization(container, data) {
	var $container = $(!!container && !(container instanceof $.Event) ? container : document),
		params = $.extend(true, {}, options, data);
	
	$container.find(params['container']).each(function (index, element) {
		var $element = $(element),
			optionsExtended = extendDeviceConfig($element.data()),
			options = $.extend({}, params['options'], optionsExtended || {});

		if (typeof params['options'].offset_top == 'function') {
			options.offset_top = params['options'].offset_top();
		}
		
		$element
		.stick_in_parent(options)
		.on("sticky_kit:bottom", function(e) {
			$(e.target).addClass('is_bottom');
		})
		.on("sticky_kit:unbottom", function(e) {
			$(e.target).removeClass('is_bottom');
		});
	});
}

function elementsReCalculation() {
	$(document.body).trigger('sticky_kit:recalc');
}

var stickykit = {
	init : function (params, reinitialize) {
		if (initialized && !reinitialize) return;

		if ('undefined' === typeof $.fn['stick_in_parent']) {
			$.getScript(Urls.staticBasePath + '/lib/jquery/jquery.sticky-kit.min.js').done((function (params) {
				return function (data) {
					require('./stickykit').init(params);
				};
			})(params));
			return;
		}

		initializeParams(params);
		initializeCache();
		initializeEvents();
		initialized = true;
	}
}

module.exports = stickykit;