'use strict';

var giftcert = require('./../giftcert'),
	tooltip = require('./../tooltip'),
	util = require('./../util'),
	dialog = require('./../dialog'),
	page = require('./../page.js'),
	login = require('./../login'),
	validator = require('./../../../../app_storefront_core/cartridge/js/validator'),
	addresscountry = require('./address/addresscountry'),
    adyenCheckout = require('./../../../../int_adyen_controllers/cartridge/js/pages/checkout/adyen-checkout'),
    countryvariants,
	selectedaddress;

    var selectors = {
        provinceSelect: '.js-province > select',
        citySelect: '.js-city > select',
        districtSelect: '.js-district > select'
    }

    function setOptions(arr, selector, setValue) {
        $(selector).empty();
        var options = [];

        for (var i = 0; i < arr.length; i++) {
            var item = arr[i];
            var option = new Option(item, item);
            if (setValue && item === setValue) {
                option.setAttribute("selected", "selected");
            }
            options.push(option);
        }

        $(selector).append(options);
    }

    function initSelectedCountryVariants() {
        // province
        var provinceList = Object.keys(countryvariants);
        // var setProvince = provinceList[0];
        var setProvince = selectedaddress && selectedaddress.province && provinceList.indexOf(selectedaddress.province) > -1 ? selectedaddress.province : provinceList[0];
        setOptions(provinceList, selectors.provinceSelect, setProvince);
        // ciry
        var cityList = Object.keys(countryvariants[setProvince]);
        var setCity = selectedaddress && selectedaddress.city && cityList.indexOf(selectedaddress.city) > -1 ? selectedaddress.city : cityList[0];
        setOptions(cityList, selectors.citySelect, setCity);

        // district
        var districtList = countryvariants[setProvince][setCity];
        var setDistrict = selectedaddress && selectedaddress.district && districtList.indexOf(selectedaddress.district) > -1 ? selectedaddress.district : null;
        setOptions(districtList, selectors.districtSelect, setDistrict);
    }

    function listenSelectedCountryVariants($form) {
        $form.on('change', selectors.provinceSelect, function(e) {
            var province = e.target.value;
            // city
            var cityList = Object.keys(countryvariants[province]);
            setOptions(cityList, selectors.citySelect);

            // district
            var districtList = countryvariants[province][cityList[0]];
            setOptions(districtList, selectors.districtSelect);
        });

        $form.on('change', selectors.citySelect, function(e) {
            var city = e.target.value;
            var province = $(selectors.provinceSelect).val();

            // district
            var districtList = countryvariants[province][city];
            setOptions(districtList, selectors.districtSelect);
        });
    }

/**
 * @function
 * @description Initializes the events on the address form (apply, cancel, delete)
 * @param {Element} form The form which will be initialized
 */
function initAddressForm() {
	var $form = $('#edit-address-form');
	if ($form.length === 0) return;

	countryvariants = countryvariants || $form.data() ? $form.data().countryvariants : null;
	selectedaddress = selectedaddress || $form.data() ? $form.data().selectedaddress : null;
	if (countryvariants) {
		initSelectedCountryVariants();
		listenSelectedCountryVariants($form);
	}

	addresscountry.hideStateField($form);

	initAddressFormCountrySelect($form);
	showFullNameField();

	$form.find('input[name="format"]').remove();
	tooltip.init();
	//$("<input/>").attr({type:"hidden", name:"format", value:"ajax"}).appendTo(form);

	$form.on('click', '.apply-button', function (e) {
			e.preventDefault();
			if (!$form.valid()) {
				return false;
			}
			var url = util.appendParamToURL($form.attr('action'), 'format', 'ajax');
			var applyName = $form.find('.apply-button').attr('name');
			var options = {
				url: url,
				data: $form.serialize() + '&' + applyName + '=x',
				type: 'POST'
			};
			$.ajax(options).done(function (data) {
				if (typeof (data) !== 'string') {
					if (data.success) {
						dialog.close();
						page.redirect(Urls.addressesList);
					} else if (data.error) {
						page.redirect(Urls.csrffailed);
					} else {
						window.alert(data.message);
						return false;
					}
				} else {
					$('#dialog-container').html(data);
					account.init();
					tooltip.init();
				}
			});
		})
		.on('click', '.delete-button', function (e) {
			e.preventDefault();
			if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
				var url = util.appendParamsToUrl(Urls.deleteAddress, {
					AddressID: $form.find('#addressid').val(),
					format: 'ajax'
				});
				$.ajax({
					url: url,
					method: 'POST',
					dataType: 'json'
				}).done(function (data) {
					if (data.status.toLowerCase() === 'ok') {
						alert(data.message);
						dialog.close();
						page.refresh();
					} else if (data.message.length > 0) {
						window.alert(data.message);
						return false;
					} else {
						dialog.close();
						page.refresh();
					}
				});
			}
		});

	validator.init();

	$(document).trigger('form.show');
}
/**
 * @private
 * @function
 * @description Toggles the list of Orders
 */
function toggleFullOrder() {
	$('.order-items')
		.find('li.hidden:first')
		.prev('li')
		.append('<a class="toggle">View All</a>')
		.children('.toggle')
		.click(function () {
			$(this).parent().siblings('li.hidden').show();
			$(this).remove();
		});
}
/**
 * @private
 * @function
 * @description Binds the events on the address form (edit, create, delete)
 */
function initAddressEvents() {
	initAddressForm();

	var addresses = $('#addresses');
	if (addresses.length === 0) {
		return;
	}

	addresses.on('click', '.address-edit, .address-create', function (e) {
		e.preventDefault();
		dialog.open({
			url: this.href,
			options: {
				dialogClass: 'dialog-create_address',
				open: initAddressForm
			}
		});
	}).on('click', '.delete', function (e) {
		e.preventDefault();
		if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
			$.ajax({
				url: util.appendParamToURL($(this).attr('href'), 'format', 'ajax'),
				dataType: 'json'
			}).done(function (data) {
				if (data.status.toLowerCase() === 'ok') {
					alert(data.message);
					page.redirect(Urls.addressesList);
				} else if (data.message.length > 0) {
					window.alert(data.message);
				} else {
					page.refresh();
				}
			});
		}
	});
}
/**
 * @private
 * @function
 * @description Binds the events of the payment methods list (delete card)
 */
function initPaymentEvents() {
	$('.add-card').on('click', function (e) {
		e.preventDefault();
		dialog.open({
			url: $(e.target).attr('href'),
			options: {
				open: initPaymentForm
			}
		});
	});

	var paymentList = $('.payment-list');
	if (paymentList.length === 0) {
		return;
	}

	util.setDeleteConfirmation(paymentList, String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD));

	$('form[name="payment-remove"]').on('submit', function (e) {
		e.preventDefault();
		// override form submission in order to prevent refresh issues
		var button = $(this).find('.delete');
		$('<input/>').attr({
			type: 'hidden',
			name: button.attr('name'),
			value: button.attr('value') || 'delete card'
		}).appendTo($(this));
		var data = $(this).serialize();
		$.ajax({
				type: 'POST',
				url: $(this).attr('action'),
				data: data
			})
			.done(function () {
				page.redirect(Urls.paymentsList);
			});
	});
}

function initPaymentForm() {
	$('#CreditCardForm').on('click', '.cancel-button', function (e) {
		e.preventDefault();
		dialog.close();
	});

	if (SitePreferences.ADYEN_SF_ENABLED)  {
		adyenCheckout.initAccount();
	}
}

/**
 * Initializes address form country select
 *
 * @param {jQuery} $form Address form
 */
function initAddressFormCountrySelect($form) {
	var $countrySelect = $form.find('select.country');

	$countrySelect.on('change', function () {
		var countryCode = $(this).val() || '';

        $form.trigger('form.address.country.change', {
            countryCode: countryCode
        });

		toggleStateSelect();
	});
}

/**
 * Toggles state select after country change
 *
 * @todo Bind this behavior to "form.address.country.change" form event
 * @todo Move all CSS selectors to a corresponding module config
 */
function toggleStateSelect() {
	var $countrySelect = $('#edit-address-form select.country'),
		$stateWrapper = $('#edit-address-form .js-state'),
		countryCode = $countrySelect.val() || '',
		allowedCountries = SitePreferences.COUNTRIES_WITH_STATES.split(','),
		formName = $countrySelect.attr('name').split('_').slice(1, -1).join('.');

	if (allowedCountries.indexOf(countryCode) > -1) {
		$.get(Urls.getStates, {
			code: countryCode,
			form: formName
		}, function (data) {
			var $data = $($.parseHTML(data)),
				newStateWrapper = $data.filter('div.js-state');

			$stateWrapper.replaceWith(newStateWrapper);
			$stateWrapper = newStateWrapper;
			$stateWrapper.show();
		});
	} else {
		$('#edit-address-form .js-state select').val('');

		$stateWrapper.hide();
	}
}

function showFullNameField() {
	var countrySelect = $('#edit-address-form select.country'),
		firstName = $("input[id$='_firstname']"),
		lastName = $("input[id$='_lastname']"),
		fullName = $("input[id$='_fullname']");
	setTimeout(function () {
		fieldsView();
		fildsLengthRestrict(countrySelect.val());
	}, 500);

	if (countrySelect.length) {
		countrySelect.on('change', function () {
			var $this = $(this),
				selectedCountry = $this.val();
				fieldsView();
				fildsLengthRestrict(selectedCountry);
		});
	}

	function fieldsView(val) {
		if (val) {
			firstName.parent().addClass('h-hidden');
			firstName.attr('disabled','disabled');
			lastName.parent().addClass('h-hidden');
			lastName.attr('disabled','disabled');
			fullName.parent().removeClass('h-hidden');
			fullName.attr('disabled',false);
		}else {
			firstName.parent().removeClass('h-hidden');
			firstName.attr('disabled',false);
			lastName.parent().removeClass('h-hidden');
			lastName.attr('disabled',false);
			fullName.parent().addClass('h-hidden');
			fullName.attr('disabled','disabled');
		}
	}
}

// fields length restriction
function fildsLengthRestrict(country) {
	var city = $("input[id$='_city']");

	if (country) {
		if (country === 'CN') {
			city.attr('minlength', 1);
		}else {
			city.attr('minlength', 3);
		}
	}
}

/**
 * @private
 * @function
 * @description Binds the events of the order, address and payment pages
 */
function initEvents() {
	toggleFullOrder();
	initAddressEvents();
	initPaymentEvents();
	login.init();
}

var account = {
	init: function () {
		initEvents();
		giftcert.init();
	},
	initCartLogin: function () {
		login.init();
	}
};

module.exports = account;
