const utilityMenu = document.querySelector('.js-sticky_header-wrapper');
const headerElement = utilityMenu ? utilityMenu.querySelector('.js-sticky_header') : null;
const buttonMenuElement = utilityMenu ? utilityMenu.querySelector('.b-utility_menu-link.js-utility_menu-link.menu-toggle.icon-menu') : null;

if(buttonMenuElement) {
  buttonMenuElement.addEventListener('click', function(e) {
    if (e.target.classList.contains('active')) {
      utilityMenu.classList.remove('b-utility_menu--grey-bg');
      headerElement.classList.remove('b-utility_menu--grey-bg');
    } else {
      utilityMenu.classList.add('b-utility_menu--grey-bg');
      headerElement.classList.add('b-utility_menu--grey-bg');
    }
  });
}

const hideMenuToggle = () => {
  if (document.querySelectorAll('.menu-item-toggle').length) {
    const menuToggle = document.querySelectorAll('.menu-item-toggle');

    if (window.innerWidth > 1023) {
      menuToggle.forEach(elem => {
        elem.style.display = 'none';
      });
    } else {
      menuToggle.forEach(elem => {
        elem.style.display = 'inline-block';
      });
    }
  }
}

const headerAnimation = () => {
  if (window.innerWidth > 1023) {
    const jsNavItem = document.querySelectorAll('.js-navigation-item');
    const navMenuLvl1 = document.querySelector('.b-navigation-level_1');
    const navMenuLvl2 = document.querySelectorAll('.b-navigation-level_2');
    const navMenuLvl3 = document.querySelectorAll('.b-navigation-level_3');

    const menuArrowNext = '<div class="menu-arrow-next"></div>';
    const menuArrowBack = '<div class="menu-arrow-back">Return back</div>';

    if (jsNavItem) {
      jsNavItem.forEach(item => {
        if ((item.querySelector('.b-navigation-dropdown') != null) || (item.querySelector('.b-navigation-menu') != null)) {

          item.insertAdjacentHTML('beforeend', menuArrowNext);
        }
      });
    }

    if (navMenuLvl2) {
      navMenuLvl2.forEach(menu => {
        menu.insertAdjacentHTML('afterbegin', menuArrowBack);
      });
    }

    if (navMenuLvl3) {
      navMenuLvl3.forEach(menu => {
        menu.insertAdjacentHTML('afterbegin', menuArrowBack);
      });
    }


    const adaptiveNav = () => {
      document.addEventListener('click', function (e) {
        if (e.target && e.target.classList.contains('menu-arrow-back')) {
          if (e.target.parentNode.classList.contains('b-navigation-level_3')) {
            e.target.parentNode.classList.remove('menu--active');
            e.target.closest('.b-navigation-level_2').classList.remove('nav--hide');
          } else if (e.target.parentNode.classList.contains('b-navigation-level_2')) {
            e.target.closest('.b-navigation-dropdown').classList.remove('menu--active');
            navMenuLvl1.classList.remove('nav--hide');
          }
        }

        if (e.target && e.target.classList.contains('menu-arrow-next')) {

          const navMenuLvl2 = e.target.parentNode.querySelector('.b-navigation-level_2');
          const navMenuLvl3 = e.target.parentNode.querySelector('.b-navigation-level_3');

          if (navMenuLvl2) {
            navMenuLvl2.parentNode.classList.add('menu--active');
            navMenuLvl1.classList.add('nav--hide');
          } else if (navMenuLvl3) {
            navMenuLvl3.classList.add('menu--active');
            e.target.closest('.b-navigation-level_2').classList.add('nav--hide');
          }
        }
      });
    }

    adaptiveNav();

    window.addEventListener('resize', () => {
      adaptiveNav();
    });
  }
}

hideMenuToggle();
headerAnimation();

window.addEventListener('resize', () => {
  hideMenuToggle();
  headerAnimation();
});

