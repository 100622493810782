'use strict';

var allowedCountries = SitePreferences.COUNTRIES_WITH_STATES.split(',');

function processStateDisplay(form) {
	var countrySelect = $(form || '.checkout-billing').find('select.country'),
		stateWrapper = $(form || '.checkout-billing').find('.state-select'),
		stateSelect = stateWrapper.find('select');

	if (countrySelect.length && stateSelect.length) {
		countrySelect.on('change', function (event) {
			var $this = $(this),
				selectedCountry = $this.val(),
				formName = $this.attr('name').split('_').slice(1, -1).join('.');
			if (allowedCountries.indexOf(selectedCountry) > -1) {
				$.get(Urls.getStates, {
					code: selectedCountry,
					form: formName
				}, function (data) {
					var $data = $($.parseHTML(data)),
						newStateWrapper = $data.filter('div.js-state');
					newStateWrapper.find('select').val($this.data('state'));
					stateWrapper.replaceWith(newStateWrapper);
					stateWrapper = newStateWrapper;
					stateWrapper.show();
					stateSelect.removeAttr('disabled');
					stateSelect.removeClass('disabled');
				});
			} else {
				stateSelect.val('');
				stateWrapper.hide();
			}
		});
	}
}

function hideStateField(form) {
	var stateWrapper = $(form).find('.state-select'),
		stateSelect = stateWrapper.find('select'),
		countrySelect = $(form).find('select.country');

	if (stateSelect.length && countrySelect.length && allowedCountries.indexOf(countrySelect.val()) == -1) {
		stateSelect.val('');
		stateWrapper.hide();
		stateSelect.attr('disabled', 'disabled');
		stateSelect.addClass('disabled');
	}
}

function showFullNameField(form, isCountrySelectEnabled) {
	var firstName, lastName, fullName;
	if (isCountrySelectEnabled) {
		firstName = $("input[id$='_firstName']");
		lastName = $("input[id$='_lastName']");
		fullName = $("input[id$='_fullName']");

		var countrySelect = $(form).find('select.country');
		setTimeout(function () {
				fieldsView();
				fildsLengthRestrict(countrySelect.val());
		}, 500);

		if (countrySelect.length) {
			countrySelect.on('change', function (event) {
				var $this = $(this),
					selectedCountry = $this.val();
					fieldsView();
					fildsLengthRestrict(selectedCountry);
			});
		}
	} else {
		fullName =  $(form).find('input[id$="_fullname"]');
		firstName =  $(form).find('input[id$="_firstname"]');
		lastName =  $(form).find('input[id$="_lastname"]');

		fieldsView();
	}

	function fieldsView(val) {
		if (val) {
			firstName.parent().addClass('h-hidden');
			firstName.attr('disabled','disabled');
			lastName.parent().addClass('h-hidden');
			lastName.attr('disabled','disabled');
			fullName.parent().removeClass('h-hidden');
			fullName.attr('disabled',false);
		}else {
			firstName.parent().removeClass('h-hidden');
			firstName.attr('disabled',false);
			lastName.parent().removeClass('h-hidden');
			lastName.attr('disabled',false);
			fullName.parent().addClass('h-hidden');
			fullName.attr('disabled','disabled');
		}
	}
}

//fields length restriction
function fildsLengthRestrict(country) {
	var city = $("input[id$='_city']");

	if (country) {
		if (country === 'CN') {
			city.attr('minlength', 1);
		}else {
			city.attr('minlength', 3);
		}
	}
}

exports.processStateDisplay = processStateDisplay;
exports.hideStateField = hideStateField;
exports.showFullNameField = showFullNameField;

exports.init = function () {
	processStateDisplay();
	showFullNameField();
};