'use strict';

var stickykit = require('./../../stickykit'),
	util = require('./../../util'),
	initialized = false,
	$cache = {},
	options = {
		'stickykit' : {
			'container': '.js-sticky-refinements',
			'options' : {
				'offset_top' : function() {
					return $('.js-sticky_header').height();
				},
				'parent': '#main',
				'bottoming' : false
			}
		}
	};


/**
 * @private
 * @function
 * @description Initializes parameters for component
 */
function initializeParams(params) {
	options = $.extend(true, {}, options, params);
}


/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
	
}


/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeEvents() {
	stickykit.init(options.stickykit);

	$(document)
	.on('window.resize layout.reload', function() {
		stickykit.init(options.stickykit, true);
	})
	.on('search.update', function(event, data) {
		!data.istriggered && util.scrollBrowser(0, 1000);
	});
}


module.exports = {
	init : function (params) {
		if (initialized) return;
		
		initializeParams(params);
		initializeCache();
		initializeEvents();
		initialized = true;
	}
}
