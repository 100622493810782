var config = require("./config"),
  context = window.pageContext,
  dataLayer = window.dataLayer,
  options = {
    addToCart: ".js-add-to-cart",
    addToWishlist: ".js-add-to-wishlist",
    dynamicProductsContainer: ".js-search-result-items:last",
    productTile: ".js-product_tile",
    productTileLinks: ".js-product_link, .js-product_name",
    productListItem: ".js-product_tile .js-product_link",
    cartProductRow: ".js-row-product-data",
    cartNumberInput: ".js-cart_quantity_field",
    cartPlusItems: ".ui-spinner-button:has(.icon-plus)",
    cartMinusItems: ".ui-spinner-button:has(.icon-minus)",
    removeProductLink: ".js_remove-product-line-item",
    regBtn: ".b-login-item_register button[name='dwfrm_login_register'], .f-create_account button[name='dwfrm_profile_confirm']",
    allLoginBtns: "button[name='dwfrm_login_login'], button[name='dwfrm_login_unregistered'], .b-login-item_social .b-button-social",
    loginBtn: ".js-login-form button[name='dwfrm_login_login']",
    loginBtnSocial: ".b-login-item_social .b-button-social",
    loginFrame: ".b-login-iframe"
  };

/****************************************************
 * Main function to push events to dataLayer
 ****************************************************/
function pushEvent(eventData, type) {

  let firstPush = true;
  let key;
  if (dataLayer) {
    for (let i = 0; dataLayer.length > i; i++) {
      if (dataLayer[i]) {
        if (dataLayer[i].event === eventData.event) {
          firstPush = false;
          key = i;
        }
      }
    }
    // The first push on a page
    if (firstPush) {
      dataLayer.push(eventData);
    } else {
      // The second one, clear & push
      if (dataLayer[key]) {
        dataLayer.push({ecommerce: null});
        dataLayer.push(eventData);
      } else {
        dataLayer.push(eventData);
      }
    }
  }
}

/****************************************************
 * Add checkout page type to global DL vars section
 ****************************************************/
function setCheckoutPageInDL(selector, checkoutPage) {
  if ($(selector).length > 0) {
    let globalVarsPart = dataLayer.find(obj => obj.page_type);
    if (globalVarsPart) {
      globalVarsPart.checkout_page = checkoutPage;
    }
  }
}

/****************************************************
 * Convert product item object to GTM format
 ****************************************************/
function convertProductItems(rawData) {

  let convertedItemsArr = [];

  if (rawData && rawData.length) {
    rawData.forEach(function(item) {
      let convertedItem = {};
      if (item) {
        convertedItem.item_name = item.name || "";
        convertedItem.item_id = item.id || "";
        convertedItem.price = item.price || 0;
        convertedItem.initial_price = item.initial_price || 0;
        convertedItem.item_variant = item.variant || "";
        convertedItem.quantity = item.quantity || 1;
        convertedItem.item_category = item.category || "";
        convertedItem.item_brand = item.brand || "";
        convertedItem.item_list_name = config.productListing || "";
        if (item.pid) {
          convertedItem.item_pid = item.pid || "";
        }
        if (item.fbid) {
          convertedItem.item_fb = item.fbid || "";
        }
      }
      convertedItemsArr.push(convertedItem);
    });
  }
  return convertedItemsArr;
}

/****************************************************
 * Add productID & hybrid ID (for Facebook events)
 * when a size has been selected,
 * or onload if product has no sizes,
 * skip adding pid if only color has changed,
 * but size hasn't been selected yet
 ****************************************************/
function addPid(data, contextSKU) {
  let htmlPid = document.getElementById("pid") ? document.getElementById("pid").value : null,
    contextPid = context.productPID ? context.productPID : null,
    pid = contextPid ? contextPid : htmlPid,
    prodSize;
  if (data.length && pid !== null) {
    if (pid !== contextSKU) {
      prodSize = pid.split("_")[3] || "0";

      data[0].item_pid = pid;
      data[0].item_fb = contextSKU + "_" + prodSize;
    } else {
      data[0].item_pid && delete data[0].item_pid;
    }
    return data;
  }
}

module.exports = {
  /****************************************************
   * View PLP & search results;
   * Collect products considering different types of search results rendering:
   * infinite scroll or pagination
   ****************************************************/
  view_item_list: function() {

    let productData = getProductsListFromPage($(options.productListItem));
    // First event is fired on page load
    doPush(productData, "pageload");

    // Second dynamic event is fired on infinite scroll
    $(window).on("infinitescroll.loaded", function() {
      productData = getProductsListFromPage(
        $(options.dynamicProductsContainer).find(options.productListItem)
      );
      doPush(productData, "infinitescroll");
    });

    // Second dynamic event is fired on pagination change
    $(document).on("search.update", function() {
      productData = getProductsListFromPage($(options.productListItem));
      doPush(productData, "search.update");
    });

    function getProductsListFromPage(productsContainer) {

      let rawProductsData = [];
      let productsData = [];

      if (productsContainer.length) {
        $.each(productsContainer, function() {
          let rawData = $(this).data();
          rawProductsData.push(rawData);
        });
        productsData = convertProductItems(rawProductsData);
        return productsData;
      }
    }


    function doPush(productData, why) {
      let event = {
        "event": "view_item_list",
        "ecommerce": {
          "currency": context.currencyCode || "",
          "items": productData
        }
      };
      pushEvent(event, `view_item_list - ${why}`);
    }
  },

  /****************************************************
   * View quick view & PDP,
   * item_id should be SKU - GTM doesn't care about exact ID with size/color
   * item_pid should be SKU or product ID after size selection - for 3rd-party integrations like facebook
   ****************************************************/
  view_item: function() {
    ////// Quick View on PLP

    if (context.ns === "search") {
      $(document).on("quickview.opened", function() {
        let productDataRaw = $(".b-pdp-add_to_cart").data(),
          productData = convertProductItems([productDataRaw]);

        addPid(productData, context.productID);

        let event = {
          "event": "view_item",
          "ecommerce": {
            "currency": context.currencyCode || "",
            "items": productData
          }
        };
        pushEvent(event, "view_item");

        // Push once again after size selection, also add productID with size
        $(document).on("product.variation.changed", function() {
          addPid(productData, context.productID);
          productData[0].item_variant = context.productColor || "";
          productData[0].item_id = context.productID || "";
          pushEvent(event, "view_item");
        });
      });
    }

    ////// PDP view
    if (context.ns === "product") {
      let currentProduct = [{
          "item_id": context.productID || "",
          "item_name": context.productName || "",
          "item_brand": context.productBrand || "",
          "item_category": context.productCategory || "",
          "item_variant": context.productColor || "",
          "price": context.productPrice || 0,
          "initial_price": context.initial_price || 0
        }],
        event = {
          "event": "view_item",
          "ecommerce": {
            "currency": context.currencyCode || "",
            "items": currentProduct
          }
        };

      addPid(currentProduct, context.productID);

      pushEvent(event, "view_item");

      // Push once again after size selection, also add productID with size
      // Skip adding pid if only color has changed, and size hasn't been selected yet
      $(document).on("product.variation.changed", function() {
        addPid(currentProduct, context.productID);
        currentProduct[0].item_variant = context.productColor || "";
        currentProduct[0].item_id = context.productID || "";
        pushEvent(event, "view_item");
      });
    }
  },

  /****************************************************
   * Watch product click on search results
   ****************************************************/
  select_item: function() {
    $(options.productTileLinks).on("click", function() {
      let productDataRaw = $(this).closest(options.productTile).find(".js-product_link").data(),
        productData = convertProductItems([productDataRaw]),
        event = {
          "event": "select_item",
          "ecommerce": {
            "currency": context.currencyCode || "",
            "items": productData || []
          }
        };
      pushEvent(event, "select_item");
    });
  },

  /****************************************************
   * Watch adding to Cart & increasing number in Cart
   ****************************************************/
  add_to_cart: function() {
    // Watch on PLP (quick view), PDP or wishlist
    if (context.ns === "product" || context.ns === "search" || context.ns === "wishlist") {
      $(document).on("click", options.addToCart, function() {
        let productDataRaw = $(this).data();
        doPush(productDataRaw);
      });
    }

    // Watching "+" icon in cart
    if (context.ns === "cart") {
      $(document).on("click", options.cartPlusItems, function() {
        let productDataRaw = $(this).closest(options.cartProductRow).find(options.removeProductLink).data();
        doPush(productDataRaw);
      });
    }

    function doPush(productDataRaw) {
      productDataRaw.quantity = 1;
      let productData = convertProductItems([productDataRaw]);
      addPid(productData, context.productID);
      let event = {
        "event": "add_to_cart",
        "ecommerce": {
          "currency": context.currencyCode || "",
          "items": productData || []
        }
      };
      pushEvent(event, "add_to_cart");
    }
  },

  /****************************************************
   * Watch removal or decreasing number of items in cart
   ****************************************************/
  remove_from_cart: function() {
    // Using "remove" link
    $(options.removeProductLink).on("click", function() {
      let productDataRaw = $(this).data();
      let quantityInput = $(this).closest(options.cartProductRow).find(options.cartNumberInput);
      productDataRaw.quantity = quantityInput ? parseInt(quantityInput.val()) : 1;
      doPush(productDataRaw);
    });

    // Using "-" icon
    $(options.cartMinusItems).on("click", function() {
      let productDataRaw = $(this).closest(options.cartProductRow).find(options.removeProductLink).data();
      productDataRaw.quantity = 1;
      doPush(productDataRaw);
    });

    function doPush(productDataRaw) {
      let productData = convertProductItems([productDataRaw]),
        event = {
          "event": "remove_from_cart",
          "ecommerce": {
            "currency": context.currencyCode || "",
            "items": productData || []
          }
        };
      pushEvent(event, "remove_from_cart", productData);
    }
  },

  /****************************************************
   * Watching adding to wishlist on PDP & Cart
   ****************************************************/
  add_to_wishlist: function() {
    $(document).on("click", options.addToWishlist, function() {
      let productDataRaw;
      if (context.ns === "product") {
        productDataRaw = $(options.addToCart).length ? $(options.addToCart).data() : [];
      } else if (context.ns === "cart") {
        productDataRaw = $(this).closest(options.cartProductRow).find(options.removeProductLink).data();
      }

      let productData = convertProductItems([productDataRaw]),
        event = {
          "event": "add_to_wishlist",
          "ecommerce": {
            "currency": context.currencyCode || "",
            "items": productData || []
          }
        };
      pushEvent(event, "add_to_wishlist");
    });
  },

  /****************************************************
   * Watch viewing the Cart
   ****************************************************/
  view_cart: function() {
    let productDataRaw = context ? context.products : [],
      productData = convertProductItems(productDataRaw),
      event = {
        "event": "view_cart",
        "ecommerce": {
          "currency": context.currencyCode || "",
          "items": productData || []
        }
      };
    pushEvent(event, "view_cart");
  },

  /****************************************************
   * This event is divided into several ones for each checkout step:
   * begin_checkout, add_shipping_info, add_payment_info, place_order
   ****************************************************/
  checkout: function() {
    let productDataRaw = context ? context.products : [],
      productData = convertProductItems(productDataRaw);

    if (config.checkoutSteps.length > 0) {
      config.checkoutSteps.forEach(function(step, i) {
        setCheckoutPageInDL(step.selector, step.checkoutPage);

        let event = {
          "event": step.eventName,
          "ecommerce": {
            "currency": context.currencyCode || "",
            "value": context.transactionTotal || 0,
            "items": productData || []
          }
        };

        if ($(step.selector) && $(step.selector).length) {
          // First event push is done if selector exists = on page load
          // The second push is done on page submit to watch if shipping/payment method has been changed

          // Send event on clicking "Checkout" in cart
          if (step.checkoutPage === "checkout_cart") {
            $(step.selector).on("click", function() {
              pushEvent(event, step.eventName);
            });
          }

          // Send a small event on auth page
          if (step.checkoutPage === "checkout_auth") {
            delete event.ecommerce;
            pushEvent(event, step.eventName);
          }

          // Shipping method selection
          if (step.checkoutPage === "checkout_shipping") {
            // Send event on page load, after delay because shipping methods are loading slow;
            setTimeout(function() {
              let selectedMethod = $("#shipping-method-list .input-radio:checked");
              event.ecommerce.shipping_tier = selectedMethod.length ?
                selectedMethod.parent().find(".b-shipping_method-name").text() : "";
              pushEvent(event, step.eventName);
            }, 2000);


            // And send again on page submit
            $(step.selector).on("click", function() {
              let selectedMethod = $("#shipping-method-list .input-radio:checked");
              event.ecommerce.shipping_tier = selectedMethod.length ?
                selectedMethod.parent().find(".b-shipping_method-name").text() : "";
              pushEvent(event, step.eventName);
            });
          }

          // Payment method selection
          if (step.checkoutPage === "checkout_billing") {
            // Send event on page load, after delay because payment methods are loading slow;
            // avoid capturing a hidden AdyentComponent input
            setTimeout(function() {
              let selectedMethod = $(".b-checkout-payment_methods div:not('.hidden') .paymentMethod input:checked");
              event.ecommerce.payment_type = selectedMethod.length ?
                selectedMethod.parent().find(".f-label").text().trim() : "";
              pushEvent(event, step.eventName);
            }, 2000);

            // And send again on page submit
            $(step.selector).on("click", function() {
              let selectedMethod = $(".b-checkout-payment_methods input:checked");
              event.ecommerce.payment_type = selectedMethod.length ?
                selectedMethod.parent().find(".f-label").text().trim() : "";
              pushEvent(event, step.eventName);
            });
          }

          // Send event on confirmation page load (pre-final step)
          if (step.checkoutPage === "checkout_place_order") {
            delete event.ecommerce;
            pushEvent(event, step.eventName);
          }
          // The next orderconfirmation (thanks) page is processed separately with "purchase" event
        }
      });
    }
  },
  /****************************************************
   * Watch order completion on the last checkout step - orderconfirmation
   ****************************************************/
  purchase: function() {
    let productDataRaw = context ? context.products : [],
      productData = convertProductItems(productDataRaw),
      event = {
        "event": "purchase",
        "ecommerce": {
          "transaction_id": context.transactionID || "",
          "currency": context.currencyCode || "",
          "value": context.transactionTotal || 0,
          "tax": context.transactionTax || 0,
          "shipping": context.transactionShipping || 0,
          "items": productData || []
        }
      };
    pushEvent(event, "purchase");

  },


  /****************************************************
   * Watch when a user logs in either to their account or via socials,
   * triggered on login popup or on a separate page
   ****************************************************/
  account_login: function() {
    // TODO watch login popup trigger (iframe)
    if (options.loginBtn || options.loginBtnSocial) {
      $(options.allLoginBtns).on("click", function(e) {
        let method;
        // Regular login
        if (e.target.name && e.target.name.includes("dwfrm_login_login")) {
          method = "account";
        }
        // Guest login
        else if (e.target.name && e.target.name.includes("dwfrm_login_unregistered")) {
          method = "guest";
        }
        // Login via socials
        else if (e.target.name && e.target.className.includes("b-button-social")) {
          method = "social";
        }

        let event = {
          "event": "account_login",
          "login_method": method
        };
        pushEvent(event, "account_login");
      });
    }

  },

  /****************************************************
   * Watch when a user starts registration
   ****************************************************/
  account_register: function() {
    // TODO watch login popup trigger (iframe)
    if ($(options.regBtn)) {
      let event = {
        "event": "account_register",
      },
      regform = $("#RegistrationForm"),
      isFormValid;

      if (!regform.length) {
        return false;
      }

      $(options.regBtn).on("mouseenter", function() {
        regform.validate();
        isFormValid = regform.valid();
      });
      $(options.regBtn).on("click", function() {
        if(isFormValid) {
          pushEvent(event, "account_register");
        }
      });
    }
  }

};
