"use strict";
var initialized = false,
    util = require('./../../../app_storefront_core_UI/cartridge/js/util'),
    commonElements = require('./../../../app_storefront_core_UI/cartridge/js/common-elements'),
    validator = require('./../../../app_storefront_core/cartridge/js/validator'),
    quickview = require('./../../../app_storefront_core_UI/cartridge/js/quickview'),
    progress = require('./../../../app_storefront_core/cartridge/js/progress');
/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeEvents() {
    $(document)
        .on(
            "product.variation.changed dialogopen",
            function (event, customEvent) {
                commonElements.initNumberInputs(
                    customEvent.container || event.target
                );
                validator.init();
            }
        )
        .on("click", ".js-order_product-edit_link", function (e) {
            e.preventDefault();
            progress.show($(".search-result-content"));
            quickview.show({
                url: this.href,
                source: "quickview",
                callback: function () {
                    progress.hide();
                },
            });
        });
}
module.exports = {
    init: function (params) {
        if (initialized) return;
        initializeEvents();
        initialized = true;
    },
};