'use strict';

var $cache = {},
	options = {},
	util = require('./../util'),
	isOrderFound = false;

var returnform = {
	init: function(params) {
		initializeConfig(params);
		if ($(options.findOrderForm).length) {
			initializeCache();
			initializeEvents();
			changeFindOrderBy();
		}
	}
};

function initializeConfig(params) {
	$.extend(options, params || {});
}

function initializeCache() {
	$cache.findOrderForm = $(options.findOrderForm);
	$cache.findOrderBy = $('.js-findOrderBy select');
	$cache.orderEmail = $('.js-orderEmail');
	$cache.postalCode = $('.js-postalCode');
}

function initializeEvents() {
	initValidation( $cache.findOrderForm);
	$cache.findOrderBy.on('change', changeFindOrderBy);
	$cache.findOrderForm.on('submit', findOrder);
}

function initValidation(form) {
	form.validate();
}

function changeFindOrderBy() {
	if ($cache.findOrderBy.val() == 'orderEmail') {
		$cache.orderEmail.show();
		$cache.postalCode.hide();
	} else if ($cache.findOrderBy.val() == 'postalCode'){
		$cache.postalCode.show();
		$cache.orderEmail.hide();
	}
}
function findOrder(e) {
	var $form = $cache.findOrderForm;
	if ($form.valid()) {
		if (!isOrderFound) {
			e.preventDefault();
			initValidation( $cache.findOrderForm);
			$.ajax({
				type: 'POST',
				url: util.ajaxUrl(Urls.submitFindOrderForm),
				data: $form.serialize()
			}).success(function (response) {
				if (!response.success) {
					$form.find('.js-findordernerror').addClass('error-message').removeClass('hidden');
				} else {
					isOrderFound = true;
					$form.submit();
				}
			});
		}
	} else {
		$form.find('.error-message').hide();
		return false;
	}
}
module.exports = returnform;
