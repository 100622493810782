'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	formPrepare = require('./formPrepare'),
	progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
	tooltip = require('./../../tooltip'),
	util = require('./../../../../../app_storefront_core/cartridge/js/util'),
	addresscountry = require('./../address/addresscountry'),
	instorepickup = require('./instorepickup'),
    zipCodeValidation = require('./zipCodeValidation'),
    orderDuties = require('./../../util/order-duties');

var $cache = {},
	countryvariants,
	selectedaddress,
	shippingMethods;

var selectors = {
	provinceSelect: '.js-province > select',
	citySelect: '.js-city > select',
	districtSelect: '.js-district > select'
}

/**
 * @function
 * @description Initializes jQuery collection cache
 */
function initCache() {
	$cache.body = $('body');
	$cache.form = $('.address');
	var selectVal = $('.js-standard-shipping').data().selectedvariants;
	var countryVal = $('.js-standard-shipping').data().countryvariants;
	selectedaddress = selectVal && selectVal !== '' ? selectVal : null;
	countryvariants = countryVal && countryVal !== '' ? countryVal : null;
}

/**
 * @function
 * @description Initializes shipping events
 */
function initEvents() {
    $('input[name$="_shippingAddress_isGift"]').on('click', giftMessageBox);

    $('.address').on('change',
		'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_postal"]',
		updateShippingMethodList
	);


	if (countryvariants) {
		$cache.form.on('change', selectors.provinceSelect, function (e) {
			var province = e.target.value;
			// city
			var cityList = Object.keys(countryvariants[province]);
			setOptions(cityList, selectors.citySelect);

			// district
			var districtList = countryvariants[province][cityList[0]];
			setOptions(districtList, selectors.districtSelect);
		});

		$cache.form.on('change', selectors.citySelect, function (e) {
			var city = e.target.value;
			var province = $(selectors.provinceSelect).val();

			// district
			var districtList = countryvariants[province][city];
			setOptions(districtList, selectors.districtSelect);
		});
	}

    $cache.body.on('click', '[name$="_shippingMethodID"]', function() {
        $cache.body.trigger('checkout.shipping.method.select');
    });

    $cache.body.on('checkout.shipping.method.select', onShippingMethodSelect);
}

/**
 * @function
 * @description Handles shipping method select event
 */
function onShippingMethodSelect() {
    var requestData;

    if (SitePreferences.IS_IN_STORE_PICKUP_ENABLED && instorepickup.isStoreDeliverySelected()) {
        requestData = instorepickup.getAddressFormData();
        requestData.shippingMethodID = instorepickup.getShippingMethodID();
    } else {
        requestData = getAddressFormData();
        requestData.shippingMethodID = getSelectedShippingMethodID();
    }

    if (!requestData.shippingMethodID) {
        return;
    }

    ajax.getJson({
        url: util.appendParamsToUrl(Urls.selectShippingMethodsList, requestData),
        callback: function (data) {
            if (!data || !data.shippingMethodID) {
                window.alert('Couldn\'t select shipping method.');

                return false;
            }

            clearShippingPromotions();
            updateSummary();
        }
    });
}

/**
 * @function
 * @description Returns currently selected shipping method ID
 * @returns {String}
 */
function getSelectedShippingMethodID() {
    var $options = $cache.body.find('[name$="_shippingMethodID"]'),
        $activeOption = $options.filter(':checked');

    if (!$activeOption.length) {
        $activeOption = $options.first();
    }

    return $activeOption.val();
}

/**
 * @function
 * @description Clears information about appied shipping promotions
 */
function clearShippingPromotions() {
    $('.shippingpromotions').empty();
}

/**
 * @function
 * @description Initializes gift message box, if shipment is gift
 */
function giftMessageBox() {
	// show gift message box, if shipment is gift
	$('.gift-message-text').toggleClass('hidden', $('input[name$="_shippingAddress_isGift"]:checked').val() !== 'true');
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
	var $summary = $('#secondary.summary');
	// indicate progress
	progress.show($summary);

	// load the updated summary area
	$summary.load(Urls.summaryRefreshURL, function () {
		// hide edit shipping method link
		$summary.fadeIn('fast');
		$summary.find('.checkout-mini-cart .minishipment .header a').hide();
		$summary.find('.order-totals-table .order-shipping .label a').hide();
        orderDuties.init();
	});
}

/**
 * @function
 * @description Fetches address information entered in the form
 * @returns {Object}
 */
function getAddressFormData() {
	var $addressForm = $('.address');

    return {
		address1: $addressForm.find('input[name$="_address1"]').val(),
		address2: $addressForm.find('input[name$="_address2"]').val(),
		countryCode: $addressForm.find('select[id$="_country"]').val(),
		stateCode: $addressForm.find('select[id$="_state"]').val(),
		postalCode: $addressForm.find('input[name$="_postal"]').val(),
		city: $addressForm.find('input[name$="_city"]').val()
	};
}

/**
 * @function
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
function updateShippingMethodList() {
    var $shippingMethodList = $('#shipping-method-list');

    if (!$shippingMethodList || $shippingMethodList.length === 0) {
        return;
    }

	var url = util.appendParamsToUrl(Urls.shippingMethodsJSON, getAddressFormData());

	ajax.getJson({
		url: url,
		callback: function (data) {
			if (!data) {
				window.alert('Couldn\'t get list of applicable shipping methods.');
				return false;
			}
			if (shippingMethods && shippingMethods.toString() === data.toString()) {
				// No need to update the UI.  The list has not changed.
				return true;
			}

			// We need to update the UI.  The list has changed.
			// Cache the array of returned shipping methods.
			shippingMethods = data;
			// indicate progress
			progress.show($shippingMethodList);

			// load the shipping method form
            var smlUrl = util.appendParamsToUrl(Urls.shippingMethodsList, getAddressFormData());

			$shippingMethodList.load(smlUrl, function () {
				$shippingMethodList.fadeIn('fast');

                updateSummary();
				progress.hide();
				tooltip.init();
				//if nothing is selected in the shipping methods select the first one
				if ($shippingMethodList.find('.input-radio:checked').length === 0) {
					$shippingMethodList.find('.input-radio:first').prop('checked', 'checked');
				}
			});
		}
	});
}

function setOptions(arr, selector, setValue) {
	$(selector).empty();
	var options = [];

	for (var i = 0; i < arr.length; i++) {
		var item = arr[i];
		var option = new Option(item, item);
		if (setValue && item === setValue) {
			option.setAttribute("selected", "selected");
		}
		options.push(option);
	}

	$(selector).append(options);
}

exports.init = function () {
	var shippingFormValidationOpt = {
		continueSelector: '[name$="shippingAddress_save"]',
		formSelector: '[id$="singleshipping_shippingAddress"]'
    };

    initCache();
	initEvents();

	if (countryvariants) {
		// province
		var provinceList = Object.keys(countryvariants);
		var setProvince = selectedaddress && selectedaddress.province && provinceList.indexOf(selectedaddress.province) > -1 ? selectedaddress.province : provinceList[0];
		setOptions(provinceList, selectors.provinceSelect, setProvince);
		// ciry
		var cityList = Object.keys(countryvariants[setProvince]);
		var setCity = selectedaddress && selectedaddress.city && cityList.indexOf(selectedaddress.city) > -1 ? selectedaddress.city : cityList[0];
		setOptions(cityList, selectors.citySelect, setCity);
		// district
		var districtList = countryvariants[setProvince][setCity];
		var setDistrict = selectedaddress && selectedaddress.district && districtList.indexOf(selectedaddress.district) > -1 ? selectedaddress.district : null;
		setOptions(districtList, selectors.districtSelect, setDistrict);
	}

	if( !SitePreferences.IS_IN_STORE_PICKUP_ENABLED ) {
		formPrepare.init(shippingFormValidationOpt);
	}

	addresscountry.showFullNameField($('.checkout-shipping'), true);
	giftMessageBox();
	updateShippingMethodList();

	if(SitePreferences.IS_IN_STORE_PICKUP_ENABLED) {
		instorepickup.init(shippingFormValidationOpt);
	}

	if(SitePreferences.IS_ZIP_CODE_VALIDATION_ENABLED) {
		zipCodeValidation.init();
	}
};

exports.updateShippingMethodList = updateShippingMethodList;
