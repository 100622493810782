'use strict';

var util = require('./../../util'),
	dialog = require('./../../dialog'),
	addresscountry = require('./../address/addresscountry');

/**
 * @function
 * @description Initialize subscription dialog logic
 */
function initSubscribeEvents() {
	var dialogUrl = Urls.frameView,
		dialogUrlWithParams,
		originalSourceUrl;

	$('.js-newsletter-subscribe-dialog').on('click', function (e) {
		e.preventDefault();
		originalSourceUrl = $(this).attr('href');
		dialogUrlWithParams = util.appendParamsToUrl(dialogUrl, {
			sourceUrl: originalSourceUrl
		});
		getSubscribeDialog(dialogUrlWithParams);
	});

	$('#email-alert-signup').on('submit', function (e) {
		var email, $this;
		
		e.preventDefault();
		$this = $(this);
		if (!$this.valid()) {
			return;
		}
		email = $this.find('#email-alert-address').val() || '';
		originalSourceUrl = $this.attr('action');
		originalSourceUrl = util.appendParamsToUrl(originalSourceUrl, {
			email: email
		});
		dialogUrlWithParams = util.appendParamsToUrl(dialogUrl, {
			sourceUrl: originalSourceUrl
		});
		getSubscribeDialog(dialogUrlWithParams);
	});
}

function getSubscribeDialog(dialogUrl) {
	dialogUrl = util.ajaxUrl(dialogUrl);

	dialog.open({
		url: dialogUrl,
		target: 'service-dialog',
		options: {
			dialogClass: 'dialog-newsletter_subscribe',
			closeOnEscape: true
		}
	});
}

module.exports = {
	init: function () {
		addresscountry.showFullNameField($('.js-newsletter-form'), false);
		initSubscribeEvents();
	}
};