'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util'),
	dialog = require('./../../dialog'),
	tls = require('./../../tls'),
	storelocator = require('./../storelocator');

var markersArray = [];
var formSubmitted = false;

function initializeEvents(){
	var body = $('body');

	if(pageContext && pageContext.ns == 'product'){
        body.on('click', '.js-check-availability', showStoresDialog);
	}

    body.on('click', '.js-store_availability-swatchanchor', updateProductInfo);

    body.on('submit', '.js-search-stores-form', findStoresForProduct);
    body.on('change', '.js-sa-country .country', function () {
    	formSubmitted = true;
	    submitForm();
    });
    body.on('click', '.js-store_result-item', focusStoreMarkerItem);
    body.on('click', '.js-store_result-item a', function(e) {
		e.stopPropagation();
	});
}

function submitForm() {
    $('.js-search-stores-form').submit();
}

function focusStoreMarkerItem(e){
	e.preventDefault();
	var storeIndex = $(this).data('index');
	if(markersArray && markersArray.length && markersArray[storeIndex]){
		google.maps.event.trigger(markersArray[storeIndex], "click", {});
	}
}

function focusStoreListItem(index){
	var $storeElement = $('.js-store_result-item.item-' + index);
	if($storeElement.length){
		var $storeList = $('.js-store_result-list');
		$storeList.animate({scrollTop: $storeList.scrollTop() + $storeElement.position().top});
		$storeElement.addClass('selected');
	}
}

function unfocusStoreListItems(){
	var activeStoreListElement = $('.js-store_result-item.selected');
	if(activeStoreListElement.length){
		activeStoreListElement.removeClass('selected');
	}
}

function findStoresForProduct(e){
	e.preventDefault();
	markersArray = [];
	var $formObj = $(this);
	var $countrySelect = $formObj.find('.js-sa-country .country');
	var formData = $formObj.serialize();
	var searchURL = $formObj.attr('action');
	var productID = $('.dialog-store_availability .js-pdp-attribute_list .selected a').first().data('selectedVariationPid');
	var sizeSelector = $('.dialog-store_availability .js-pdp-attribute.attribute-size');
	var isSizeSelected = ( !sizeSelector.length || (sizeSelector.length && sizeSelector.find('li.selected').length) );
	if(!isSizeSelected){
		$('.dialog-store_availability .js-select_size-message').removeClass('hidden');
		return false;
	}else{
		$('.dialog-store_availability .js-select_size-message').addClass('hidden');
	}
	
	formData += '&pid='+ productID;
	$.ajax({
		url: searchURL,
		method: 'POST',
		data: formData
	}).done(function (data) {
		if(data) {
            var searchResultJSONHolder = $(data).find('.js-stores-search-result-data');

            if (searchResultJSONHolder.length) {
                $('.js-global-search-result-holder').removeClass('hidden');
                $('.js-storelocator-no-results-text').addClass('hidden');

                var $storeSearchResult = $('.js-store-search-result-holder');
                $storeSearchResult.html($(data).find('.js-stores-result-list').html());
                $('.dialog-store_availability').animate({scrollTop: $storeSearchResult.offset().top}, 200);

                markersArray = storelocator.updateMapMarkers(storelocator.getGoogleMap('#map', true), searchResultJSONHolder);
            } else {
                $('.js-global-search-result-holder').addClass('hidden');
	
				if ($countrySelect[0] && $countrySelect[0].value != '0') {
					$('.js-storelocator-no-results-text').removeClass('hidden');
				} else {
					$('.js-storelocator-no-results-text').addClass('hidden');
				}
            }
            $('.js-storelocator-no-results').removeClass('hidden');
        }
	});
}

function updateProductInfo(e){
	e.preventDefault();
	var $targetSelector = $(this),
		productUrl = $targetSelector.attr('href');
	
	if($targetSelector.hasClass('js-back_in_stock')){
		return false;
	}
	
	var $storeAvilabilityContainer = $('.js-store_avilability');
	
	$storeAvilabilityContainer.addClass('js-loading');
	$storeAvilabilityContainer.find('.js-global-search-result-holder').addClass('hidden');
	$storeAvilabilityContainer.find('.js-storelocator-no-results').addClass('hidden');
	productUrl += '&format=ajax';
	
	$.ajax({
		url: productUrl,
		method: 'GET'
	}).done(function (data) {
		$storeAvilabilityContainer.find('.js-pdp-attribute_list').html($(data).find('.js-pdp-attribute_list').html());
		$storeAvilabilityContainer.find('.js-availability-price').html($(data).find('.js_pdp-pricing').html());
		if($targetSelector.hasClass('js-swatch-type-color')){
			$storeAvilabilityContainer.find('.js-availability-p-image').html($(data).find('.js-pdp-image_holder').html());
			$storeAvilabilityContainer.find('.js-item-variation-id').html($(data).find('.js_product-pdp-number').html());
		}
		$storeAvilabilityContainer.removeClass('js-loading');
        submitForm();
	});
}

function showStoresDialog(e){
	var $targetBtn = $(e.target),
		layerUrl = Urls.storeAvailability,
		selectedCountrycode = tls.getCookie('selectedCountry'),
		productID = $targetBtn.data('id');
	if(productID){
		layerUrl = util.appendParamToURL(layerUrl, 'format', 'ajax');
		layerUrl = util.appendParamToURL(layerUrl, 'source', 'availability');
		layerUrl = util.appendParamToURL(layerUrl, 'pid', productID);
		layerUrl = util.appendParamToURL(layerUrl, 'ccode', selectedCountrycode);
		dialog.open({
			url: layerUrl,
			options: {
				dialogClass: 'dialog-store_availability',
				closeOnEscape: true
			},
			callback: function() {
				storelocator.getGoogleMap('#map', true);
                submitForm();
                $('.js-shop-online-button').on('click', function (e) {
                    e.preventDefault();
                    dialog.close();
                });
			}
		});

	}
}

module.exports = {
	init: function() {
		initializeEvents();
	}
};