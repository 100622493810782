'use strict';

var dialog = require('./dialog'),
  product = require('./pages/product'),
  addtocart = require('./pages/product/addtocart'),
  util = require('./../../../app_storefront_core/cartridge/js/util'),
  _ = require('lodash');


var makeUrl = function(url, source, productListID) {
  if (source) {
    url = util.appendParamToURL(url, 'source', source);
  }
  if (productListID) {
    url = util.appendParamToURL(url, 'productlistid', productListID);
  }
  return url;
};

var removeParam = function(url) {
  if (url.indexOf('?') !== -1) {
    return url.substring(0, url.indexOf('?'));
  } else {
    return url;
  }
};

var quickview = {
  init: function() {
    if (!this.exists()) {
      this.$container = $('<div/>').attr('id', 'QuickViewDialog').appendTo(document.body);
    }
    this.productLinks = $('#search-result-items .thumb-link').map(function(index, thumbLink) {
      return $(thumbLink).attr('href');
    });
    addtocart.init();
  },

  setup: function(qvUrl) {
    product.init();

    this.productLinkIndex = _(this.productLinks).findIndex(function(url) {
      return removeParam(url) === removeParam(qvUrl);
    });
  },

  /**
   * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
   */
  navigateQuickview: function(step) {
    // default step to 0
    this.productLinkIndex += (step ? step : 0);
    var url = makeUrl(this.productLinks[this.productLinkIndex], 'quickview');
    dialog.replace({
      url: url,
      callback: this.setup.bind(this, url)
    });
  },

  /**
   * @description show quick view dialog
   * @param {Object} options
   * @param {String} options.url - url of the product details
   * @param {String} options.source - source of the dialog to be appended to URL
   * @param {String} options.productlistid - to be appended to URL
   * @param {Function} options.callback - callback once the dialog is opened
   */
  show: function(options) {
    var loaderPlaceholder = $('.loader');
    var url;
    if (!this.exists()) {
      this.init();
    }
    url = makeUrl(options.url, options.source, options.productlistid);

    dialog.open({
      target: this.$container,
      url: url,
      options: {
        width: 920,
        title: Resources.QUICK_VIEW_POPUP,
        dialogClass: 'dialog-quickview',
        open: function() {
          this.setup(url);
          if (typeof options.callback === 'function') {
            options.callback();
          }
          // Disable #pdpMain container of PDP if we're on PDP to process only quick view's one
          if ($('#primary > #pdpMain').length) {
            $('#primary > #pdpMain').attr('id', 'pdpMainDisabled').attr('class', 'pdp-main-disabled');
          }
        }.bind(this),
        close: function() {
          // Restore the initial ID of #pdpMain container of PDP if we're on PDP after closing quick view
          if ($('#primary > #pdpMainDisabled').length) {
            $('#primary > #pdpMainDisabled').attr('id', 'pdpMain').attr('class', 'pdp-main');
          }
          $("body").removeClass("js-quickview_opened");
        }
      },
      callback: function() {
        $("body").addClass("js-quickview_opened");
        $(document).trigger('quickview.opened');
        $(document).find('.js-add-variant-to-cart').removeAttr('disabled').attr('type', 'submit');
        // remove global loading placeholder after showing quick view
        // not to break other functions that depend on its presence (i.e. infiniteScroll)
        if (loaderPlaceholder.length) {
          loaderPlaceholder.remove();
        }
      }
    });
  },

  exists: function() {
    return this.$container && (this.$container.length > 0);
  }
};

module.exports = quickview;
