'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	formPrepare = require('./formPrepare'),
	giftcard = require('./../../../../../app_storefront_core/cartridge/js/giftcard'),
	util = require('./../../../../../app_storefront_core/cartridge/js/util'),
	adyenCheckout = require('./../../../../../int_adyen_controllers/cartridge/js/pages/checkout/adyen-checkout'),
	page = require('./../../page.js'),
	addresscountry = require('./../address/addresscountry'),
	progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
	qrcode = null,
	countryvariants,
	selectedaddress;

var selectors = {
	provinceSelect: '.js-province > select',
	citySelect: '.js-city > select',
	districtSelect: '.js-district > select'
}

/**
* @function
* @description Changes the payment type or issuerId of the selected payment method
* @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
*/
function updatePaymentType(selectedPayType, issuerType) {
	if (issuerType) {
		$('#dwfrm_adyPaydata_issuer').val(selectedPayType);
	} else {
		$('input[name="brandCode"]').removeAttr('checked');
		$('input[value=' + selectedPayType + ']').prop('checked', 'checked');
	}
	
	// if the payment type has hidden fields reveal it
	$('#component_' + selectedPayType).show();
	formPrepare.validateForm();
}

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
	var $creditCard = $('[data-method="CREDIT_CARD"]');
	$creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
	$creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
	$creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
	$creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
	$creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
	$creditCard.find('input[name$="_cvn"]').val('').trigger('change');
}

/**
 * @function
 * @description Clear credit card form
 */
function clearCCFields() {
	var $creditCard = $('[data-method="CREDIT_CARD"]');
	$creditCard.find('input[name$="creditCard_owner"]').val('')
	$creditCard.find('select[name$="_type"]').val('');
	$creditCard.find('input[name*="_creditCard_number"]').val('');
	$creditCard.find('[name$="_month"]').val('');
	$creditCard.find('[name$="_year"]').val('');
	$('.js-cc-cvn-wrapper input').val('');
	$creditCard.find('label.error').hide();
}

function hideCCForm() {
	$('.js-cc-fields-holder').hide();
}

function showCCForm() {
	$('.js-cc-fields-holder').show();
}

function mooveCVNBlock(triggeredCCInput) {
	var svnInputEl = $('.js-cc-cvn-wrapper'),
		activeCVNHolder;
	if (!triggeredCCInput.val()) {
		activeCVNHolder = $('.js-svn-holder-static');
	} else {
		activeCVNHolder = $(triggeredCCInput).closest('.js-cc-s-holder').find('.js-svn-holder-dynamic');
	}

	activeCVNHolder.append(svnInputEl);
}


/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
	// load card details
	var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
	ajax.getJson({
		url: url,
		callback: function (data) {
			if (!data) {
				window.alert(Resources.CC_LOAD_ERROR);
				return false;
			}
			setCCFields(data);
		}
	});
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID) {
	var $paymentMethods = $('.payment-method');
	var $paymentMethodsToHide = $('.payment-method[data-method != "AdyenComponent"]');
	$paymentMethodsToHide.removeClass('payment-method-expanded');

	var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
	if ($selectedPaymentMethod.length === 0) {
		$selectedPaymentMethod = $('[data-method="Custom"]');
	}
	$selectedPaymentMethod.addClass('payment-method-expanded');

	// ensure checkbox of payment method is checked
	$('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
	$('input[value=' + paymentMethodID + ']').prop('checked', true);

	formPrepare.validateForm();
}

function loadSavedCCList() {
	var savedCCListHolder = $('.js-cc-slist-holder'),
		url = Urls.getSavedCCListURL;
	if (savedCCListHolder.length && url) {
		$.get(url, '', function (data) {
			if (data && data.length && $(data).find('.js-cc-s-holder').length) {
				savedCCListHolder.html(data);
			}
		});
	}
}

function initRemoveSavedCard() {
	var savedCCListHolder = $('.js-cc-slist-holder'),
		$selectedCCLink,
		selectedWrapper,
		ccID,
		url;

	if (savedCCListHolder.length) {
		$(savedCCListHolder).on('click', '.js-cc-remove', function (e) {
			e.preventDefault();
			$selectedCCLink = $(this);
			ccID = $selectedCCLink.data('cardid');
			if (ccID) {
				url = Urls.removeSavedCC;
				$.post(url, {
					ccid: ccID
				}, function (data) {
					if (data && data.success) {
						selectedWrapper = $selectedCCLink.closest('.js-cc-s-holder');
						if (!savedCCListHolder.find('.js-new-cc:checked').length) {
							selectedWrapper.next().find('.js-creditCardRadioList').click();
						}
						selectedWrapper.remove();
					}

				});
			}
		});
	}
}

function focusPaymentError(){
	var $paymentErrorBlock = $('.js-billing-payment-error');
	$paymentErrorBlock.length && util.scrollBrowser($paymentErrorBlock.offset().top - $('.js-sticky_header').outerHeight() - 10, 300);
}

/**
 * @function
 * @description update Payment Method lists on postal code changed
 */
function updatePaymentMethodList() {
	var $paymentMethodList = $('#payment-method-list'),
		url;
	if ($paymentMethodList.length === 0) {
		return;
	}
	url = util.appendParamsToUrl(Urls.getPaymentMethodList, {
		postalCode: $('.js-address').find('input[name$="_postal"]').val()
	});

	progress.show($paymentMethodList);

	$paymentMethodList.load(url, function () {
		initPaymentSelector();
		progress.hide();

        if (SitePreferences.ADYEN_SF_ENABLED) {
            adyenCheckout.initBilling();
        }
	});
}

/**
 * @function
 * @description initialize Payment selector for radio buttons
 */
function initPaymentSelector() {
	var $selectPaymentMethod = $('.payment-method-options,#paymentMethodsList'),
		selectedPaymentMethod = $selectPaymentMethod.find(':checked').val();

	if (!selectedPaymentMethod) {
		var paymentMethods = $selectPaymentMethod.find('.input-radio:not([disabled])'),
			hasCreditCardPM = false;

		for (var i = 0; i < paymentMethods.length; i++) {
			var paymentMethod = paymentMethods[i];

			if (paymentMethod.value == 'CREDIT_CARD') {
				hasCreditCardPM = true;
				break;
			}
		}

		if (!hasCreditCardPM && paymentMethods.length) {
			var pm = paymentMethods[0];

			selectedPaymentMethod = pm.value;
		}
	}
	// default payment method to 'CREDIT_CARD'
	var $paymentMethods = $('.payment-method');
	$paymentMethods.removeClass('payment-method-expanded');
	updatePaymentMethod((selectedPaymentMethod) ? selectedPaymentMethod : 'CREDIT_CARD');
	//Scroll to error element
	focusPaymentError();
	$selectPaymentMethod.on('click', 'input[type="radio"]', function () {
		var $payType = $('[name="brandCode"]');
        if ($(this).val()) {
			updatePaymentMethod($(this).val());	
		}
		if ($(this).parent().parent().attr('id') &&
            $(this).parent().parent().attr('id') === 'paymentMethodsList') {
            var selectedPayType = $payType.filter(':checked').val();
            updatePaymentMethod('AdyenComponent');
            updatePaymentType((selectedPayType) ? selectedPayType : $payType[0].value, false);
        } else {
            $payType.removeAttr('checked');
			adyenCheckout.resetPaymentMethod();
			adyenCheckout.displaySelectedMethod();
        }
	});
}

function updateAdyenComponent(paymentMethodID) {
	var $payType = $('[name="brandCode"]');
	var $issuer = $('.issuer');
	var selectedPayType = $payType.find(':checked').val();
	if (paymentMethodID.includes('Adyen') && $payType.length > 0) {
		// set payment type of Adyen to the first one
		updatePaymentType((selectedPayType) ? selectedPayType : $payType[0].value, false);
	} else {
		$payType.removeAttr('checked');
	}
}

/**
 * @function
 * @description init ajax action to btn if CUP payment
 */
function initPlaceOrderBtn() {
    var $requestForm = $('#checkout-submit_form'),
        $submit,
        url,
        actionRsponse;
    if (!$requestForm.length) {
        return;
    }
    $submit = $('#place-order-button');
    url =  $requestForm.attr('action');

    $submit.on('click', function (e) {
        e.preventDefault();
        if (actionRsponse) {
            afterPlaceOrder(actionRsponse);
        } else {
            var data = $requestForm.serialize();
            $.ajax({
                type: 'POST',
                url: url,
                data: data,
                success: function (response) {
                    actionRsponse = response;
                    afterPlaceOrder(response);
                }
            });
        }
    });

    initSMSCodeClosePopup();
    initWeChatClosePopup();
}

/**
 * @function
 * @description initialize actions after place order
 * @param {Object} response place order response
 */
function afterPlaceOrder(response) {
    if (response.redirectUrl) {
        page.redirect(response.redirectUrl);
    } else if (response.isWechatAuthorize) {
        if (response.wechatUrl) {
            initWeChatPopup(response.wechatUrl, response.orderNo);
            showWeChatPopup();
        } else {
            submitWeChatPayment();
        }
    } else {
        showSMSCodePopup();
    }
}

/**
 * @function
 * @description init SMS code popup close action
 */
function initSMSCodeClosePopup() {
    $('.js-utility_popup-sms-close').on('click', function (e) {
        $('.js-utility_popup-sms-item').removeClass('active');
    });
}

/**
 * @function
 * @description init WeChat popup close action
 */
function initWeChatClosePopup() {
    $('.js-utility_popup-wechat-close').on('click', function (e) {
        $('.js-utility_popup-wechat-item').removeClass('active');
    });
}

/**
 * @function
 * @description show SMS code popup action
 */
function showSMSCodePopup() {
    $('.js-utility_popup-sms-item').addClass('active');
}

/**
 * @function
 * @description show WeChat popup action
 */
function showWeChatPopup() {
    $('.js-utility_popup-wechat-item').addClass('active');
}

/**
 * @function
 * @description submit WeChat payment
 */
function submitWeChatPayment() {
    $('#wecaht-submitform').submit();
}

/**
 * @function
 * @description init WeChat popup
 */
function initWeChatPopup(qrUrl, orderNo) {
    var element = document.getElementById("wechatqrcode");
    if (!qrcode) {
        qrcode = new QRCode( element, {
            text: qrUrl,
            width: 300,
            height: 300,
            colorDark : "#000000",
            colorLight : "#ffffff",
            correctLevel : QRCode.CorrectLevel.H
        });
        checkWeChatPaymentStatus(orderNo);
    } else {
        qrcode.clear();
        qrcode.makeCode(qrUrl);
    }
}

/**
 * @function
 * @description initialize checking of WeChat payment status
 */
function checkWeChatPaymentStatus(orderNo) {
    setTimeout(function() {
        $.ajax({
            type: 'POST',
            url: Urls.wechatQueryPaymentStatus,
            data: {orderNo: orderNo},
            success: function(result) {
                var title = null,
                    text = null;

                if (!result) {
                    checkWeChatPaymentStatus(orderNo);
                    return;
                }
                if (result.status === "SUCCESS") {
                    title = Resources.WECHAT_SUCCESSFUL_TITLE;
                    text = Resources.WECHAT_SUCCESSFUL_TEXT;
                    $('.js-wechat-title').text(title);
                    $('.js-wechat-text').removeClass('m-error').text(text);
                    setTimeout(function() {
                        submitWeChatPayment();
                    }, 4000);
                } else if (result.status === "PAYERROR") {
                    title = Resources.WECHAT_ERROR_TITLE;
                    text = Resources['WECHAT_ERROR_' + result.error];
                    $('.js-wechat-title').text(title);
                    $('.js-wechat-text').addClass('m-error').text(text);
                    checkWeChatPaymentStatus(orderNo);
                } else {
                    checkWeChatPaymentStatus(orderNo);
                }
            },
            error: function() {
                checkWeChatPaymentStatus(orderNo);
            }
        });
    }, 10000);
}

function setOptions(arr, selector, setValue) {
	$(selector).empty();
	var options = [];

	for (var i = 0; i < arr.length; i++) {
		var item = arr[i];
		var option = new Option(item, item);
		if (setValue && item === setValue) {
			option.setAttribute("selected", "selected");
		}
		options.push(option);
	}

	$(selector).append(options);
}

function initSelectCountries() {
	// province
	var provinceList = Object.keys(countryvariants);
	var setProvince = selectedaddress && selectedaddress.province && provinceList.indexOf(selectedaddress.province) > -1 ? selectedaddress.province : provinceList[0];
	setOptions(provinceList, selectors.provinceSelect, setProvince);
	// ciry
	var cityList = Object.keys(countryvariants[setProvince]);
	var setCity = selectedaddress && selectedaddress.city && cityList.indexOf(selectedaddress.city) > -1 ? selectedaddress.city : cityList[0];
	setOptions(cityList, selectors.citySelect, setCity);
	// district
	var districtList = countryvariants[setProvince][setCity];
	var setDistrict = selectedaddress && selectedaddress.district && districtList.indexOf(selectedaddress.district) > -1 ? selectedaddress.district : null;
	setOptions(districtList, selectors.districtSelect, setDistrict);
}

function listenSelectCountries() {
	var form = $('.js-address');

	form.on('change', selectors.provinceSelect, function(e) {
		var province = e.target.value;
		// city
		var cityList = Object.keys(countryvariants[province]);
		setOptions(cityList, selectors.citySelect);

		// district
		var districtList = countryvariants[province][cityList[0]];
		setOptions(districtList, selectors.districtSelect);
	});

	form.on('change', selectors.citySelect, function(e) {
		var city = e.target.value;
		var province = $(selectors.provinceSelect).val();

		// district
		var districtList = countryvariants[province][city];
		setOptions(districtList, selectors.districtSelect);
	});
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
exports.init = function () {
	var $checkoutForm = $('.checkout-billing');
	var $addGiftCert = $('#add-giftcert');
	var $checkGiftCert = $('#check-giftcert');
	var $giftCertCode = $('input[name$="_giftCertCode"]');
	var $addCoupon = $('#add-coupon');
	var $couponCode = $('input[name$="_couponCode"]');
	var $changeBillingAddress = $('.js-checkout-change_billing');
	var $address = $('.js-address'),
		$document = $(document);

	// validate cardNumber and cardCVC by jquery.payment lib 
	$('.js-cardNumber input').payment('formatCardNumber');
	$('.js-cardCVC input').payment('formatCardCVC');

	countryvariants = $address.data() ? $address.data().countryvariants : null;
	selectedaddress = $address.data() ? $address.data().selectedvariants : null; 
	if (countryvariants) {	
		initSelectCountries();
		listenSelectCountries();
	}

 /**
 * @function
 * @description change Gift Certificates buttons 'disable' attribute in case of COD selected
 */
function changeGiftCertificatesButtonsStatus(value) {
    var $message = $checkoutForm.find('.js-giftcert_message');
    if (value === true) {
        $message.removeClass('success').addClass('error').html(Resources.GIFT_CERT_AND_COD);
        $addGiftCert.attr("disabled", value);
        $checkGiftCert.attr("disabled", value);
    } else {
        $message.removeClass('error').addClass('success').html('');
        $addGiftCert.attr("disabled", value);
        $checkGiftCert.attr("disabled", value);
    }
}

/**
 * @function
 * @description change COD status depends on Gift Certificates
 */
function updateCODstatus() {
	var $message = $("label[for='is-CASH_ON_DELIVERY']").find("#cod_message");
    if ($('.success.giftcert-pi').length) {
		$("#is-CASH_ON_DELIVERY").attr("disabled", true).attr("checked", false);
		$message.html(Resources.GIFT_CERT_AND_COD_2);
    } else if(!($('.js-tooltip-content.hidden').length)){
		$("#is-CASH_ON_DELIVERY").attr("disabled", false);
		$message.html("");
    }
}

/**
 * @function
 * @description listeners for radio buttons in case of COD and Gift Certificates
 */
function updateRadioButtonsCODandGiftCertificate(){
	$('input[type="radio"]').bind('click', function(){
		if(this.id!="is-CASH_ON_DELIVERY"){
			changeGiftCertificatesButtonsStatus(false);
		}else{
			changeGiftCertificatesButtonsStatus(true);
		}
	});
}

/**
 * @function
 * @description listener for remove Gift Certificate with COD
 */
function removeGiftCertificateWithCOD(){
$('.remove icon-close js-tooltip-desktop').on('click', function(e){
	if( !($('.success giftcert-pi').is(':empty'))){
		changeGiftCertificatesButtonsStatus(true);
	}else{
		changeGiftCertificatesButtonsStatus(false);
	}
});
}

	loadSavedCCList();
	initRemoveSavedCard();
	formPrepare.init({
		formSelector: 'form[id$="billing"]',
		continueSelector: '[name$="billing_save"]'
	});

	$address.on('change',
		'input[name$="_addressFields_postal"]',
		updatePaymentMethodList
	);
	
	initPlaceOrderBtn();
	
	if (SitePreferences.GIFT_CERT_COD_DISABLED) {
		removeGiftCertificateWithCOD();
		updateRadioButtonsCODandGiftCertificate();
		updateCODstatus();
	}

	addresscountry.showFullNameField($('.checkout-billing'), true);

	$changeBillingAddress.on('click', function () {
		$('.js-checkout-mini_billing_block').hide();
		$('.js-checkout-edit_billing_block').show();

		$document.trigger('form.show');
  });

    initPaymentSelector();

	$('.js-cc-fields-holder').on('change keyup', '.required.error', function () {
		// remove server side error if existing jQuery validate error
		$(this).closest('.f-field').find('.f-caption.error-message').remove();
	});

	// select credit card from list
	$('.js-cc-slist-holder').on('click', '.js-creditCardRadioList', function (e) {
		var cardUUID = $(this).val();
		var isSelectedCheck = $(this).hasClass('active');
		if (!isSelectedCheck) {
			clearCCFields();

			$('.js-creditCardRadioList').removeClass('active');
			$(this).addClass('active');
			mooveCVNBlock($(this));
			if (!cardUUID) {
				showCCForm();
			} else {
				populateCreditCardForm(cardUUID);
				hideCCForm();
			}
			// remove server side error
			$('.required.error').removeClass('error');
			$('.error-message').remove();
		}
	});

	$('#check-giftcert').on('click', function (e) {
		e.preventDefault();
		var $message = $('.js-giftcert_message');

		if ($giftCertCode.length === 0 || $giftCertCode.val().length === 0) {
			$message.removeClass('success').addClass('error').html(Resources.GIFT_CERT_MISSING);
			return;
		}

		giftcard.checkBalance($giftCertCode.val(), function (data) {
			if (!data || !data.giftCertificate) {
				$message.removeClass('success').addClass('error').html(Resources.GIFT_CERT_INVALID);
				return;
			}
			$message.removeClass('error').addClass('success').html(Resources.GIFT_CERT_BALANCE + ' ' + data.giftCertificate.balance);
		});
	});

	$addGiftCert.on('click', function (e) {
		e.preventDefault();
		var code = $giftCertCode.val(),
			$message = $checkoutForm.find('.js-giftcert_message');

		if (code.length === 0) {
			$message.removeClass('success').addClass('error').html(Resources.GIFT_CERT_MISSING);
			return;
		}

		var url = util.appendParamsToUrl(Urls.redeemGiftCert, {
			giftCertCode: code,
			format: 'ajax'
		});
		$.getJSON(url, function (data) {
			var fail = false;
			var msg = '';
			if (!data) {
				msg = Resources.BAD_RESPONSE;
				fail = true;
			} else if (!data.success) {
				msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
				fail = true;
			}
			if (fail) {
				$message.removeClass('success').addClass('error').html(msg);
				return;
			} else {
				if (SitePreferences.GIFT_CERT_COD_DISABLED){
				$("#is-CASH_ON_DELIVERY").attr("disabled", true).attr("checked", false);
				}
				window.location.assign(Urls.billing);
			}
		});
	});

	$addCoupon.on('click', function (e) {
		e.preventDefault();
		var $error = $checkoutForm.find('.coupon-error'),
			$successMessageBox = $checkoutForm.find('.coupon'),
			code = $couponCode.val();
		if (code.length === 0) {
			$error.html(Resources.COUPON_CODE_MISSING);
			return;
		}

		var url = util.appendParamsToUrl(Urls.addCoupon, {
			couponCode: code,
			format: 'ajax'
		});
		$.getJSON(url, function (data) {
			var fail = false;
			var msg = '';
			if (!data) {
				msg = Resources.BAD_RESPONSE;
				fail = true;
			} else if (!data.success) {
				msg = data.message.split('<').join('&lt;').split('>').join('&gt;');
				fail = true;
			} else {
				msg = $('<span />', {
					text: data.message.split('<').join('&lt;').split('>').join('&gt;'),
					class: 'success'
				});
			}
			if (fail) {
				$successMessageBox.empty();
				$error.html(msg);
				return;
			}

			$error.empty();
			$successMessageBox.html(msg);

			//basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
			//this will force a page refresh to display the coupon message based on a parameter message
			if (data.success && data.baskettotal === 0) {
				window.location.assign(Urls.billing);
			}
		});
	});

	// trigger events on enter
	$couponCode.on('keydown', function (e) {
		if (e.which === 13) {
			e.preventDefault();
			$addCoupon.click();
		}
	});
	$giftCertCode.on('keydown', function (e) {
		if (e.which === 13) {
			e.preventDefault();
			$addGiftCert.click();
		}
	});
	$checkoutForm.on('keydown', function (e) {
		if (e.which === 13) {
			e.preventDefault();
			$checkoutForm.submit();
		}
	});

	if (SitePreferences.ADYEN_SF_ENABLED) {
		adyenCheckout.initBilling();
	}
	var $payType = $('input[name="brandCode"]');
	var $issuer = $('.issuer');
	$payType.on('change', function() {
		$('#selectedIssuer').val("");
		$issuer.hide();
		$('.checkoutComponent').hide();
		$('#component_' + $(this).val()).show();
		if ($(this).siblings( ".issuer").length > 0) {
			$('#selectedIssuer').val($(this).siblings( ".issuer" ).val());
			$(this).siblings('.issuer').show();
		}
	});
};
