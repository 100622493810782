'use strict';

var selectors = {
    orderDutiesTitle: '.js-order-duties-title',
    orderDuties: '.js-order-duties',
    orderDutiesContent: '.js-order-duties-content'
};

var classes = {
    orderDutiesTitleMobile: 'order-duties-title-mobile'
};

function globalClick(e) {
    if (!$(e.target).parents(selectors.orderDuties).length) {
        $(selectors.orderDutiesContent).hide();
        $(selectors.orderDutiesTitle).removeClass(classes.orderDutiesTitleMobile);
    } else {
        e.stopPropagation();
    }
}

/**
 * @function
 * @description Initialize order duties event listener
 */
function initOrderDuties() {
    var $div = $('div.gpay-button-fill');
    if ($div.length > 1) {
        $div.not(':last').remove()
    }
    
    $(document).on('click touchstart', globalClick);

    $(selectors.orderDutiesTitle).on('click', function(e) {
        var $currentTarget = $(e.currentTarget);
        var $dutiesContent = $currentTarget.parents(selectors.orderDuties).find(selectors.orderDutiesContent);

        $currentTarget.toggleClass(classes.orderDutiesTitleMobile);
        $dutiesContent.toggle();
    });
}

module.exports = {
    init : function() {
        initOrderDuties();
    }
};
