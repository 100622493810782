var dialog = require('./../../../../app_storefront_core/cartridge/js/dialog'),
	utils = require('./utils');

function Dialog() {

	var prototype = Object.getPrototypeOf(this);

	$.extend(true, prototype.settings, {
		'width': '100%',
		'height': 'auto',
		'dialogClass': '',
		'buttons': [],
		'draggable': false,
		'emptyOnClose': true,
		'closeText': Resources.CLOSE,
		'closePrevs': false,
		'closeOnOutsideClick': true,
		'usetarget': false,
		'autofocusOnFirst' : false
	});

	this.create = function(params) {
		var that = this,
			options = {};

		!!params.options.title && (params.options.dialogClass += ' dialog-with-title');

		prototype.create(params);
		options = this.$container.dialog('option');

		if (!!options.emptyOnClose) {
			this.$container.on('dialogclose', function (event, ui) {
				$(this).empty();
			});
		}

		if (!options.autofocusOnFirst) {
			this.$container.one('dialogopen', function (event, ui) {
				$(this).find(':tabbable:first').blur();
			});
		}

		if (options.closeOnOutsideClick) {
			this.$container.one('dialogopen', function(event, ui) {
				var $container = $(this),
					$overlay = $container.data('ui-dialog')['overlay'] || '';

				if (!$overlay.length) {
					return;
				}

				$overlay.one('click', function (event) {
					$(this).data('dialog').dialog('close');
				}).data('dialog', $container);
			});
		}

		return this.$container;
	};

	this.flyout = function(params) {
		this.open(params);
		utils.showFlyout.call(this, params.animationDuration, params.showDuration);
	};

	this.submit = function(action) {
		var $form = this.$container.find('form:first');
		// set the action
		$('<input/>').attr({
			name: action,
			type: 'hidden'
		}).appendTo($form);
		// serialize the form and get the post url
		var data = $form.serialize();
		var url = $form.attr('action');
		// make sure the server knows this is an ajax request
		if (data.indexOf('ajax') === -1) {
			data += '&format=ajax';
		}
		// post the data and replace current content with response content
		$.ajax({
			type: 'POST',
			url: url,
			data: data,
			dataType: 'html',
			success: function (html) {
				$(document).trigger('sendtofriend.success', [this.$container]);
				this.$container.html(html);
				this.$container.dialog( "option", "position", prototype.settings.position);
			}.bind(this),
			failure: function () {
				window.alert(Resources.SERVER_ERROR);
			}
		});
	};

	this.openWithContent = function (params) {
	   if (params.target instanceof jQuery && params.usetarget) {
			prototype.$container = params.target;
		}
		prototype.openWithContent.call(this, params);
	};

	/**
	 * @function
	 * @description Opens a dialog using the given url (params.url) or html (params.html)
	 * @param {Object} params
	 * @param {Object} params.url should contain the url
	 * @param {String} params.html contains the html of the dialog content
	 */
	this.open = function (params) {
		// close any open dialog
		if(params['closePrevs']) {
			this.close();
		}
		this.create(params);
		this.replace(params);
	};

}

/** Assign core SG2 dialog module to current implementation prototype */
Dialog.prototype = require('./../../../../app_storefront_core/cartridge/js/dialog');

/**
 * Static handler for updating of all initialized dialog instances
 * @param  {String} option
 * @param  {Object} value
 * @return {void}
 */
Dialog.prototype.updateAll = function (option, value) {
	$(':ui-dialog').each(function (index) {
		var $dialog = $(this),
			valueUpdated = value || $dialog.dialog('option', option);

		if (!$dialog.dialog('isOpen')) {
			return;
		}

		$dialog.dialog('option', option, valueUpdated);
	});
};

/**
 * Close all opened dialog instances
 * @return {void}
 */
Dialog.prototype.closeAll = function (option, value) {
	$(':ui-dialog').dialog('close');
};

module.exports = new Dialog();
