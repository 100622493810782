'use strict';

var quickview = require('./quickview'),
	imagesLoaded = require('imagesloaded'),
    util = require('./util');


function initQuickViewButtons() {
	$('.tiles-container .product-image').on('mouseenter', function () {
		var $qvButton = $('#quickviewbutton');
		if ($qvButton.length === 0) {
			$qvButton = $('<a id="quickviewbutton" class="quickview">' + Resources.QUICK_VIEW + '<i class="fa fa-arrows-alt"></i></a>');
		}
		var $link = $(this).find('.thumb-link');

		$qvButton.attr({
			'href': $link.attr('href'),
			'title': $link.attr('title')
		}).appendTo(this);

		$qvButton.on('click', function (e) {
			e.preventDefault();
			var containerName;

			quickview.show({
				url: $(this).attr('href'),
				source: 'quickview',
				containerName: containerName || ''
			});
		});
	});
}


function initTileSwatches($container) {
	$container.find('.js-swatch_list')
		.one('mouseenter', function () {
			var $tile = $(this).closest('.js-product_tile');

			// Record the current img
			$tile.find('.js-thumb_image').each(function() {
				var $image = $(this);
				$image.data('current', {
					src: $image[0].src,
					srcset: $image[0].srcset,
					alt: $image[0].alt,
					title: $image[0].title
				});
			});

			// Record the current color
			$tile.data('color-id', $tile.find('.js-product_tile-action.selected').data('color-id'));
		})
		.on('mouseleave', function () {
			// Restore current thumb image
			var $tile = $(this).closest('.js-product_tile'),
				thumbColor = $tile.data('color-id'),
				$thumbActions = $tile.find('.js-product_tile-action'),
				$thumbProduct = $tile.find('.js-product-view'),
				$thumbOutfit = $tile.find('.js-outfit-view'),
				$thumbRollover = $tile.find('.js-rollover-view');

			$thumbProduct.attr($thumbProduct.data('current'));
			$thumbOutfit.length && $thumbOutfit.attr($thumbOutfit.data('current'));
			$thumbRollover.length && $thumbRollover.attr($thumbRollover.data('current'));

			$thumbActions.removeClass('selected').filter(function() {
				return $(this).data("color-id") == thumbColor;
			}).addClass('selected');
		})
		.on('click', '.js-swatch', function (e) {
			e.preventDefault();
			if ($(this).hasClass('selected')) { return; }

            var variationId = $(this).data('variation-id'),
                $wishlist = $(this).parents(".js-product_tile").find(".js-wishlist-custom");

            updateWishListButton($wishlist, variationId);
			showSwatchThumbnail.call(this, true);
		})
		.on('mouseenter', '.js-swatch', function () {
			showSwatchThumbnail.call(this);
		});

	function showSwatchThumbnail(selectSwatch) {
		var data = $(this).children('.js-swatch_image').data('thumb'),
			$tile = $(this).closest('.js-product_tile'),
			thumbColor = $(this).data('color-id'),
			$thumbActions = $tile.find('.js-product_tile-action'),
			$thumbProduct = $tile.find('.js-product-view'),
			$thumbOutfit = $tile.find('.js-outfit-view'),
			$thumbRollover = $tile.find('.js-rollover-view');

		// Set the tile image to the values provided on the swatch data attributes
		$thumbProduct.attr({
			src: data.src,
			srcset: data.srcset,
			alt: data.alt,
			title: data.title
		});
		$thumbOutfit.length && $thumbOutfit.attr({
			src: data.srcOutfit,
			srcset: data.srcsetOutfit,
			alt: data.alt,
			title: data.title
		});
		$thumbRollover.length && $thumbRollover.attr({
			src: data.srcRollover,
			srcset: data.srcsetRollover,
			alt: data.alt,
			title: data.title
		});

		$thumbActions.removeClass('selected').filter(function() {
			return $(this).data("color-id") == thumbColor;
		}).addClass('selected');

		if (!!selectSwatch) {
			$(this).closest('.js-swatch_list').find('.selected').removeClass('selected');
			$(this).addClass('selected');
			$tile.find('.js-product_link, .js-product_name').attr('href', $(this).attr('href'));

			$thumbProduct.data('current', {
				src: data.src,
				srcset: data.srcset,
				alt: data.alt,
				title: data.title
			});
			$thumbOutfit.length && $thumbOutfit.data('current', {
				src: data.srcOutfit,
				srcset: data.srcsetOutfit,
				alt: data.alt,
				title: data.title
			});
			$thumbRollover.length && $thumbRollover.data('current', {
				src: data.srcRollover,
				srcset: data.srcsetRollover,
				alt: data.alt,
				title: data.title
			});

			$tile.data('color-id', thumbColor);
		}
	}
}

function loadDynamicImages($container) {
	var productsImg =  $container.find('.js-dynamic-image');

	$.each(productsImg, function() {
		var _this = $(this);

		_this.attr( 'src', _this.attr('data-src') );
		_this.attr( 'srcset', _this.attr('data-srcset') );
	});

	imagesLoaded(productsImg).on('always', function () {
		$('.js-search-result-items').addClass('js-images-loaded');
	});
}

function initTileIndexes(options) {
    var $container, $tiles, start, pageHref, pageStart;

    if (!!options && 'container' in options) {
        $container = options.container;
        start = options.start;
    } else {
    	pageHref = parseInt(util.getParameterValueFromUrl('page')) || 0;
    	pageStart = (pageHref) ? pageHref - 1 : 0;
        $container = $('body');
        start = (pageStart <=  0) ? 0 : pageStart * 12;
    }
    $tiles = $container.find('.js-product_tile');

    if ($tiles.length === 0) {
    	return false;
    }
    $tiles.each(function (idx) {
        $(this).data('idx', start + idx);
    });

    return $tiles;
}


function initWishListButtonsAction($container) {
    $container.on('click', '.js-wishlist-custom', handleWishListButtonsAction);
}

function updateWishListButton($item, productId) {
    var url = util.appendParamsToUrl(util.ajaxUrl(Urls.wishlistIsProductInProductList), {
        pid: productId
    });

    $.ajax({
        url: url,
        success: function(response) {
            if (response.success) {
                if ( response.status) {
                    $item.addClass('active');
                } else {
                    $item.removeClass('active');
                }
                $item.data('product-id', productId);
            }
        }
    });
}

function handleWishListButtonsAction(e) {
    e.preventDefault()	;
    var $item = $(this),
        productID = $item.data('product-id'),
        wishlisID = $item.data('wishlis-id'),
        isActive = $item.hasClass('active'),
        url = isActive ? Urls.wishlistRemoveProductVariants : Urls.wishlistAddProduct;

    url = util.appendParamsToUrl(util.ajaxUrl( url), {
        pid: productID,
        wishlisID: wishlisID,
        onlyVariant: true
    });
    $.ajax({
        url: url,
        success: function (response) {
            if (response.success) {
                if (isActive) {
                    $item.removeClass('active');
                    $item.data('wishlis-id', null);
                } else {
                    $item.addClass('active');
                    $item.data('wishlis-id', response.productListID);
                }
                updateHeaderWishListButtons();
				updateHeaderWishListQuantity();
            }
        }
    });
}

function updateHeaderWishListButtons() {
    var $item = $('.js-wishlist-custom-header'),
        url = util.appendParamsToUrl(util.ajaxUrl(Urls.wishlistIsHasProduct), {});

    $.ajax({
        url: url,
        success: function (response) {
            if (response.success) {
                if (response.status) {
                    $item.addClass('active');
                } else {
                    $item.removeClass('active');
                }
            }
        }
    });
}

function updateHeaderWishListQuantity() {
    var $item = $('.b-site_header .wishlist-quantity');

    $.ajax({
        url: location.href,
		dataType: 'html',
        success: function (response) {
			var $quantity = $(response).find('.b-site_header .wishlist-quantity');
			
			if ($item.length && $quantity.text()) {
				$item.text($quantity.text());
			}
        }
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents($container) {
	//initQuickViewButtons();
	loadDynamicImages($container);
    initTileSwatches($container);
    initWishListButtonsAction($container);
}

exports.init = function (options) {
    var $tiles = initTileIndexes(options);
    if (!$tiles) {
        return;
	}
	initializeEvents($tiles);
};
