'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
    util = require('./../../util'),
    validator = require('./../../../../../app_storefront_core/cartridge/js/validator');

var options = {
    modSuccessClass: 'm-success',
    notifyMeFormWrapperClass: 'b-notify',
    pdpForm: '.js-pdp_form'
};

var $cache = {},
    notifyMeFormWrapperJSClass = 'js-notify-me-form',
    notifyMeFormWrapper = '.' + notifyMeFormWrapperJSClass,
    notifyMeForm = notifyMeFormWrapper + ' form';

var notifyme = {
    init: function(params) {
    	initializeConfig(params);
        initializeCache();
        initializeEvents();
    }
};

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache() {
    $cache.document = $(document);
}

function initializeEvents() {
    $cache.document.on('click', function() {
        $(notifyMeFormWrapper).addClass('hidden');
    })
    .on('click', '.js-show-notifyme', showNotifyMeForm)
    .on('click', notifyMeFormWrapper, closeFormIfCancelClicked)
    .on('submit', notifyMeForm, sendAsyncNotifyMeForm);
}

function showNotifyMeForm(e) {
    e.stopPropagation();

    var $formWrapper = $(notifyMeFormWrapper);
    if($formWrapper.length) {
        $formWrapper.removeClass('hidden');
    } else {
        asyncFormLoad(e.target);
    }
}

function asyncFormLoad(notifymeButton) {
    var $loadedForm = $('<div>', {
        'class': 'hidden ' + options.notifyMeFormWrapperClass + ' ' + notifyMeFormWrapperJSClass
    });

    var productActionsData = getProductInfo();

    var urlParams = {
        pid: productActionsData.pid
    };

    var notifyMeURL = Urls.notifyMeFormShow;
    notifyMeURL = util.appendParamsToUrl(notifyMeURL, urlParams);
    $loadedForm.load(notifyMeURL, function() {
        $(notifymeButton).closest(options.pdpForm).after($loadedForm);
        $(notifyMeFormWrapper).removeClass('hidden');

        validator.init();
    });
}

function closeFormIfCancelClicked(e) {
    e.stopPropagation();

    if($(e.target).hasClass('js-close_notify-me')) {
        e.preventDefault();
        $cache.document.trigger('click');
    }
}

function sendAsyncNotifyMeForm(e) {
    e.preventDefault();
    var $this = $(this);
    $.ajax({
        url: $this.attr('action'),
        type: $this.attr('method'),
        data: $this.serialize(),
        dataType: 'json',
        success: notifyMeSubmitCallback
    });
}

function notifyMeSubmitCallback(response) {
    var $notifyMeFormWrapper = $(notifyMeFormWrapper);
    if(response.success) {
        $cache.document.trigger('notifyme.success', [$notifyMeFormWrapper]);
        $notifyMeFormWrapper.html(response.html);
        $notifyMeFormWrapper.addClass(options.modSuccessClass);
    } else {
        var $emailField = $notifyMeFormWrapper.find('input[type=email]');
        var errors = {};
        errors[$emailField.attr('name')] = response.error;
        $notifyMeFormWrapper.find('form').validate().showErrors(errors);
    }
}

function getProductInfo() {
    return $('.js-product_actions').first().data();
}

module.exports = notifyme;
