'use strict';

var COOKIE_NAME_SESSION_ID = 'sid',
    COOKIE_NAME_LOCALE = 'selectedLocale',
    PLUGIN_LANGUAGE_DEFAULT = 'en';

var $cache = {},
    cookie = require('../util/cookie'),
    dialog = require('./../../../../app_storefront_core/cartridge/js/dialog'),
    util = require('./../util'),
    opts = {
        allowedActions: {
            'customize': false
        },
        selectors: {
            pdpCustomizationWrap: '#js-pdp-customization',
            customizeBtn: '#js-customize-product',
            deleteCustomizationBtn: '#js-customization-delete',
            previewCustomizationBtn: '.js-customization-preview'
        }
    };

function initializeConfig(params) {
    opts = $.extend({}, opts, params);
}

function isCustomizationEnabled() {
    return SitePreferences.IS_PRODUCT_CUSTOMIZATION_ENABLED;
}

function isPluginLoaded() {
    return 'ppplaca' in window;
}

function createGlobalPluginVars() {
    window.placca_tool_rep_url = Urls.sendProductCustomizationToRepo;
    window.placca_tool_web_url = Urls.saveProductCustomization;
    window.placca_tool_root_files = Urls.productCustomizerAssetDir;
}

function initializeCache() {
    $cache.window = $(window);
    $cache.body = $('body');
}

function initializeEvents() {
    if (opts.allowedActions.customize && isCustomizationEnabled() && isPluginLoaded()) {
        $cache.body.on('click', opts.selectors.customizeBtn, openCustomizeDialog);
        $cache.body.on('click', opts.selectors.deleteCustomizationBtn, deleteCustomization);
        $cache.window.on('placca_modal_closed', showPDPCustomization);
    }

    $cache.body.on('click', opts.selectors.previewCustomizationBtn, previewCustomization);

}

function openCustomizeDialog(e) {
    e.preventDefault();

    var $customizationWrap = jQuery(opts.selectors.pdpCustomizationWrap),
        productID = $customizationWrap.data('pid') || '',
        plaqueSize = $customizationWrap.data('plaque-size') || '',
        sessionID = cookie.get(COOKIE_NAME_SESSION_ID) || '',
        language = getPluginLanguage();

    ppplaca.init(productID, plaqueSize, sessionID, language);
}

function getPluginLanguage() {
    var supportedLangs = [
        'de',
        'en',
        'fr',
        'it',
        'zh'
    ],
        locale = cookie.get(COOKIE_NAME_LOCALE) || '',
        lang = locale.split('_')[0] || PLUGIN_LANGUAGE_DEFAULT;

    lang = lang.toLowerCase();

    if (supportedLangs.indexOf(lang) === -1) {
        lang = PLUGIN_LANGUAGE_DEFAULT;
    }

    return lang;
}

function deleteCustomization(e) {
    e.preventDefault();

    var $customizationWrap = jQuery(opts.selectors.pdpCustomizationWrap),
        productID = $customizationWrap.data('pid') || '';

    $.ajax({
        url: Urls.deleteProductCustomization,
        type: 'POST',
        data: {
            pid: productID
        },
        success: function (data) {
            if (data.success) {
                showPDPCustomization();
            }
        }
    });
}

function previewCustomization(e) {
    e.preventDefault();

    var $clickedEl = jQuery(this),
        $dialogContent = jQuery('<div>'),
        $imageWrapper = jQuery('<div class="customization-popup" />'),
        $image = jQuery('<img/>')
            .attr('src', $clickedEl.data('image') || '')
            .attr('title', $clickedEl.data('title') || '');

    $imageWrapper.append($image);
    $dialogContent.append($imageWrapper);

    dialog.open({
        html: $dialogContent.html(),
        options: {
            closeOnEscape: true
        }
    });
}

function showPDPCustomization() {
    var $customizationWrap = jQuery(opts.selectors.pdpCustomizationWrap),
        productID = $customizationWrap.data('pid') || '',
        url = util.appendParamToURL(Urls.productCustomizationShow, 'pid', productID);

    $.ajax({
        url: url,
        success: function (data) {
            $customizationWrap.html(data);
        }
    });
}

module.exports = {
    init: function (params) {
        initializeConfig(params);

        if (opts.allowedActions.customize && isCustomizationEnabled() && isPluginLoaded()) {
            createGlobalPluginVars();
        }

        initializeCache();
        initializeEvents();
    }
}
