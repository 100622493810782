var _ = require('lodash'),
		util = require('./../../../../../app_storefront_core/cartridge/js/util'),
		tls = require('./../../tls');


var $cache = {},
		autocomplete,
		placesConfig = {},
		isShippingAddressValidated,
		isAddrSelectedFromBook = false;

var loadScript = _.once(function (url, data) {
	$.getScript(url, data);
});

function initCache() {
	$cache.address1Input = $('input[name$="_addressFields_address1"]');
	$cache.address2Input = $('input[name$="_addressFields_address2"]');
	$cache.cityInput = $('input[name$="_addressFields_city"]');
	$cache.countrySelect = $('select[id$="_country"]');
	$cache.stateSelect = $('select[name$="_addressFields_states_state"]');
	$cache.postalCodeInput = $('input[name$="_addressFields_postal"]');
}

function initEvents() {
	$cache.address1Input.on("input", function () {//focus
		removeCountryError();
	});
	watchAddressBook();
}

function initPlacesConfig() {
	var selectedCountry = tls.getCookie("selectedCountry");
	var config = JSON.parse(SitePreferences.GOOGLE_PLACES_CONFIG);
	if (config) {
		for (var defaultConfig in config.default) {
			placesConfig[defaultConfig] = config.default[defaultConfig];
		}

		if (selectedCountry in config) {
			for (var countryConfig in config[selectedCountry]) {
				placesConfig[countryConfig] = config[selectedCountry][countryConfig];
			}
		}
	}
}

function loadMapsScript(callback) {
	return loadScript(util.appendParamsToUrl('//maps.googleapis.com/maps/api/js', {
		key: SitePreferences.GOOGLE_PLACES_API_KEY,
		libraries: 'places'
	}), callback);
}

function initAutocomplete() {
	autocomplete = $cache.address1Input[0] ? new google.maps.places.Autocomplete($cache.address1Input[0], {
		types: ['address']
	}) : null;
	
	if (!autocomplete) {return};
	
	autocomplete.setFields(['address_component']);
	autocomplete.addListener('place_changed', fillInAddress);
	// disable all fields except main for autofill
	if (placesConfig.strict_mode){
		toggleFields().disableAndWatch();
		$cache.address1Input.change(function(){
			removeDropdownNote();
		});
	}
}


// Watch if a user has selected their address from the book manually
function watchAddressBook(){
	var addrBook = document.querySelector("select[name$='_addressList']");
	if(addrBook) {
		// If selected from the book dropdown manually
		addrBook.addEventListener("click", function (){
			isAddrSelectedFromBook = true;
		});
		// If an address wasn't selected but
		// the autofilled address matches one of the book's options
		var resultArr = [];
		var addrOptions = addrBook.getElementsByTagName("option");
		var addrFieldVal = document.querySelector('input[name$="_addressFields_address1"]').value;
		Array.from(addrOptions).forEach(function(opt){
			var optionAddr1 = "";
			var val = opt.dataset.address;
			if(val){
				optionAddr1 = JSON.parse(val).address1;
			}
			if(optionAddr1 === addrFieldVal){
				resultArr.push("match");
			}
		});
		if(resultArr.indexOf("match") !== -1) {
			isAddrSelectedFromBook = true;
		}
	}
}


var place;
function fillInAddress() {
	place = autocomplete.getPlace();
	var street_number = '';
	var street_name = '';
	var city_name = '';
	var zip_code = '';
	var state_name = '';
	var state_name_short = '';
	var country_name = '';
	var country_name_short = '';
	var postal_town = '';
	var sublocality_level_1 = '';
	if (place.address_components) {
		if (place.address_components.length > 0) {
			for (var i = 0; i < place.address_components.length; i++) {
				var addressType = place.address_components[i].types[0];
				switch (addressType) {
					case 'street_number':
						street_number = place.address_components[i].long_name;
						break;
					case 'route':
						street_name = place.address_components[i].long_name;
						break;
					case 'locality':
						city_name = place.address_components[i].long_name;
						break;
					case 'postal_town':
						postal_town = place.address_components[i].long_name;
						break;
					case 'sublocality_level_1':
						sublocality_level_1 = place.address_components[i].long_name;
						break;
					case 'postal_code':
						zip_code = place.address_components[i].long_name;
						break;
					case 'administrative_area_level_1':
						state_name = place.address_components[i].long_name;
						state_name_short = place.address_components[i].short_name;
						break;
					case 'country':
						country_name = place.address_components[i].long_name;
						country_name_short = place.address_components[i].short_name;
						break
				}
			}

			if ($cache.countrySelect.val() === country_name_short) {
				if (!!street_name) {
					if (placesConfig.number_first) {
						$cache.address1Input.val((!!street_number ? (street_number + ' ') : '') + street_name);
					} else {
						$cache.address1Input.val(street_name + (!!street_number ? (' ' + street_number) : ''));
					}
				} else {
					$cache.address1Input.val('');
				}

				var formattedCity = cityFormatted(city_name, postal_town, sublocality_level_1);
				$cache.postalCodeInput.val(!!zip_code ? zip_code : '');
				$cache.cityInput.val(!!formattedCity ? formattedCity : '');

				if (placesConfig.state_province && !!$cache.stateSelect.length) {
					$cache.stateSelect.val(!!state_name_short ? state_name_short : '');
				}
				if (placesConfig.strict_mode) {
					toggleFields().enable();
				}
			} else {
				displayCountryError();
			}
		}
	}
}

// Triggered for address forms by app_storefront_core_UI/cartridge/js/pages/checkout/index.js
function checkOnSubmit(){
	var address1InputVal = $cache.address1Input.val();
	if (address1InputVal.length > 0){
		if(!place && placesConfig.enabled && isAddrSelectedFromBook){// if the address was selected from book but not dropdown
			isShippingAddressValidated = true;
		} else if(!place && placesConfig.enabled){// mark non-validated address)
			isShippingAddressValidated = false;
		} else if (place && placesConfig.enabled){// if user has selected a value from the dropdown
			isShippingAddressValidated = true;
		} else if (!placesConfig.enabled){// if the check is off, consider the address always good
			isShippingAddressValidated = isShippingAddressValidated || true;
		}
	// Add cookie for passing isShippingAddressValidated to Order attr
		createAddrStatusCookie(isShippingAddressValidated);
	} // else: the address is empty, and will be handled by another validator
}

function createAddrStatusCookie(status) {
	var cookieName = "isShippingAddressValidated";
	var cookieExpDate = new Date(Date.now() + 3600000).toLocaleString();
	document.cookie = cookieName + "=" + status + ";path=/;expires=" + cookieExpDate;
}

function toggleFields(){
	var inputsArr = [$cache.address2Input, $cache.cityInput, $cache.postalCodeInput];
	function disableAndWatch(){
		inputsArr.forEach(function(input){
			input.prop("disabled", true);
			input.parent().click(function(){
				displayDropdownNote();
			});
		});
	}
	function enable(){
		inputsArr.forEach(function(input){
			input.prop("disabled", false);
			input.parent().unbind("click");
		});
	}
	return {disableAndWatch, enable};
}

var fieldLabel = $(".f-label[for$='_addressFields_address1']");
function displayDropdownNote(){
	if($(".address-pls-select").length == 0 && !place){
		var dropLabel = document.createElement('p');
		$cache.address1Input.addClass('error');
		$(dropLabel).attr('class', 'address-pls-select');
		$(dropLabel).text(Resources.ADDRESS_DROPDOWN);
		fieldLabel.append($(dropLabel));
	}
	$('input[name$="_addressFields_address1"]').focus();
}

function removeDropdownNote(){
	if ($(".address-pls-select").length){
		$(".address-pls-select").remove();
	}
	$cache.address1Input.removeClass('error');
}


function displayCountryError() {
	$cache.address1Input.addClass('error');
	var label_countryError = document.createElement('p');
	$(label_countryError).attr('id', 'country-error-message');
	$(label_countryError).addClass('error');
	$(label_countryError).text(Resources.WRONG_COUNTRY);
	$cache.address1Input.val('');
	$cache.address1Input.parent().append($(label_countryError));
	place = null;
}

function removeCountryError() {
	if ($('#country-error-message').length) {
		$('#country-error-message').remove();
		$cache.address1Input.removeClass('error');
	}
	// Restore strict mode limitations
	if (placesConfig.strict_mode){
		toggleFields().disableAndWatch();
	}
}

function cityFormatted(param_city, param_postalTown, param_sublocality_level_1) {
	var selectedCountry = tls.getCookie("selectedCountry");
	var arrayNation = ["SE", "GB"];

	if (!!selectedCountry) {
		if (arrayNation.indexOf(selectedCountry) >= 0)
			return param_postalTown;
		else {
			if (selectedCountry == "US") {
				return !!param_sublocality_level_1 ? param_sublocality_level_1 : param_city;
			}
			return param_city;
		}
	}

	return param_city;
}

exports.placesConfig = placesConfig;
exports.checkOnSubmit = checkOnSubmit;

exports.init = function () {
	initPlacesConfig();
	if (placesConfig.enabled) {
		initCache();
		initEvents();
		loadMapsScript(initAutocomplete);
	}
};
