'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
    carousel = require('./../../carousel'),
	util = require('./../../util');
var currentLocation = window.location.href;
var isPostLoadEnabled = !!SitePreferences.isPLPPostLoadEnabled;
var initialPageSize = SitePreferences.initialPageSize,
	resultPageSize = (+util.getParameterValueFromUrl('sz', currentLocation) || SitePreferences.resultPageSize),
	loadPartPageSize = SitePreferences.loadPartPageSize;
var size = +loadPartPageSize;
var productTile = require('./../../product-tile');
var isForceBreak = false;

function getRequestsURLs(){
	var numberOfRequests = ((resultPageSize - initialPageSize) / loadPartPageSize);
	var requestsUrlArr = [];
	var stepUrl = '';
	for(var i = 0; i < numberOfRequests; i += 1){
		requestsUrlArr.push(generateStepURL(i));
	}
	return requestsUrlArr;
}

function generateStepURL(iteration){
	var start = +util.getParameterValueFromUrl('start', currentLocation) || 0;
	var clearStartURL = util.removeParamFromURL(currentLocation, 'start');
	var startValue = start + initialPageSize + ((iteration) * loadPartPageSize);
	var resultURL = util.appendParamToURL(clearStartURL, 'start', startValue);
	resultURL = util.removeParamFromURL(resultURL, 'sz');
	resultURL = util.appendParamToURL(resultURL, 'sz', size);
	resultURL = util.ajaxUrl(resultURL);
	return resultURL;
}

function updatePLPWithProducts(){
	$(document).ready(function(){
		var requestURLs = getRequestsURLs();
		loadMoreProducts(requestURLs);
	});
}

function loadMoreProducts(requestURLs, step){
	var step = step || 0;
	var url = requestURLs[step];
	if(!url || isForceBreak){
		$(document).trigger('search.update', [{istriggered: true}]);
		productTile.init();
		return false;
	}
	$.ajax({
		url: url,
		method: 'GET'
	}).done(function (data) {
		if(data){
			var resultProductsWrapper = $(data).find('#search-result-items');
			if(!!resultProductsWrapper.length && !!resultProductsWrapper.find('.js-product_tile').length){
				productTile.init({container: resultProductsWrapper});
				$('#search-result-items').append(resultProductsWrapper.children());
				step += 1;
                carousel.init({});
				loadMoreProducts(requestURLs, step);
			}
		}
	});
}


function initEvents(){
	$(document).on('search.update', function(e, isTriggered){
		if( !isTriggered || ( typeof isTriggered == 'object' && !('istriggered' in isTriggered) && ('url' in isTriggered) ) ){
			currentLocation = isTriggered.url;
			isForceBreak = false;
			updatePLPWithProducts();
		}
	});
	$(document).on('click', '.b-pagination a', function(e){
		currentLocation = $(this).attr('href');
		isForceBreak = true;
	});
	
	$(document).on('click', '.js-pagesizeholder a', function(e){
		var pageSizeUrl = $(this).attr('href');
		resultPageSize = +util.getParameterValueFromUrl('sz', pageSizeUrl);
	});
}

module.exports = {
	init : function () {
		if(isPostLoadEnabled){
			updatePLPWithProducts();
			initEvents();
		}
	}
}
