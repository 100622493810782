'use strict'
var dialog = require('./../../dialog');
var tls = require('./../../tls');
var util = require('./../../util');
var cookieTTL = 60 * 60 * 24 * 365;
var presetSiteIDVal = SitePreferences.SITE_ID;
function initCountriesSelectorEvents(){
	if (window.history && window.history.state) {
		var historyState = window.history.state;
		processCountryChange(historyState.previousCountry, historyState.previousLocale, historyState.previousSiteId, historyState.previousUrl); 
		window.history.pushState(null, null, null);
	}
	
	$('body')
	.on('click', '.js-countries-selector .js-change_country', function(e){
		e.preventDefault();
		var historyState = createHistoryStateObj();
		if (historyState) {
			window.history.pushState(historyState, null, null);	
		}
		var $this = $(this),
			$countriesSelector = $this.closest('.js-countries-selector'),
			countryCode = $this.data('country'),
			countryName = $this.text().trim(),
			countryLocale = $this.data('locale'),
			siteID = $this.data('site-id'),
			pageUrlParams = $countriesSelector.data('page-params'),
			url = $this.attr('href'),
			isShowDialog = (siteID != SitePreferences.SITE_ID) && !$countriesSelector.hasClass('js-countries_list');
		
		url = util.appendParamsToUrl(url, {
			pageparams: pageUrlParams,
			ccode: countryCode,
			siteid: siteID
		});
		
		showConfirmationDialog(isShowDialog, countryName, null, processCountryChange, [countryCode, countryLocale, siteID, url]);
	})
	.on('change', '.js-change_country-select', function(e){
		e.preventDefault();
		var historyState = createHistoryStateObj();
		if (historyState) {
			window.history.pushState(historyState, null, null);	
		}
		var $this = $(this),
			countryOption = $this.find(":selected"),
			countryCode = countryOption.val(),
			countryLocale = countryOption.data('locale'),
			currentCountryCode = $this.find('option[selected]').attr('value'),
			siteID = countryOption.data('siteId'),
			pageUrlParams = $this.closest('.js-mobile-locale_block').data('page-params'),
			selectorUrl = countryOption.data('href'),
			isShowDialog = (siteID != SitePreferences.SITE_ID) && !$this.hasClass('js-countries_list');

		selectorUrl = util.appendParamsToUrl(selectorUrl, {
			pageparams: pageUrlParams,
			ccode: countryCode,
			siteid: siteID
		});

		$this.parent()
			.removeClass('flag-icon-' + currentCountryCode.toLowerCase())
			.addClass('flag-icon-' + countryCode.toLowerCase());

		showConfirmationDialog(isShowDialog, countryCode, null, processCountryChange, [countryCode, countryLocale, siteID, selectorUrl]);
	})
	.on('change' , '.js-select-countries_selector', function(e) {
		var $countrySelect = $(this),
			countryOption = $countrySelect.find(":selected"),
			countryCode = countryOption.val(),
			countryName = countryOption.text(),
			countryLocale = countryOption.data('locale'),
			siteID = countryOption.data('siteId'),
			pageUrlParams = $countrySelect.data('page-params'),
			selectorUrl = countryOption.data('href');
		
		selectorUrl = util.appendParamsToUrl(selectorUrl, {
			pageparams: pageUrlParams,
			ccode: countryCode,
			siteid: siteID
		});
		if ($countrySelect.hasClass('js-cart-select')) {
			
			var currentCountryCode = $countrySelect.find('option[selected]').attr('value'),
				isShowDialog = (siteID != SitePreferences.SITE_ID);
			
			$countrySelect.parent()
				.removeClass('flag-icon-' + currentCountryCode.toLowerCase())
				.addClass('flag-icon-' + countryCode.toLowerCase());
			showConfirmationDialog(isShowDialog, countryName, function () {
				$countrySelect
					.val(currentCountryCode)
					.parent()
					.removeClass('flag-icon-' + countryCode.toLowerCase())
					.addClass('flag-icon-' + currentCountryCode.toLowerCase());
			}, processCountryChange, [countryCode, countryLocale, siteID, selectorUrl]);
		}
	});
}
function showConfirmationDialog(isShowDialog, countryName, closeAction, confirmAction, confirmParams) {
	if (isShowDialog) {
		dialog.open({
			url: Urls.confirmSiteChange,
			data: {
				cname: countryName
			},
			options: {
				closeOnEscape: true,
				dialogClass: 'dialog-choose_country',
				close: closeAction,
				buttons: [
					{
						text: Resources.CONFIRM,
						'class': 'b-button',
						click: (function () {
							var that = this;
							$.get(confirmParams.pop(), function (data) {
								if (data.success) {
									confirmParams.push(data.url);
									confirmAction.apply(null, confirmParams);
								} else {
									$(this).dialog("close");
								}
							});

						}).bind(this)
					},
					{
						text: Resources.CLOSE,
						'class': 'b-button m-white',
						click: function () {
							$(this).dialog("close");
						}
							}
						]
			}

		});
		return false;
	} else {
		$.get(confirmParams.pop(), function (data) {
			if (data.success) {
				confirmParams.push(data.url);
				confirmAction.apply(null, confirmParams);
			}
		});
	}
}
function initLanguageSelectorEvents(){
	$('body')
	.on('click', '.js-change_language', function(e){
		var $linkSelector = $(this),
			country = $linkSelector.data('country'),
			locale = $linkSelector.data('locale'),
			changeLanguageURL;	
		
		processLanguageChange(country, locale);
		e.preventDefault();
		changeLanguageURL = util.appendParamsToUrl($linkSelector.attr('href'), {
			pageparams: $linkSelector.closest('.js-languages-selector').data('page-params'),
			ccode: country,
			locale: locale
		});
		$.get(changeLanguageURL, function(data) {
			if(data.success) {
				location.href = data.url;
			}
		});
	})
	.on('change', '.js-change_language-select', function(e){
		var $this = $(this),
			$linkSelector = $this.find(":selected"),
			country = $linkSelector.data('country'),
			locale = $this.val(),
			changeLanguageURL;	
		
		processLanguageChange(country, locale);
		
		changeLanguageURL = util.appendParamsToUrl($linkSelector.data('href'), {
			pageparams: $linkSelector.closest('.js-mobile-locale_block').data('page-params'),
			ccode: country,
			locale: locale
		});
		
		$.get(changeLanguageURL, function(data) {
			if(data.success) {
				location.href = data.url;
			}
		});
	});
}

function countryMissmatchCheck(){
	var isSiteSuggested = tls.getCookie('isSuggestedSite');
	if(isSiteSuggested && isSiteSuggested == 'true'){
		dialog.open({
			url: Urls.countriesSuggest,
			options: {
				closeOnEscape: true,
				close: function() {
					setSiteSuggestAccepted();
				},
				beforeClose: function(){
					setSelectedSiteOnClose();
				},
				dialogClass: 'dialog-choose_country' 
			}
		});
	}
}

function setSiteSuggestAccepted(){
	tls.setCookie('isSuggestedSite', 'false', cookieTTL);
}

function setSelectedSiteOnClose(){
	tls.setCookie('lastsiteid', presetSiteIDVal, 0);
}

function initSuggestDialogEvents(){
	$('body').on('click', '.js-accept-current-country', function(e){
		var $triggeredElement = $(this),
			$suggestDialogSelect = $('.js-country-suggest-wrapper .js-select-countries_selector'),
			customerCountry = $triggeredElement.data('ccountryId'),
			requestedCountry = $triggeredElement.data('rcountryId'),
			$correspondingOptionEl,
			correspondSiteIdVal;
		
		if(customerCountry != requestedCountry){
			$correspondingOptionEl = $suggestDialogSelect.find('option[value='+ customerCountry +']');
			correspondSiteIdVal = $correspondingOptionEl.data('siteId');
			presetSiteIDVal = correspondSiteIdVal;
			$suggestDialogSelect.val(customerCountry).change();
		}
		if(dialog.isActive()){
			dialog.close();
		}
		
	});
	
}

function processCountryChange(countryCode, countryLocale, siteID, selectorUrl){
	if (!siteID) siteID = SitePreferences.SITE_ID;
	if(!countryCode || !countryLocale){
		return;
	}
	tls.setCookie('selectedCountry', countryCode, cookieTTL);
	tls.setCookie('selectedLocale', countryLocale, cookieTTL);
	tls.setCookie('lastsiteid', siteID, 0);
	//setSiteSuggestAccepted();
	window.location.href = selectorUrl;
}

function processLanguageChange(countryCode, countryLocale){
	var historyState = createHistoryStateObj();
	if (historyState) {
		window.history.pushState(historyState, null, null);	
	}
	tls.setCookie('selectedCountry', countryCode, cookieTTL);
	tls.setCookie('selectedLocale', countryLocale, cookieTTL);
}

function createHistoryStateObj(){
	var previousCountry = $('.js-current-country').data('currentcountry');
	var previousLocale = $('.js-selected-locale').data('selectedlocale');
	var previousSiteId = $('.js-current-country').data('currentsiteid');
	var previousUrl = $('.js-selected-locale').data('currenturl');
	var historyState = { 'previousCountry': previousCountry,
		'previousLocale': previousLocale,
		'previousSiteId': previousSiteId, 
		'previousUrl' : previousUrl
	};
	return historyState;
}

module.exports = {
	init: function(){
//		countryMissmatchCheck(); 
		initCountriesSelectorEvents();
		initLanguageSelectorEvents();
		initSuggestDialogEvents(); 
	}
};