'use strict';

var _ = require('lodash'),
	progress = require('./../../../app_storefront_core/cartridge/js/progress');

var util = {
	/**
	 * @function
	 * @description appends the parameter with the given name and value to the given url and returns the changed url
	 * @param {String} url the url to which the parameter will be added
	 * @param {String} name the name of the parameter
	 * @param {String} value the value of the parameter
	 */
	appendParamToURL: function (url, name, value) {
		// quit if the param already exists
		if (url.indexOf(name + '=') !== -1) {
			return url;
		}
		var separator = url.indexOf('?') !== -1 ? '&' : '?';
		return url + separator + name + '=' + encodeURIComponent(value);
	},

	/**
	 * @function
	 * @description remove the parameter and its value from the given url and returns the changed url
	 * @param {String} url the url from which the parameter will be removed
	 * @param {String} name the name of parameter that will be removed from url
	 */
	removeParamFromURL: function (url, name) {
		if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
			return url;
		}
		var hash;
		var params;
		var domain = url.split('?')[0];
		var paramUrl = url.split('?')[1];
		var newParams = [];
		// if there is a hash at the end, store the hash
		if (paramUrl.indexOf('#') > -1) {
			hash = paramUrl.split('#')[1] || '';
			paramUrl = paramUrl.split('#')[0];
		}
		params = paramUrl.split('&');
		for (var i = 0; i < params.length; i++) {
			// put back param to newParams array if it is not the one to be removed
			if (params[i].split('=')[0] !== name) {
				newParams.push(params[i]);
			}
		}
		return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
	},

	/**
	 * @function
	 * @description appends the parameters to the given url and returns the changed url
	 * @param {String} url the url to which the parameters will be added
	 * @param {Object} params
	 */
	appendParamsToUrl: function (url, params) {
		var _url = url;
		_.each(params, function (value, name) {
			_url = this.appendParamToURL(_url, name, value);
		}.bind(this));
		return _url;
	},
	/**
	 * @function
	 * @description extract the query string from URL
	 * @param {String} url the url to extra query string from
	 **/
	getQueryString: function (url) {
		var qs;
		if (!_.isString(url)) { return; }
		var a = document.createElement('a');
		a.href = url;
		if (a.search) {
			qs = a.search.substr(1); // remove the leading ?
		}
		return qs;
	},
	/**
	 * @function
	 * @description
	 * @param {Object}
	 * @param {Object}
	 */
	elementsOverlapped: function($element1, $element2) {
		if (!$element1 || !$element1.is(":visible") || !$element2 || !$element2.is(":visible")) return false;

		var offset1 = $element1.offset(),
			offset2 = $element2.offset();

		offset1.right = offset1.left + $element1.outerWidth(true);
		offset1.bottom = offset1.top + $element1.outerHeight(true);

		offset2.right = offset2.left + $element2.outerWidth(true);
		offset2.bottom = offset2.top + $element2.outerHeight(true);

		return !(offset1.right <= offset2.left ||
				offset1.left >= offset2.right ||
				offset1.bottom <= offset2.top ||
				offset1.top >= offset2.bottom);
	},
	/**
	 * @function
	 * @description
	 * @param {String}
	 * @param {String}
	 */
	elementInViewport: function (el, offsetToTop) {
		var top = el.offsetTop,
			left = el.offsetLeft,
			width = el.offsetWidth,
			height = el.offsetHeight;

		while (el.offsetParent) {
			el = el.offsetParent;
			top += el.offsetTop;
			left += el.offsetLeft;
		}

		if (typeof(offsetToTop) !== 'undefined') {
			top -= offsetToTop;
			height += offsetToTop;
		}

		if (window.pageXOffset !== null) {
			return (
				top < (window.pageYOffset + window.innerHeight) &&
				left < (window.pageXOffset + window.innerWidth) &&
				(top + height) > window.pageYOffset &&
				(left + width) > window.pageXOffset
			);
		}

		if (document.compatMode === 'CSS1Compat') {
			return (
				top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
				left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
				(top + height) > window.document.documentElement.scrollTop &&
				(left + width) > window.document.documentElement.scrollLeft
			);
		}
	},

	/**
	 * @function
	 * @description Appends the parameter 'format=ajax' to a given path
	 * @param {String} path the relative path
	 */
	ajaxUrl: function (path) {
		return this.appendParamToURL(path, 'format', 'ajax');
	},

	/**
	 * @function
	 * @description
	 * @param {String} url
	 */
	toAbsoluteUrl: function (url) {
		if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
			url = '/' + url;
		}
		return url;
	},
	/**
	 * @function
	 * @description Loads css dynamically from given urls
	 * @param {Array} urls Array of urls from which css will be dynamically loaded.
	 */
	loadDynamicCss: function (urls) {
		var i, len = urls.length;
		for (i = 0; i < len; i++) {
			this.loadedCssFiles.push(this.loadCssFile(urls[i]));
		}
	},

	/**
	 * @function
	 * @description Loads css file dynamically from given url
	 * @param {String} url The url from which css file will be dynamically loaded.
	 */
	loadCssFile: function (url) {
		return $('<link/>').appendTo($('head')).attr({
			type: 'text/css',
			rel: 'stylesheet'
		}).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
	},
	// array to keep track of the dynamically loaded CSS files
	loadedCssFiles: [],

	/**
	 * @function
	 * @description Removes all css files which were dynamically loaded
	 */
	clearDynamicCss: function () {
		var i = this.loadedCssFiles.length;
		while (0 > i--) {
			$(this.loadedCssFiles[i]).remove();
		}
		this.loadedCssFiles = [];
	},
	/**
	 * @function
	 * @description Extracts all parameters from a given query string into an object
	 * @param {String} qs The query string from which the parameters will be extracted
	 */
	getQueryStringParams: function (qs) {
		if (!qs || qs.length === 0) { return {}; }
		var params = {};
		// Use the String::replace method to iterate over each
		// name-value pair in the string.
		qs.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
			function ($0, $1, $2, $3) {
				params[$1] = decodeURIComponent($3).replace(/\+/g, ' ');
			}
		);
		return params;
	},

	getParameterValueFromUrl: function(parameterName, url) {
		var currentQueryString = url || window.location.search;
		var currentQueryStringParams = this.getQueryStringParams(currentQueryString);

		return currentQueryStringParams[parameterName];
	},

	fillAddressFields: function (address, $form) {
		for (var field in address) {
			if (field === 'ID' || field === 'UUID' || field === 'key' || field === 'city' || field === 'district') {
				continue;
			}
			// if the key in address object ends with 'Code', remove that suffix
			// keys that ends with 'Code' are postalCode, stateCode and countryCode
			$form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]).trigger('change');
			// update the state fields
			if (field === 'countryCode') {
				$form.find('[name$="country"]').data('state', address.stateCode).trigger('change');
			}
			if (field === 'fullName') {
				$form.find('[name$="' + field.replace('Code', '') + '"]').val(address['firstName']).trigger('change');
			}
		}

    //For CN site the province field has to be set before the city and district
    if (address.city) {
      $form.find('[name$="city"]').val(address.city).trigger('change');
    }

    if (address.district) {
      $form.find('[name$="district"]').val(address.district).trigger('change');
    }
	},
	/**
	 * @function
	 * @description Updates the number of the remaining character
	 * based on the character limit in a text area
	 */
	limitCharacters: function () {
		$('form').find('textarea[maxlength]').each(function () {
			var characterLimit = $(this).attr('maxlength');
			var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
				'<span class="char-remain-count">' + characterLimit + '</span>',
				'<span class="char-allowed-count">' + characterLimit + '</span>');
			var charCountContainer = $(this).next('div.char-count');
			if (charCountContainer.length === 0) {
				charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
			}
			charCountContainer.html(charCountHtml);
			// trigger the keydown event so that any existing character data is calculated
			$(this).change();
		});
	},
	/**
	 * @function
	 * @description Binds the onclick-event to a delete button on a given container,
	 * which opens a confirmation box with a given message
	 * @param {String} container The name of element to which the function will be bind
	 * @param {String} message The message the will be shown upon a click
	 */
	setDeleteConfirmation: function (container, message) {
		var utils = this,
			dialog = require('./dialog'),
			dialogURL = utils.ajaxUrl(Urls.pageShow),
			confirmResult = false;

		$(container).on('click', '.delete', function (event) {
			var $this = $(this),
				$dialog;

			if (!confirmResult) {
				event.preventDefault();
				event.stopPropagation();
			} else {
				return;
			}

			dialog.open({
				'url' : utils.appendParamToURL(dialogURL, 'cid', Resources.CONFIRMATION_DIALOG_CID),
				'options' : {
					'width' : 400
				},
				'callback' : function() {
					$('#delete-cancel')
					.on('click', closeDialogCallback);

					$('#delete-confirm')
					.on('click', closeDialogCallback)
					.on('click', function (event) {
						confirmResult = true;
						$this[0].click();
					});

					function closeDialogCallback(event) {
						event.preventDefault();
						$dialog.dialog('isOpen') && $dialog.dialog('close');
					}
				}
			});

			$dialog = dialog.$container;
			return false;
		});

	},
	/**
	 * @function
	 * @description Scrolls a browser window to a given x point
	 * @param {String} The x coordinate
	 */
	scrollBrowser: function (xLocation, duration, callback) {
		var duration = typeof(duration) !== 'undefined' ? duration : 500;
		$('html, body').animate({scrollTop: xLocation}, duration, function() {
			if (typeof callback == 'function') {
				callback();
			}
		});
	},

	setWindowScrollTop: function(offset) {
		if(!offset) {
			return;
		}

		var fixedHeaderHeight = $('.js-sticky_header').height();
		var positionToScroll = offset - fixedHeaderHeight;
		if(positionToScroll <= 0) {
			return;
		}

		$(window).scrollTop(positionToScroll);
	},

	isMobile: function () {
		var mobileAgentHash = ['mobile', 'tablet', 'phone', 'ipad', 'ipod', 'android', 'blackberry', 'windows ce', 'opera mini', 'palm'];
		var idx = 0;
		var isMobile = false;
		var userAgent = (navigator.userAgent).toLowerCase();

		while (mobileAgentHash[idx] && !isMobile) {
			isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
			idx++;
		}
		return isMobile;
	},

	isIOS: function () {
		return (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream);
	},

	"eventDelay": function(callback, threshhold, scope, skipInitialCall) {
		threshhold || (threshhold = 250);
		var last,
			deferTimer;

		/**
		 * @todo  Add description
		 */
		return function() {
			var context = scope || this,
				now = (new Date()).getTime(),
				args = arguments;

			if (last && now < last + threshhold) {
				clearTimeout(deferTimer);
				deferTimer = setTimeout(function() {
					last = now;
					callback.apply(context, args);
				}, threshhold);
			} else {
				last = now;

				if (!skipInitialCall) {
					callback.apply(context, args);
				}
			}
		};
	},

	/**
	 * Throttling Function Calls
	 *
	 * @see http://www.nczonline.net/blog/2007/11/30/the-throttle-function/
	 * @param {Function} callback Callback function to call
	 * @param {Number} delay Delay before callback fire
	 * @param {Object} scope The context to for callback fire
	 */
	"throttle": function(callback, delay, scope) {
		clearTimeout(callback._tId);
		callback._tId = setTimeout(function() {
			callback.call(scope);
		}, delay || 100);
	},

	getTimer: function() {
		return {
			id: null,
			clear: function () {
				if (this.id) {
					window.clearTimeout(this.id);
					delete this.id;
				}
			},
			start: function (duration, callback) {
				this.id = setTimeout(callback, duration);
			}
		};
	},

	/**
	 * Add/remove value from array depending on its existing in array
	 * @param inArr
	 * @param value
	 * @returns {Array}
	 */
	toggleFromArray: function(inArr, value) {
		var arr = inArr.slice();
		var valueIndex = arr.indexOf(value);
		if(valueIndex >= 0) {
			arr.splice(valueIndex, 1);
		} else {
			arr.push(value);
		}

		return arr;
	},

	/**
	 * Helper function.
	 * Checks if two arrays have the same values. It doesn't depend from order
	 * @param arr1
	 * @param arr2
	 * @returns {Boolean}
	 */
	arraysEqualByValues: function(arr1, arr2) {
		if(!arr1 || !arr2) {
			return arr1 === arr2;
		}

		var len1 = arr1.length,
			len2 = arr2.length;

		if(len1 != len2) {
			return false;
		}
		for(var i = 0; i < len1; i++) {
			if(arr2.indexOf(arr1[i]) < 0) {
				return false;
			}
		}

		return true;
	},

	/**
	 * Helper function.
	 * Construct :nth-child selector with given element and start position
	 * @param {String} elem
	 * @param {Number} from
	 * @returns {String}
	 */
	fromNthSelector: function(elem, from) {
		var selector = elem + ':nth-child(n+';
		selector += (from + 1);
		selector += ')';

		return selector;
	},

	getKeyByValue: function(HashMapCollection, value ) {
		for( var prop in HashMapCollection ) {
			if( HashMapCollection.hasOwnProperty( prop ) ) {
				 if( HashMapCollection[ prop ] === value )
					 return prop;
			}
		}
	},

	/**
	 * [getUri description]
	 * @param  {[type]} o [description]
	 * @return {[type]}   [description]
	 */
	getUri : function(o) {
		var a, uri;

		if (o.tagName && $(o).attr('href')) {
			a = o;
		} else if (typeof o === 'string') {
			a = document.createElement("a");
			a.href = o;
		} else {
			return null;
		}

		// overcome some stupid ie behaviour
		if (a.host == '') {
			a.href = a.href;
		}

		// all actual version of IE not so smart to correctly process
		// protocol independent locations, so wee need to help them
		if (a.protocol === ':') {
			a.protocol = window.location.protocol;
		}

		// fix for some IE browsers
		if (a.pathname.indexOf('/') != 0) {
			a.pathname = '/' + a.pathname;
		}

		return Object.create({
			'protocol' : a.protocol, //http:
			'host' : a.host, //www.myexample.com
			'hostname' : a.hostname, //www.myexample.com'
			'port' : a.port, //:80
			'path' : a.pathname, // /sub1/sub2
			'query' : a.search, // ?param1=val1&param2=val2
			'queryParams' : a.search.length > 1 ? this.getQueryStringParams(a.search.substr(1)) : {},
			'hash' : a.hash, // #OU812,5150
			'url' : a.protocol + '//' + a.host + a.pathname,
			'toString' : function () {
				return this.protocol + '//' + this.host + this.port + this.pathname + this.search + this.hash;
			}
		});
	},

	/**
	 * @description Watch change of element property
	 * @param {Object} element for watching
	 * @param {String} property name
	 * @param {Function} the function that gets called if property changes
	 * @return {Void}
	 */
	watchProperty: function(element, id, fn, delay) {
		delay = delay ? delay : 100;
		return element.each(function() {
			var _this = this,
				oldVal = _this[id];
			$(_this).data(
				'watch_timer_' + id,
				setInterval(function() {
					if (_this[id] !== oldVal) {
						fn.call(_this, id, oldVal, _this[id]);
						oldVal = _this[id];
					}
				}, delay)
			);
			return _this;
		});
	},

	/**
	 * @description Stop watch change of element property
	 * @param {Object} element for watching
	 * @param {String} property name
	 * @return {Void}
	 */
	stopWatchProperty: function(element, id) {
		return clearInterval($(element).data('watch_timer_' + id));
	},

	/**
	 * @description Bind events for printing
	 * @param {Function} callback which will be called before print
	 * @param {Function} callback which will be called after print
	 * @return {Void}
	 */
	bindPrintEvents: function(beforePrint, afterPrint) {
		if ('matchMedia' in window) {
			var mediaQueryList = window.matchMedia('print');
			mediaQueryList.addListener(function(mql) {
				if (mql.matches) {
					(typeof beforePrint === "function") && beforePrint();
				} else {
					(typeof afterPrint === "function") && afterPrint();
				}
			});
		}

		(typeof beforePrint === "function") && $(window).on('beforeprint', beforePrint);
		(typeof afterPrint === "function") && $(window).on('afterprint', afterPrint);
	},

	progressShow: function ($container) {
		$container.addClass('js-loading');
		progress.show($container);
	},

	progressHide: function ($container) {
		$container.removeClass('js-loading');
		progress.hide();
	}
};

module.exports = util;
