"use strict";

let searchKeyword = window.pageContext.searchPhrase || "",
  numberResults = window.pageContext.numberResults || "",
  searchType = window.pageContext.searchType || "",
  categoryID = window.pageContext.categoryID || "",
  categoryName = window.pageContext.categoryName || "",
  pageURL = window.location.href,
  customerID = window.pageContext.customerID || "",
  accountType = window.pageContext.accountType || "",
  // These events are sent on click by a selector at each step
  checkoutSteps = [
    // Cart
    // One of the Checkout buttons in cart is hidden,
    // another visible button has onclick attr to trigger a real submit
    {
      "selector": "#checkout-form button",// "Checkout"
      "checkoutPage": "checkout_cart",
      "eventName": "begin_checkout"
    },
    // Auth page - account & guest login, or registration
    // this page doesn't really matter for the funnel,
    // and thus doesn't have a number
    {
      "selector": ".js-login-checkout",// login/reg form
      "checkoutPage": "checkout_auth",
      "eventName": "checkout_login_registration"
    },

    // Shipping address & method selection
    {
      "selector": ".checkout-shipping button",// "Continue to billing"
      "checkoutPage": "checkout_shipping",
      "eventName": "add_shipping_info"
    },
    // Payment method selection and/or Billing address
    // pay on this page, on a popup or external page,
    {
      "selector": "#billing-submit",// "Continue to place order"
      "checkoutPage": "checkout_billing",
      "eventName": "add_payment_info"
    },
    // Pre-final step - confirmation page
    {
      "selector": "#place-order-button",// "Place order"
      "checkoutPage": "checkout_place_order",
      "eventName": "place_order"
    }
    // The next is orderconfirmation (thanks) page,
    // and it's processed separately with "purchase" event
  ],
  pageType = {
    "storefront": "homepage",
    "search": categoryID ? "category" : "search",
    "product": "product",
    "account":
      window.pageContext.type == "customerservice"
        ? "content"
        : "account",
    "cart": "cart",
    "checkout": "checkout",// divided into checkoutSteps
    "orderconfirmation": "orderconfirmation",// final thanks page
    "wishlist": "wishlist"
  }[window.pageContext.ns],

  pageVariables = {
    "global": {
      "page_type": pageType,
      "page_url": pageURL,
    }
    // Push page-specific global vars here:
    // "product": {
    //     "var": "val",
    // }
  };

// Adding more global vars
// PLP
if (searchKeyword) {
  $.extend(pageVariables.global, {
    "search_keyword": searchKeyword,
    "results_number": numberResults,
    "search_type": searchType
  });
} else if (categoryID) {
  $.extend(pageVariables.global, {
    "category_id": categoryID,
    "category_name": categoryName
  });
}

if (customerID) {
  $.extend(pageVariables.global, {
    "customer_id": customerID,
    "account_type": accountType
  });
}

let productListing = {
  "search": searchKeyword ? "search_results" : "category",
  "storefront": "homepage",
  "product": "product",
  "cart": "cart",
  "wishlist": "wishlist"
}[window.pageContext.ns];

let events = {
  "all_pages": [
    "account_login",
    "account_register"
  ],
  "search": [
    "view_item_list",
    "add_to_cart",
    "add_to_wishlist",
    "select_item",
    "view_item"
  ],
  "product": [
    "view_item",
    "add_to_cart",
    "add_to_wishlist"
  ],
  "cart": [
    "view_cart",
    "add_to_cart",
    "remove_from_cart",
    "add_to_wishlist",
    "checkout"
  ],
  "wishlist": [
    "add_to_cart"
  ],
  "checkout": [
    "checkout"// divided into checkoutSteps
  ],
  "orderconfirmation": [
    "purchase"
  ],
};

module.exports = {
  pageVariables: pageVariables,
  checkoutSteps: checkoutSteps,
  productListing: productListing,
  events: events
};
