'use strict';

var address = require('./address'),
	billing = require('./billing'),
	multiship = require('./multiship'),
	shipping = require('./shipping'),
	addresscountry = require('./../address/addresscountry'),
	orderDuties = require('./../../util/order-duties'),
	autocompleteAddress = require('./autocomplete'),
	isFormSubmitted = false,
	btcRates = require('../product/btcRates'),
	gpay = require('../product/gpay'),
	adyenSummary = require('../../summary');

gpay.waitForLoadElement('#googleData');
/**
 * @function Initializes the page events depending on the checkout stage (shipping/billing)
 */
exports.init = function () {
	address.init();
	if ($('.checkout-shipping').length > 0) {
		addresscountry.hideStateField($('.checkout-shipping'));
		shipping.init();
	} else if ($('.checkout-multi-shipping').length > 0) {
		multiship.init();
	} else {
		addresscountry.hideStateField($('.checkout-billing'));
		addresscountry.init();
		billing.init();
	}

	autocompleteAddress.init();

	// Protection from incorrect address submit in case Places Autofill is enabled
	$('.checkout-shipping').on('submit', function (e) {
		var placesConfig = autocompleteAddress.placesConfig;
		var isAutofillEnabled = placesConfig.enabled;
		if (isAutofillEnabled){
			autocompleteAddress.checkOnSubmit(e);
		}
	});

	// Check for products availability & check order consent checkbox
	if ($('.js-place_order-button').length > 0) {
		// If on the order review page and there are products that are not available disable the submit order button
		if ($('.notavailable').length > 0) {
			$('.js-place_order-button').attr('disabled', 'disabled');
		}
		// Check terms agreement automatically on click by place order btn (even if it was unchecked)
		var termsCheck = document.getElementById("order-terms-consent");
		var plOrderBtn = document.getElementById("place-order-button");
		plOrderBtn.addEventListener("click", () => {
			termsCheck.checked = true;
		});
	}

	// Protection from clicking "Place order" button many consecutive times
	$('.submit-order').on('submit', function (e) {
		var paymentName = $('.mini-payment-instrument.order-component-block .details #paymentname').data('paymentmethod');
		if (((window.location.pathname.includes('billing') && !window.location.pathname.includes('shipping')) || window.location.pathname.includes('Adyen-ShowConfirmation')) && paymentName.toLowerCase() === 'adyencomponent') {
			e.preventDefault();
			adyenSummary.init();
		} else {
			if (!isFormSubmitted) {
				var $form = $(this),
					buttonPlaceOrder = $form.find('.js-place_order-button');
				if (buttonPlaceOrder.length > 0) {
					buttonPlaceOrder.attr('disabled', 'disabled');
					isFormSubmitted = true;
					$form.submit();
				}
			}
		}
	});

    orderDuties.init();
    setTimeout(function(){// delay due to address block reload after page load
        btcRates.addCryptoPriceOnCheckout();
    }, 700);
};
