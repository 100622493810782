'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util'),
	$cache = {};


/**
 * @private
 * @function
 * @description Initializes cache for product set detail page
 */
function initializeCache() {
	$cache.addAllToCart = $('#add-all-to-cart'),
	$cache.productSetList = $('#product-set-list');
}


/**
 * @private
 * @function
 * @description Initialize DOM elements on product set detail page
 */
function initializeDom() {
	updateAddToCartButtons();
}


/**
 * @private
 * @function
 * @description Initialize event handlers on product set detail page
 */
function initializeEvents() {
	$(document).on('product.variation.changed', updateAddToCartButtons);
}


/**
 * @private
 * @function
 * @description Disable button "Add to cart" if product list has unavailable products
 */
function updateAddToCartButtons() {
	if ($cache.productSetList.find('.js-add-to-cart[disabled]').length > 0) {
		$cache.addAllToCart.attr('disabled', 'disabled');
	} else {
		$cache.addAllToCart.removeAttr('disabled');
	}
}


module.exports = {
	init: function (params) {
		initializeCache();
		if ($cache.productSetList.length > 0) {
			initializeDom();
			initializeEvents();
		}
	}
}
