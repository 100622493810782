'use strict';

var dialog = require('./dialog'),
	zoom = require('./zoom'),
	util = require('./util'),
	imagesLoaded = require('imagesloaded'),
	options = {
		imagesWrapper: '.js-360view',
		imagesContainer: '.js-360view-container',
		imagesList: '.js-360view-list'
	};


/**
* @private
* @function
* @description Initializes parameters for component
*/
function initializeParams(params) {
	options = $.extend(true, {}, options, params);
}


/**
* @private
* @function
* @description Initializes DOM elements for component
*/
function initProduct360View() {
	$(this).find(options.imagesWrapper).each(function() {
		var $that = $(this),
			$dialogHTML = $that.clone(),
			sourceUrlsArray = $that.find(options.imagesList).data('sourceUrls').split(','),
			originalUrlsArray = $that.find(options.imagesList).data('originalUrls').split(',');
		
		$dialogHTML.find('.js-360view-link').remove();
		$dialogHTML = $dialogHTML.add('<a class="ui-dialog-close_overlay js-dialog-close" href="#"></a>');
		
		initPlugin.call($that, sourceUrlsArray, util.isMobile());
		
		$that.on('click touchstart', '.js-360view-link', function(e) {
			e.preventDefault();
			dialog.open({
				html: $dialogHTML,
				options: {
					dialogClass: 'dialog-360view_zoom',
					open: function () {
						initPlugin.call($dialogHTML.filter('.js-360view'), originalUrlsArray, false);
					}
				}
			});
		});
		
		function initPlugin(urlsArray, allowVerticalScroll) {
			var $this = $(this),
				$container = $this.find(options.imagesContainer),
				$dragMessage = $container.find('.js-360view-message'),
				hideMessage = true;
			
			var product = $container.ThreeSixty({
				'imgArray': urlsArray,
				'totalFrames': urlsArray.length,
				'currentFrame': urlsArray.length,
				'framerate': urlsArray.length,
				'imgList': options.imagesList,
				'height': 'auto',
				'progress': '.js-360view-spinner',
				'responsive': true,
				'navigation': false,
				'onDragStart': function() {
					if (hideMessage) {
						$dragMessage.addClass('hidden');
						hideMessage = false;
					}
				},
				'onReady': function() {
					imagesLoaded($container).on('done', function () {
						product.gotoAndPlay(urlsArray.length);
					});
					$dragMessage.removeClass('hidden');
					
					var $zoomContainer = $this.find('.js-360view-zoom'),
						$zoomImages = $this.find(this.imgList).find('img');
					
					$zoomImages.on("click", function(e) {
						zoom.init(originalUrlsArray[$zoomImages.index(this)], $zoomContainer, e);
					});
					
					$this
						.on("touchstart.360view mousedown.360view", function(e) {
							e.stopPropagation();
						})
						.addClass('js-loaded');
					
					if (allowVerticalScroll == true) {
						var base = $container.data('ThreeSixty'),
							AppConfig = base.getConfig();
						
						$container
							.unbind('mousedown touchstart touchmove touchend mousemove click')
							.bind('mousedown touchstart touchmove touchend mousemove click', function (event) {
								
								//This part of code was copied from plugin ThreeSixty (threesixty.min.js)
								//event.preventDefault(); - this line was removed to have possibility scroll page by touch on image on mobile devices
								
								if ((event.type === 'mousedown' && event.which === 1) || event.type === 'touchstart') {
									AppConfig.pointerStartPosX = base.getPointerEvent(event).pageX;
									AppConfig.dragging = true;
									AppConfig.onDragStart(AppConfig.currentFrame);
								} else if (event.type === 'touchmove') {
									base.trackPointer(event);
								} else if (event.type === 'touchend') {
									AppConfig.dragging = false;
									AppConfig.onDragStop(AppConfig.endFrame);
								}
							});
					}
				}
			});
		}
	});
}


module.exports = {
	init : function (params) {
		initializeParams(params);
		initProduct360View.call(params.container || document);
	}
}
