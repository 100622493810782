'use strict';
var _ = require('lodash');
var $form, $continue, $requiredInputs, validator;

var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.not(':hidden:not(.js-validate-force), .js-validate-ignore').map(function () {
        return $(this).val();
    });
    return _(requiredValues).contains('');
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }
    if (!hasEmptyRequired()) {
        validator.form();
    }
};

var init = function (opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error('Missing form and continue action selectors.');
    }
    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    validator = $form.validate();
    $requiredInputs = $('.required', $form).find(':input');
    validateForm();

    $(document).ready(function () {
        $continue.removeAttr('disabled');
    });
};

exports.init = init;
exports.validateForm = validateForm;
