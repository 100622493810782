'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util'),
	dialog = require('./../../dialog'),
	validator = require('./../../../../../app_storefront_core/cartridge/js/validator');

var options = {
	pdpForm: '.js-pdp_form',
	pdpMainSelector: '#pdpMain'
};

var $cache = {};

function initializeCache() {
	$cache.pdpMain = $('#pdpMain');
}

function initializeEvents() {
	$cache.pdpMain.on('click', '.js-product-request-info', function (e) {
		e.preventDefault();

		var productID = $(this).data('product-id');

		dialog.open({
			url: getRequestProductInfoURL(Urls.requestProductInfoURL, productID),
			options: {
				dialogClass: 'dialog-request_product_info'
			}
		});
	});
}

function getRequestProductInfoURL(originalSourceUrl, productID) {
	var dialogUrl = dialogUrl = Urls.frameView;
	originalSourceUrl = util.appendParamToURL(originalSourceUrl, 'productID', productID);

	dialogUrl = util.ajaxUrl(dialogUrl);
	return util.appendParamsToUrl(dialogUrl, {sourceUrl: originalSourceUrl});
}

module.exports = {
	init: function() {
		initializeCache();
		initializeEvents();
	}
};
