'use strict';

let globalEvents = require('./events'),
    globalConfig = require('./config'),
    emarsys = require('./emarsys'),
    criteo = require('./criteo');


// Most of the global GTM vars are pushed before GTM snippet init in GtmAnalyticsMain.isml
function pushGlobalDataLayerVars() {
  // Push default global vars -
  // add to already existing globals (pushed from GtmAnalyticsMain.isml),
  // detect it by presence of page_language
  if (dataLayer.length) {
    let globalsIndex = dataLayer.findIndex(obj => obj.page_language);
    if (globalsIndex !== -1){
        Object.assign(dataLayer[globalsIndex], globalConfig.pageVariables.global);
    } else {
      pushDataLayerVar(globalConfig.pageVariables.global);
    }

    // Push page-specific global vars
    globalsIndex = dataLayer.findIndex(obj => obj.page_language);
    for (let i = 0; dataLayer.length > i; i++) {
      for (let i in globalConfig.pageVariables) {
        if (window.pageContext.ns === i) {
          Object.assign(dataLayer[globalsIndex], globalConfig.pageVariables[i]);
        }
      }
    }
  }

}

function pushDataLayerVar(item) {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push(item);
}

function pushEvents() {
  for (let i in globalConfig.events) {
    if (i === window.pageContext.ns) {
      for (let j in globalConfig.events[i]) {
        let event = globalConfig.events[i][j];
        globalEvents[event]();
      }
    } else if (i === "all_pages") {
      for (let j in globalConfig.events["all_pages"]) {
        let event = globalConfig.events["all_pages"][j];
        globalEvents[event]();
      }
    }
  }
}

module.exports = {
  init: function() {
    if (SitePreferences.ADD_GTM_EMARSYS_VARS) {
      emarsys.pushDataLayerVars();
    }
    if (SitePreferences.ADD_GTM_CRITEO_VARS) {
      criteo.pushDataLayerVars();
    }

    // TODO: check how this works (emarsys?)
    if (SitePreferences.IS_ABANDONED_CART_ENABLED) {
      pushDataLayerVar({
        "abandonedCartID": SitePreferences.ABANDONED_CART_ID
      });
    }

    pushGlobalDataLayerVars();
    pushEvents();
  }
};
