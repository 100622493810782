'use strict';

/**
 * Handles phone inputs with built-in country code dropdowns
 */

var VISIBLE_INPUT_NAME_SUFFIX = '_dummy',
  COUNTRY_LIST_HEIGHT = 300; // in pixels

var $cache = {},
  selectors = {
    inputWrapper: '.intl-tel-input',
    visibleInput: '.js-phone-input-visible',
    hiddenInput: '.js-phone-input-hidden',
    initializedInput: '.js-phone-input-initiated',
    countriesWrapper: '.country-list',
    dialogWrapper: '.ui-dialog',
    registerForm: '.f-create_account',
    selectCountry: '.input-select.country'
  },
  classes = {
    hiddenInput: 'js-phone-input-hidden',
    initializedInput: 'js-phone-input-initiated',
    forceValidation: 'js-validate-force',
    disableValidation: 'js-validate-ignore',
    forceCountriesOpenUp: 'dropup',
    forceCountriesOpenDown: 'dropdown'
  };

/**
 * Module initialization function
 */
function init() {
  if (isPluginLoaded()) {
    onPluginLoaded();
  } else {
    loadPlugin(onPluginLoaded);
  }
}

/**
 * Determines whether plugin script is already loaded
 *
 * @returns {Boolean}
 */
function isPluginLoaded() {
  return typeof window.intlTelInput !== 'undefined';
}

/**
 * Loads plugin script
 *
 * @param {Function} callback Callback to run on script load
 */
function loadPlugin(callback) {
  $.ajax({
    dataType: 'script',
    url: Urls.phoneCountrySelectScript
  }).done(function () {
    // lazy-loading the utils script on our own because plugin internally uses
    // Promise to do it
    loadPluginUtils(callback);
  });
}

/**
 * Loads plugin utils script
 *
 * @param {Function} callback Callback to run on script load
 */
function loadPluginUtils(callback) {
  $.ajax({
    dataType: 'script',
    url: Urls.phoneCountrySelectScriptUtils
  }).done(callback);
}

/**
 * Callback to run on plugin script load
 */
function onPluginLoaded() {
  initInputs();
  initFormEvents();
}

/**
 * Initializes behavior of phone inputs
 */
function initInputs() {
  initVisibleInputs();
  initHiddenInputs();
}

/**
 * Initializes behavior of visible inputs
 */
function initVisibleInputs() {
  var $inputs = getVisibleInputsToInit();

  bindPlugin($inputs);
  initVisibleInputMaxLength($inputs);
  initVisibleInputEvents($inputs);
  initVisibleInputValidation($inputs);

  $inputs.addClass(classes.initializedInput);
}

/**
 * Returns a jQuery collection of visible inputs to init
 *
 * @returns {jQuery}
 */
function getVisibleInputsToInit() {
  // not cached because inputs can dynamically appear on the page
  // plugin has issues with styling invisible inputs
  return $(selectors.visibleInput).not(':hidden').not(selectors.initializedInput);
}

/**
 * Returns a jQuery collection of inputs wrapper
 *
 * @returns {jQuery}
 */
function getInputWrapper() {
  return $(selectors.inputWrapper);
}

/**
 * Binds country selector plugin to visible inputs
 *
 * @param {jQuery} $inputs Visible inputs
 */
function bindPlugin($inputs) {
  $inputs.each(function () {
    var $input = $(this),
    inputName = $input.attr('name'),
    inputPluginConfig = getPluginConfig($input);

    // renaming visible input so that it's value is not processed on submit
    $input.attr('name', inputName + VISIBLE_INPUT_NAME_SUFFIX);

    window.intlTelInput($input.get(0), inputPluginConfig);
  });
}

/**
 * Stores maximum allowed value length for visible inputs
 *
 * @param {jQuery} $inputs Visible inputs
 */
function initVisibleInputMaxLength($inputs) {
  $inputs.each(function () {
    var $input = $(this),
    maxLength = $input.attr('maxlength') || null;

    if (maxLength) {
    $input.data('maxlength', maxLength);

    updateVisibleInputMaxLength($input);
    }
  });
}

/**
 * Returns country selector plugin config for a visible input
 *
 * @param {jQuery} $input Visible input
 * @returns {Object}
 */
function getPluginConfig($input) {
  var initialCountry = getInitialCountry($input);

  return {
    autoHideDialCode: true,
    separateDialCode: true,
    formatOnDisplay: false,
    nationalMode: false,
    hiddenInput: $input.attr('name'),
    preferredCountries: getPreferredCountries(initialCountry),
    initialCountry: initialCountry,
    autoPlaceholder: 'aggressive',
    utilsScript: Urls.phoneCountrySelectScriptUtils
  };
}

/**
 * Returns initial country for a country selector
 *
 * @param {jQuery} $input Visible input
 * @returns {String}
 */
function getInitialCountry($input) {
  var countryCode = $input.data('initial-country') || '';

  if (countryCode.length) {
    return countryCode;
  }

  return window.pageContext.countryCode || '';
}

/**
 * Returns preferred countries for a country selector
 *
 * @param {String} initialCountry Initial country
 * @returns {Array}
 */
function getPreferredCountries(initialCountry) {
  return initialCountry.length ? [initialCountry] : [];
}

/**
 * Initializes events of visible inputs
 *
 * @param {jQuery} $inputs Visible inputs
 */
function initVisibleInputEvents($inputs) {
  $inputs.on('focusout keyup', onVisibleInputFocusoutKeyup);
  $inputs.on('countrychange', onVisibleInputCountryChange);
  $inputs.on('open:countrydropdown', onVisibleInputCountriesOpen);
}

/**
 * Visible input focusout and keyup event handler
 *
 * @param {Event} e Event
 */
function onVisibleInputFocusoutKeyup(e) {
  var $input = $(e.target);

  setValueByVisibleInput($input);
}

/**
 * Visible input country change event handler
 *
 * @param {Event} e Event
 */
function onVisibleInputCountryChange(e) {
  var $input = $(e.target),
    inputVal = $input.val() || '';

  updateVisibleInputMaxLength($input);

  if (inputVal.length) {
    setValueByVisibleInput($input);
  }
}

/**
 * Visible input country list open event handler
 *
 * @param {Event} e Event
 */
function onVisibleInputCountriesOpen(e) {
  var $input = $(e.target),
    $dialog,
    $countries,
    openCountriesUp,
    openCountriesDown,
    $overflowWrapper = isInputInsideOverflow($input);

  if (isInputInsideDialog($input)) {
    $dialog = $input.closest(selectors.dialogWrapper);
    $countries = $input.closest(selectors.inputWrapper).find(selectors.countriesWrapper);
    openCountriesUp = $input.position().top < $dialog.outerHeight() - COUNTRY_LIST_HEIGHT;

    $countries.toggleClass(classes.forceCountriesOpenUp, openCountriesUp);
  } else if ($overflowWrapper) {
    $countries = $input.closest(selectors.inputWrapper).find(selectors.countriesWrapper);
    openCountriesDown = $input.position().bottom < $overflowWrapper.outerHeight() - COUNTRY_LIST_HEIGHT;

    $countries.toggleClass(classes.forceCountriesOpenDown, openCountriesDown);
  }
}

/**
 * Determines whether input is being rendered inside a dialog
 *
 * @param {jQuery} $input Input to check
 * @returns {Boolean}
 */
function isInputInsideDialog($input) {
  return $input.closest(selectors.dialogWrapper).length !== 0;
}

/**
 * Determines whether input is being rendered inside a overflow hidden or scroll
 *
 * @param {jQuery} $input Input to check
 * @returns {jQuery} or null;
 */
function isInputInsideOverflow($input) {
  var $wrapper = null;
  $input.parents().each(function (i, el) {
    if ($wrapper) {
    return false;
    }
    if ($(el).css('overflow') !== 'visible') {
    $wrapper = $(el);
    }
  });
  return $wrapper;
}

/**
 * Stores value of a visible input into a hidden input
 *
 * @param {jQuery} $visibleInput Visible input
 * @param {Boolean} revalidate Determines whether input value needs to be validated
 */
function setValueByVisibleInput($visibleInput, revalidate) {
  var $hiddenInput = getHiddenInput($visibleInput),
    plugin = getPluginInstance($visibleInput),
    country = plugin.getSelectedCountryData(),
    fullNumberMaxLength = $hiddenInput.attr('maxlength'),
    fullNumber = normalizePhoneNumber(plugin.getNumber(), country.dialCode, fullNumberMaxLength),
    hiddenInputVal = fullNumber === '+' + country.dialCode ? '' : fullNumber;

  if (typeof revalidate === 'undefined') {
    revalidate = true;
  }

  plugin.setNumber(fullNumber);

  // plugin syncs input values on submit only
  $hiddenInput.val(hiddenInputVal);

  if (revalidate) {
    // trigger validation
    $hiddenInput.valid();
  }
}

/**
 * Changes maximum allowed length based on selected country code
 *
 * @param {jQuery} $input Visible input
 */
function updateVisibleInputMaxLength($input) {
  var originalLimit = $input.data('maxlength'),
    newLimit,
    plugin,
    country,
    dialCode;

  if (originalLimit) {
    plugin = getPluginInstance($input);
    country = plugin.getSelectedCountryData();
    dialCode = country.dialCode ? '+' + country.dialCode : '';
    newLimit = originalLimit - dialCode.length;

    if (newLimit < 0) {
    newLimit = 0;
    }

    if (newLimit > 16 && country.iso2 === 'ru') {
    newLimit = 16;
    }

    $input.attr('maxlength', newLimit);
  }
}

/**
 * Returns a hidden input corresponding to a given visible input
 *
 * @param {jQuery} $visibleInput Visible input
 * @returns {jQuery}
 */
function getHiddenInput($visibleInput) {
  return $visibleInput.closest(selectors.inputWrapper).find(selectors.hiddenInput);
}

/**
 * Normalizes a phone number
 *
 * @param {String} number Phone number
 * @param {String} countryCode Country dial code
 * @param {Number|undefined} maxLength Maximum allowed length
 * @returns {String}
 */
function normalizePhoneNumber(number, countryCode, maxLength) {
   var duplicateCodeRgx = new RegExp('\\+' + countryCode + '(?=\\+' + countryCode + ')', 'g'),
    res = number.replace(duplicateCodeRgx, '');

  return maxLength ? res.substring(0, maxLength) : res;
}

/**
 * Disables client-side validation for visible inputs
 *
 * @param {jQuery} $visibleInputs Visible inputs
 */
function initVisibleInputValidation($visibleInputs) {
  $visibleInputs.addClass(classes.disableValidation);
}

/**
 * Initializes behavior of hidden inputs
 */
function initHiddenInputs() {
  var $inputs = getHiddenInputsToInit();

  $inputs.addClass(classes.hiddenInput);

  initHiddenInputValues($inputs);
  initHiddenInputEvents($inputs);
  initHiddenInputMaxLength($inputs);
  initHiddenInputValidation($inputs);

  $inputs.addClass(classes.initializedInput);
}

/**
 * Returns a jQuery collection of hidden inputs to init
 *
 * @returns {jQuery}
 */
function getHiddenInputsToInit() {
  // not cached because inputs can dynamically appear on the page
  return $(selectors.inputWrapper).find('[type="hidden"]').not(selectors.initializedInput);
}

/**
 * Initializes values of hidden inputs
 *
 * @param {jQuery} $hiddenInputs Hidden inputs
 */
function initHiddenInputValues($hiddenInputs) {
  $hiddenInputs.each(function () {
    var $hiddenInput = $(this),
    $visibleInput = getVisibleInput($hiddenInput);

    setValueByVisibleInput($visibleInput, false);
  });
}

/**
 * Initializes events of hidden inputs
 *
 * @param {jQuery} $inputs Hidden inputs
 */
function initHiddenInputEvents($inputs) {
  $inputs.on('change', function () {
    setValueByHiddenInput($(this));
  });
}

/**
 * Stores value of a hidden input into a visible input
 *
 * @param {jQuery} $hiddenInput Hidden input
 */
function setValueByHiddenInput($hiddenInput) {
  var $visibleInput = getVisibleInput($hiddenInput),
    hiddenInputVal = $hiddenInput.val() || '',
    visibleInputVal = $visibleInput.val() || '',
    plugin;

  if (hiddenInputVal !== visibleInputVal) {
    plugin = getPluginInstance($visibleInput);

    plugin.setNumber(hiddenInputVal);

    if (hiddenInputVal.charAt(0) !== '+') {
      selectInitialCountry($visibleInput);
    }
  }
}

/**
 * Selects initial country for a given visible input
 *
 * @param {jQuery} $visibleInput Visible input
 */
function selectInitialCountry($visibleInput) {
  var countryCode = $visibleInput.data('initial-country') || '',
    plugin;

  if (countryCode) {
    plugin = getPluginInstance($visibleInput);
    plugin.setCountry(countryCode.toLowerCase());
  }
}

/**
 * Returns a visible input corresponding to a given hidden input
 *
 * @param {jQuery} $hiddenInput Hidden input
 * @returns {jQuery}
 */
function getVisibleInput($hiddenInput) {
  return $hiddenInput.closest(selectors.inputWrapper).find(selectors.visibleInput);
}

/**
 * Stores maximum allowed value length for hidden inputs
 *
 * @param {jQuery} $hiddenInputs Hidden inputs
 */
function initHiddenInputMaxLength($hiddenInputs) {
  $hiddenInputs.each(function () {
    var $hiddenInput = $(this),
    $visibleInput = getVisibleInput($hiddenInput),
    maxLength = $visibleInput.data('maxlength') || null;

    if (maxLength) {
      $hiddenInput.attr('maxlength', maxLength);
    }
  });
}

/**
 * Copies client-side validation rules from visible inputs to hidden inputs
 *
 * @param {jQuery} $hiddenInputs Hidden inputs
 */
function initHiddenInputValidation($hiddenInputs) {
  var validationClasses = [
    'required',
    'phone',
    'numericrangelength'
  ];

  $hiddenInputs.each(function () {
    var $hiddenInput = $(this),
    $visibleInput = getVisibleInput($hiddenInput),
    dataAttributes = $visibleInput.data();

    validationClasses.forEach(function (className) {
    if ($visibleInput.hasClass(className)) {
      $hiddenInput.addClass(className);
    }
    });

    for (var propName in dataAttributes) {
      $hiddenInput.data(propName, dataAttributes[propName]);
    }
  });

  $hiddenInputs.addClass(classes.forceValidation);
}

/**
 * Initializes global form events
 */
function initFormEvents() {
  getDocument().on('form.show', function () {
    initInputs();
  });

  getDocument().on('form.address.country.change', 'form', onAddressCountryChange);

  initPhonePrefixFormCountrySelect();
}

/**
 * Returns current document
 *
 * @returns {jQuery}
 */
function getDocument() {
  if (!$cache.document) {
    $cache.document = $(document);
  }

  return $cache.document;
}

/**
 * Initializes phone prefix form country select
 *
 */
function initPhonePrefixFormCountrySelect() {
  var $form = getDocument().find(selectors.registerForm);

  if (!$form || !$form.length) {
    return false;
  }

  var $countrySelect = $form.find(selectors.selectCountry);

  if (!$countrySelect || !$countrySelect.length) {
    return false;
  }

  $countrySelect.on('change', function () {
    var countryCode = $(this).val() || '',
    $visibleInputs,
    $plugin;

    $visibleInputs = $form.find(selectors.visibleInput);

    if ($visibleInputs && $visibleInputs.length) {
      $plugin = getPluginInstance($visibleInputs);

      if (countryCode && $plugin) {
        $plugin.setCountry(countryCode.toLowerCase());
        $visibleInputs.data('initial-country', countryCode);
      }
    }
  });
}

/**
 * Address form country change event handler
 *
 * @param {Event} e Event
 * @param {Object} data Event data
 */
function onAddressCountryChange(e, data) {
  var $form = $(e.target),
    countryCode = data.countryCode || window.pageContext.countryCode || '',
    $visibleInputs;

  if (countryCode) {
    $visibleInputs = $form.find(selectors.visibleInput);

    $visibleInputs.data('initial-country', countryCode);
  }
}

/**
 * Returns plugin instance for a given input
 *
 * @param {jQuery} $visibleInput Visible input
 * @returns {Object}
 */
function getPluginInstance($visibleInput) {
  return window.intlTelInputGlobals.getInstance($visibleInput.get(0));
}

/**
 * Determines whether a given element is a hidden input
 *
 * @param {jQuery} $element Element to check
 * @returns {Boolean}
 */
function isHiddenInput($element) {
  return $element.is(selectors.hiddenInput);
}

/**
 * Returns a container element for validation errors
 *
 * @param {jQuery} $input Element being validated
 * @returns {jQuery}
 */
function getErrorContainer($input) {
  return $input.closest(selectors.inputWrapper);
}

module.exports = {
  init: init,
  isHiddenInput: isHiddenInput,
  getErrorContainer: getErrorContainer,
  initHiddenInputValues: initHiddenInputValues,
  getInputWrapper: getInputWrapper
};
