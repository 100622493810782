'use strict';

function initProductSnippet() {
	var script = document.createElement('script');
	script.type = 'application/ld+json';
	script.text = JSON.stringify(window.pageContext.productSnippet);
	
	$("body").append(script);
}

module.exports = {
	init : function () {
		initProductSnippet();
	}
}