'use strict';

var selectors = {
  'widget': '.js-recaptcha',
  'response': '.js-recaptcha-response input, .g-recaptcha-response',
  'customError': '#recaptcha-error'
};

function loadScripts() {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=explicit&onload=onReCaptchaScriptLoad');
    s.setAttribute('async', '');
    s.setAttribute('defer', '');
    document.getElementsByTagName('head')[0].appendChild(s);
}

function createOnScriptLoadHandler() {
    window.onReCaptchaScriptLoad = function () {
        renderWidgets();
    };
}

function renderWidgets() {
    var $widgets = $(selectors.widget);

    $widgets.each(function (index, widget) {
        var $widget = $(widget);

        if (!isWidgetRendered($widget)) {
            renderWidget($widget);
        }
    });
}

function isWidgetRendered($widget) {
    var widgetID = $widget.data('widget-id');

    return typeof widgetID !== 'undefined';
}

function renderWidget($widget) {
    var widgetID = grecaptcha.render($widget.get(0), {
        'sitekey': SitePreferences.RECAPTCHA_SITE_KEY,
        'theme': SitePreferences.RECAPTCHA_THEME,
        'size': 'normal',
        'callback': function () {
            saveResponse($widget);
        },
        'data-expired-callback': function () {
            resetWidget($widget);
        },
        'data-error-callback': function () {
            resetWidget($widget);
        }
    }, true);

    $widget.data('widget-id', widgetID);
}

function resetWidget($widget) {
    var widgetID = $widget.data('widget-id');

    grecaptcha.reset(widgetID);

    saveResponse($widget);
}

function saveResponse($widget) {
    var response = getResponse($widget),
        $responseInput = getResponseInput($widget);
    
    $responseInput.val(response).valid();
    hideErrorCustom(response);
}

function getResponse($widget) {
    if (!window.grecaptcha){return false};
    
    var widgetID = $widget.data('widget-id');

    return grecaptcha.getResponse(widgetID) || '';
}

function getResponseInput($widget) {
    return $widget.closest('form').find(selectors.response);
}

function isResponseInput($input) {
    return $input.is(getResponseInputSelector());
}

function getResponseInputSelector() {
    return selectors.response;
}

function focusResponseInput($input) {
    $('html, body').animate({
        scrollTop: $input.offset().top - $(window).height()
    }, 500);
}

function showError($errorLabel, $input) {
    var $widget = $input.closest('form').find(selectors.widget);

    $errorLabel.insertAfter($widget);
}

// Error handling for custom html forms
function showErrorCustom($errPlaceholder, $errTxt) {
    if($errPlaceholder && $errTxt){
      $errPlaceholder.text($errTxt).show();
    }
}
function hideErrorCustom($response) {
    if($response !== '' && $(selectors.customError)) {
      $(selectors.customError).hide();
    }
}

module.exports = {
    init: function () {
        if (SitePreferences.IS_RECAPTCHA_ENABLED) {
            createOnScriptLoadHandler();
            loadScripts();
        }
    },
    isResponseInput: isResponseInput,
    focusResponseInput: focusResponseInput,
    getResponseInputSelector: getResponseInputSelector,
    showError: showError,
    showErrorCustom: showErrorCustom,
    getResponse: getResponse
};
