function initializeEvents() {

  $(document).on('dialogopen', function(event, ui) {
    detectTableOverflow();
  });

}

// Detect sizes table overflow if it's too long horizontally & add shadow class
function detectTableOverflow() {
  let container = document.querySelector(".size-guide_table-container");
  if (container) {
    let contWidth = container.offsetWidth,
      tableWidth = document.querySelector(".size-guide_row") ? document.querySelector(".size-guide_row").offsetWidth : 0;
    if (tableWidth > contWidth) {
      container.classList.add("size-guide_overflow-shadow");
    }
  }
}


module.exports = {
  init: function() {
    initializeEvents();
  }
};
