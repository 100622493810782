'use strict';

var dialog = require('./../../../../app_storefront_core/cartridge/js/dialog');

/**
 * @function
 * @description Initialize postMessage event listener
 */
function initListenMessages(){
	if (window.addEventListener) {
		window.addEventListener("message", listenMessage, false);
	} else {
		window.attachEvent("onmessage", listenMessage);
	}

	// Say to iframes that the message listener was initialized
	$('.js-iframe').each(function() {
		this.contentWindow.postMessage({eventType:'postmessages.initialized'}, 'https://' + window.location.hostname);
	});
}

/**
 * @function
 * @description Initialize logic for processing received postMessage
 */
function listenMessage(event){
	var hostName = ('https://' + window.location.hostname),
		eventType,
		receivedMessageData;
	var origin = event.origin || event.originalEvent.origin;
	if(origin == hostName){
		receivedMessageData = event.data,
		eventType = receivedMessageData.eventType;

		if(eventType){
			switch(eventType){
				case 'refresh':
					window.location.reload();
				break;
				case 'goto':
					window.location.href = receivedMessageData.url;
				break;
				case 'resizedialog':
					resizeDialogWithParams(receivedMessageData.dialogParams);
				break;
				case 'resizeiframe':
					resizeIframeWithParams(receivedMessageData.selector, receivedMessageData.height);
				break;
			}
		}
	}
}

/**
 * @function
 * @description Initialize dialog resize based on received postMessage data
 */
function resizeDialogWithParams(params){
	if(params && dialog.exists()){

		$(dialog.$container).width(params.width + 'px');
		$(dialog.$container).height(params.height + 'px');
		var windowHeight = window.screen.height;
		var pos = $(dialog.$container).parent().offset();
		var dialogHalfHeight =  params.height/2;
		if(dialogHalfHeight < 100){
			dialogHalfHeight = 100;
		}
		pos.top = Math.round(windowHeight/2 - dialogHalfHeight);
		if(pos.top <= 0){
			pos.top = 100;
		}
		$(dialog.$container).parent().offset(pos);
	}
}

/**
 * @function
 * @description Initialize dialog resize based on received postMessage data
 */
function resizeIframeWithParams(iframeSelector, iframeHeight){
	$(iframeSelector).height(iframeHeight);
}

module.exports = {
    init : function(){
        initListenMessages();
    }
}
