'use strict';

var options = {
        allowedActions: {
            'addtocart': true
        },
        pdpMain: '#pdpMain'
    },

    $cache = {},

    layout = require('./../../layout'),
    dialog = require('./../../dialog');

var actions = {
    'notifyme': require('./notifyme'),
    'addtocart': require('./addtocart'),
    'backinstock': require('./backinstock'),
    'requestinfo': require('./requestinfo'),
    'storeavailability': require('./storeavailability')
};

var productactions = {
    init: function(params) {
        initializeConfig(params);
        initializeCache();
        initializeMobile();
		initializeEvents();

        initializeActions(params);
    }
}

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.pdpMain = $(options.pdpMain);
}

function initializeMobile() {
    if (layout.isMobile()) {
        initializeMobileEvents();
    }
}

function initializeMobileEvents() {
    $cache.document.on('product.options.error', scrollToInvalidOptions);
}

function initializeActions(params) {
    for (var action in options.allowedActions) {
        if (options.allowedActions[action]) {
            actions[action].init(params);
        }
    }
}

function scrollToInvalidOptions(e, params) {
    var $firstInvalid = params.$invalidOptions[0];
    var scrollTop = $cache.window.scrollTop();
    var windowHeight = $cache.window.height();
    var middleOfWindow = windowHeight / 2;
    var optionOffset = $firstInvalid.offset().top;
    if (optionOffset < scrollTop || optionOffset > scrollTop + windowHeight) {
        $cache.window.scrollTop(optionOffset - middleOfWindow);
    }
}

function initializeEvents() {
	$cache.document.on('click', '.js-remove_from_wishlist', function(e) {
		var $this = $(this);
		e.preventDefault();
		$.get($this.attr('href'), function(data) {
			$this.replaceWith(data);
		});
	})
    // gialog with qr code for wechat
	.on('click', '.js-dialog-qr-code', function (e) {
		e.preventDefault();
		var qrContainer = document.createElement('div'),
		qrcode = null;
		if ('QRCode' in window) {
			qrcode = new QRCode(qrContainer, {
				text: document.URL,
				width: 300,
				height: 300,
				colorDark : "#000000",
				colorLight : "#ffffff",
				correctLevel : QRCode.CorrectLevel.H
			});
			dialog.open({
				html: qrContainer,
				options: {
					'dialogClass': 'dialog-wechat_share',
					'title': $(this).attr('title')
				}
			});
		}
	});
}

module.exports = productactions;