'use strict';

var googleTagManager = require('./gtm');

module.exports = {
    init: function () {
        if (SitePreferences.IS_GTM_ENABLED) {
            googleTagManager.init();
        }
    }
}
