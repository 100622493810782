'use strict';

var util = require('./../../util'),
    dialog = require('./../../dialog'),
    contactUsForm = require('./../contactusform');

/**
 * @function
 * @description Initialize customer service form events
 */
function initEvents() {
	initDialogOpenerEvents();
}

/**
 * @function
 * @description Initialize customer service dialog logic
 */
function initDialogOpenerEvents(){
	var dialogTriggerElement = $('.js-customer-service-dialog'),
		dialogUrl,
		formEl,
		originalSourceUrl;

	if(dialogTriggerElement.length){
		dialogTriggerElement.on('click', function(e){

			e.preventDefault();
			originalSourceUrl = $(this).attr('href');
			dialogUrl = Urls.frameView;
			var productIdElement = $("*[itemprop='productID']");
			if (productIdElement.length) {
				originalSourceUrl = util.appendParamToURL(originalSourceUrl, 'productID', productIdElement.data().masterid);
			}
			dialogUrl = util.ajaxUrl(dialogUrl);
			dialogUrl = util.appendParamsToUrl(dialogUrl, {sourceUrl: originalSourceUrl});

			dialog.open({
				url: dialogUrl,
				target: 'service-dialog',
				options: {
					dialogClass: 'dialog-customer_services',
					closeOnEscape: true
				}
			});
		});
	}
}

module.exports = {
	init : function(){
        initEvents();

        contactUsForm.init();
	}
}
