'use strict';

/**
 * Returns cookie value by name
 *
 * @param {String} key Cookie name
 * @returns {String} Cookie value if found, null otherwise
 */
function get(key) {
    var cookies = document.cookie.split(';'),
        tokens, cookieKey;

    for (var i = 0; i < cookies.length; i++) {
        tokens = cookies[i].split('=');
        cookieKey = tokens.shift().trim();

        if (cookieKey === key) {
            return tokens.join('=');
        }
    }

    return null;
}

/**
 * Sets a cookie
 *
 * @param {String} key Cookie name
 * @param {String} value Cookie value
 * @param {Object} opts Cookie options
 */
function set(key, value, opts) {
    var cookieParts = [
        key + '=' + value
    ],
        date;

    if (opts) {
        if ('expires' in opts) {
            date = new Date();
            date.setTime(date.getTime() + (opts.expires * 60 * 1000));

            cookieParts.push('expires=' + date.toGMTString());
        }

        if ('path' in opts) {
            cookieParts.push('path=' + opts.path);
        }
    }

    document.cookie = cookieParts.join('; ');
}

module.exports = {
    get: get,
    set: set
};