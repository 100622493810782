"use strict";

function pushDataLayerVars() {
    var userEmail = getUserEmail();

    if (userEmail.length) {
        pushDataLayerVar({ UserEmail: userEmail });
    }

    pushDataLayerVar({ pageCategory: getCategoryPath() });
    pushDataLayerVar({ CartContent: getCartContent() });
    pushDataLayerVar({ WishlistContent: getWishlistContent() });
}

function pushDataLayerVar(item) {
    window.dataLayer = window.dataLayer || [];

    dataLayer.push(item);
}

function getUserEmail() {
    if (!("gtm_vars" in window)) {
        return "";
    }

    return window.gtm_vars.user_email || "";
}

function getCategoryPath() {
    var categories = [],
        categoryVarName = "page_cat1",
        categoryLevel = 1;

    if (!("gtm_vars" in window)) {
        return "";
    }

    while (
        categoryVarName in window.gtm_vars &&
        !!window.gtm_vars[categoryVarName]
    ) {
        categories.push(window.gtm_vars[categoryVarName]);

        categoryLevel++;
        categoryVarName = "page_cat" + categoryLevel;
    }

    return categories.join(">");
}

function getCartContent() {
    var cartItems;

    if (!("gtm_vars" in window)) {
        return [];
    }

    cartItems = window.gtm_vars.order_products || [];

    return cartItems.map(function (item) {
        return {
            item: item.order_product_id || "",
            price: +item.order_product_currentprice_ati || "",
            quantity: +item.order_product_quantity || "",
        };
    });
}

function getWishlistContent() {
    var wishlistItems;
    if (!("pageContext" in window)) {
        return [];
    }
    wishlistItems = window.pageContext.wishlistContent || [];
    return wishlistItems;
}

module.exports = {
    pushDataLayerVars: pushDataLayerVars,
};
