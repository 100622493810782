'use strict';

var options = {
        minicart: '#mini-cart',
        minicartHideTimeout: 5000,
        minicartLeaveTimeout: 500
    },
    $cache = {},
    
    util = require('./util'),
    
    autoHide = false,
    timer = util.getTimer();

function initializeConfig(params) {
    options = $.extend({}, options, params);
}

function initializeCache() {
    $cache.minicart = $(options.minicart);
    $cache.minicartLink = $('.js-minicart-link');
}

function initializeEvents() {
    $(document)
    .on('mouseenter', options.minicart, function (event, data) {
        timer.clear();
    })
    .on('mouseleave', options.minicart, function (event, data) {
        autoHide && timer.start(options.minicartLeaveTimeout, minicartHide);
    })
    .on('product.addedToCart', function(event, response) {
        minicartShow(response);
        if (!util.isMobile()) {
            timer.clear();
            timer.start(options.minicartHideTimeout, minicartHide);
        }
    });
}

function minicartShow(html) {
    $cache.minicart.html(html);
    $cache.minicart.addClass('js-show');
    $(document).trigger('minicart.shown', { container: $cache.minicart });
    autoHide = true;
}

function minicartHide(delay) {
    timer.clear();
    $cache.minicart.removeClass('js-show');
    $(document).trigger('minicart.hidden', { container: $cache.minicart });
    autoHide = false;
}

module.exports = {
    init: function(params) {
        initializeConfig(params);
        initializeCache();
        initializeEvents();
    },
    update: function (showOnLoad) {
        var $request = $.ajax({
            'url' : Urls.minicart
        }).done(function (response) {
            $cache.minicart.html(response);
        });

        !!showOnLoad && $request.done(show);
    }
};