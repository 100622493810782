'use strict';

let options = {
    productListItem: '.js-product_tile',
    productListTrackLimit: 3
};

function pushDataLayerVars() {
    switch (window.pageContext.ns) {
        case 'storefront':
            setHomepageDataLayer();
            break;
        case 'search':
            setListingDataLayer();
            break;
        case 'product':
            setProductDataLayer();
            break;
        case 'cart':
            setCartDataLayer();
            break;
        case 'orderconfirmation':
            setSalesDataLayer();
            break;
    }
}

function setHomepageDataLayer() {
    pushDataLayerVar({
        event: 'crto_homepage',
        crto: {
            email: getEmail()
        }
    });
}

function setListingDataLayer() {
    pushDataLayerVar({
        event: 'crto_listingpage',
        crto: {
            email: getEmail(),
            products: getTopListedProductsIDs()
        }
    });
}

function setProductDataLayer() {
    pushDataLayerVar({
        event: 'crto_productpage',
        crto: {
            email: getEmail(),
            products: [window.pageContext.productID || '']
        }
    });
}

function setCartDataLayer() {
    pushDataLayerVar({
        event: 'crto_basketpage',
        crto: {
            email: getEmail(),
            products: getCartProducts()
        }
    });
}

function setSalesDataLayer() {
    pushDataLayerVar({
        event: 'crto_transactionpage',
        crto: {
            email: getEmail(),
            transactionid: window.pageContext.transactionID || 0,
            products: getCartProducts()
        }
    });
}

function pushDataLayerVar(item) {
    window.dataLayer = window.dataLayer || [];
    dataLayer.push(item);
}

function getEmail() {
    let email = '',
      dataLayer = window.dataLayer;

    if (dataLayer) {
      for (let i = 0; dataLayer.length > i; i++) {
        if(dataLayer[i].hasOwnProperty("UserEmail")){
          email = dataLayer[i].UserEmail;
        }
      }
    }
    
    return email.toLowerCase();
}

function getCartProducts() {
    let products;
    if (!('gtm_vars' in window)) {
        return [];
    }
    products = window.gtm_vars.order_products || [];
    return products.map(function (item) {
        return {
            'id': item.order_product_id || '',
            'price': item.order_product_unitprice_ati || '0',
            'quantity': +item.order_product_quantity || 0
        };
    });
}

function getTopListedProductsIDs() {
    let topTiles = $(options.productListItem + ':lt(' + options.productListTrackLimit + ')'),
        productIDs = [],
        productID;

    topTiles.each(function (i, tile) {
        productID = $(tile).data('itemid') || '';

        if (productID && productIDs.indexOf(productID) === -1) {
            productIDs.push(productID);
        }
    });

    return productIDs;
}

module.exports = {
    pushDataLayerVars: pushDataLayerVars
};
