'use strict';

var util = require('./util');

module.exports = {
	init: function(zoomUrl, $zoomContainer, customEvent, callback) {
		$zoomContainer.zoom({
			'url': zoomUrl,
			'on': 'click',
			'touch': false,
			'callback': function() {
				$zoomContainer.trigger('click');
				(typeof callback === 'function') && callback();
			},
			'onZoomIn': function() {
				// Calculate position of image in zoom container, make it simply scrollable on devices
				if (util.isMobile() || $(document).width() < 1024) {
					$(this).css({'position': 'relative'});
					$(this).parent()
						.css({'overflow': 'scroll'})
						.scrollTop($(this).height() / 2)
						.scrollLeft($(this).width() / 2);
				} else {
					customEvent && refreshZoomImg.call(this, customEvent);
				}

				$zoomContainer
					.on("touchstart.zoom", function(e) {
						e.stopPropagation();
					})
					.one("click", '.js-zoom-close', function(e) {
						disableZoom();
					})
					.addClass('js-zoom-enabled');
			},
			'onZoomOut': function() {
				disableZoom();
			}
		});

		function disableZoom() {
			$zoomContainer
				.removeClass('js-zoom-enabled')
				.off("touchstart.zoom")
				.trigger('zoom.destroy');
		}

		function refreshZoomImg(e) {
			var img = this,
				offset = $zoomContainer.offset(),
				targetWidth = $zoomContainer.outerWidth(),
				targetHeight = $zoomContainer.outerHeight(),
				xRatio = (img.width - targetWidth) / targetWidth,
				yRatio = (img.height - targetHeight) / targetHeight;

			var left = (e.pageX - offset.left),
				top = (e.pageY - offset.top);

			top = Math.max(Math.min(top, targetHeight), 0);
			left = Math.max(Math.min(left, targetWidth), 0);

			img.style.left = (left * -xRatio) + 'px';
			img.style.top = (top * -yRatio) + 'px';
		}
	}
};
