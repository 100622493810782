'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util'),
	dialog = require('./../../dialog'),
	validator = require('./../../../../../app_storefront_core/cartridge/js/validator');

var options = {
	pdpForm: '.js-pdp_form',
	pdpMainSelector: '#pdpMain'
};

var $cache = {};

function initializeCache() {
	$cache.pdpMain = $('#pdpMain');
}


function initializeEvents() {
	$cache.pdpMain.on('click', '.js-back_in_stock', function (e) {
		e.preventDefault();

		var productLink = $(this).data('product-href');

		dialog.open({
			url: getBackInStockURL(Urls.backInStockURL, productLink),
			options: {
				dialogClass: 'dialog-back_in_stock'
			}
		});
	});
}


function getBackInStockURL(originalSourceUrl, params) {
	var paramsObj = getParamsfromURL(params);
	var dialogUrl = dialogUrl = Urls.frameView;

	for(var key in paramsObj) {
		originalSourceUrl = util.appendParamToURL(originalSourceUrl, key, paramsObj[key]);
	}

	dialogUrl = util.ajaxUrl(dialogUrl);
	return util.appendParamsToUrl(dialogUrl, {sourceUrl: originalSourceUrl});
}


function getParamsfromURL(url) {
	url = url.split("?")[1];
	var params = url.split("&");
	var paramsMap = {};

	params.forEach(function (p) {
		var v = p.split("=");
		paramsMap[v[0]] = decodeURIComponent(v[1]);
	});

	return paramsMap;
}


module.exports = {
	init: function(params) {
		initializeCache();
		initializeEvents();
	}
};
