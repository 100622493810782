'use strict';
var carousel = require('./../../carousel'),
  dialog = require('./../../dialog'),
  zoom = require('./../../zoom'),
  product360view = require('./../../product360view'),
  stickykit = require('./../../stickykit'),
  util = require('./../../util'),
  imagesLoaded = require('imagesloaded'),
  qs = require('qs'),
  url = require('url'),
  _ = require('lodash'),
  options = {
    'enableImgDots': true,
    'minimizedHeaderHeight': 115,
    'carousel': {},
    'stickykit': {
      'container': '.js-images-indicator',
      'options': {
        'parent': '.b-pdp-product_view',
        'offset_top': function() {
          return $('.js-sticky_header-wrapper').height();
        },
        'bottoming': true
      }
    }
  },
  $cache = {};


/**
 * @private
 * @function
 * @description Initializes parameters for component
 */
function initializeParams(params) {
  options = $.extend(true, {}, options, params);
}


/**
 * @private
 * @function
 * @description Initializes cache for component
 */
function initializeCache() {
  $cache.productImages = $('.js-pdp-product_images');
}


/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeDom() {
  var carouselOptions = $.extend(true, {}, {
    container: $cache.productImages,
    dots: true,
    loop: false
  }, options.carousel);

  updatePinButton();

  product360view.init({
    container: $cache.productImages
  });

  carousel.init(carouselOptions);

  imagesLoaded($cache.productImages).on('always', function() {
    $(document).trigger('product.imagesloaded');

    // initialize product image indicator
    if (options.enableImgDots && !util.isMobile()) {
      initImageDots();
    }
  });
}


/**
 * @private
 * @function
 * @description Initializes events for component
 */
function initializeEvents() {
  $(document).on('click', '.js-zoom-link[href]', function(e) {
    e.preventDefault();

    var zoomHtml = '<div class="b-zoom-container js-zoom-container js-loading"></div><a class="ui-dialog-close_overlay js-dialog-close" href="#"></a>',
      zoomUrl = $(this).attr('href');

    dialog.open({
      html: zoomHtml,
      options: {
        dialogClass: 'dialog-image_zoom',
        open: function() {
          var $zoomContainer = $(this).find('.js-zoom-container');

          zoom.init(zoomUrl, $zoomContainer, e, function() {
            $(document).off('click.zoom');
            $zoomContainer.removeClass('js-loading');
          });
        }
      }
    });
  });
}


/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
function replaceImages(callback) {
  var $newImages = $("#update-images"),
    scrollTop = $(window).scrollTop(),
    isQuickView = $("body").hasClass("js-quickview_opened");

  if ($newImages.length === 0) {
    (typeof callback == 'function') && callback();
    return;
  }

  !$cache.productImages && initializeCache();

  imagesLoaded($newImages).on('always', function() {
    if (isQuickView) {
      $('.js-pdp-quickview .js-pdp-product_images').html($newImages.html());
      $newImages.remove();
    } else {
      $('.js-pdp-product_images').html($newImages.html());
      initializeDom();
      $(window).scrollTop(scrollTop);
      $newImages.remove();
    }

    (typeof callback == 'function') && callback();
  });
}

/**
 * @private
 * @function
 * @description Initialize image indicator on product detail page
 */
function initImageDots() {
  var stickyOptions = options.stickykit,
    headerHeight = $('.js-sticky_header-wrapper').height(),
    $productImages = $cache.productImages.find('.js-pdp-image_wrapper'),
    $imgIndicator,
    $imgIndicatorItems,
    imgTops = [],
    imgSetActive = [],
    activeIndex = 0,
    checkOnScroll = true;

  if ($productImages.size() > 1) {

    // create product images indicator
    $imgIndicator = $('.js-images-indicator');
    if (!$imgIndicator.size()) {
      $imgIndicator = '<div class="b-pdp-images-indicator js-images-indicator">';
      for (var i = 0; i < $productImages.length; i++) {
        $imgIndicator += '<span class="b-pdp-images-indicator_item js-images-indicator_item"></span>';
      }
      $imgIndicator += '</div>';
      $imgIndicator = $($imgIndicator);
      $imgIndicator.appendTo($cache.productImages);
    }

    $imgIndicatorItems = $imgIndicator.find('.js-images-indicator_item');

    $imgIndicatorItems.eq(0).addClass('active');

    calcImageTops();

    stickykit.init(stickyOptions, true);

    checkActiveImg();

    $imgIndicatorItems.on('click', function() {
      setActiveImg($(this).index(), true);
    });

    $(window)
      .on('scroll', function() {
        checkOnScroll && checkActiveImg();
      })
      .on('resize', function() {
        calcImageTops();
      });
  }

  // calculate product images top positions
  function calcImageTops() {
    for (var i = 0; i < $productImages.length; i++) {
      imgTops[i] = $productImages.eq(i).offset().top;
      imgSetActive[i] = imgTops[i] - ($productImages.eq(i).height() / 2);
    }
    $imgIndicator.outerHeight($(window).height() / 2);
  }

  // find index of product image that is in view
  function checkActiveImg() {
    var windowTop = $(window).scrollTop() + headerHeight,
      newIndex = imgSetActive.length - 1;

    for (var i = 1; i < imgSetActive.length; i++) {
      if (windowTop < imgSetActive[i]) {
        newIndex = i - 1;
        break;
      }
    }

    (newIndex !== activeIndex) && setActiveImg(newIndex);
  }

  // adding active class to image indicator item when product image is in view
  // scroll to product image when image indicator item clicked
  function setActiveImg(imageIndex, scroll) {
    activeIndex = imageIndex;

    $imgIndicatorItems
      .removeClass('active')
      .eq(activeIndex).addClass('active');

    if (!!scroll) {
      checkOnScroll = false;
      $('html, body').animate({
        scrollTop: imgTops[activeIndex] - (imgTops[activeIndex] > $(window).scrollTop() ? options.minimizedHeaderHeight : headerHeight)
      }, 500, function() {
        checkOnScroll = true;
      });
    }
  }
}


function updatePinButton(imageUrl) {
  var pinButton = document.querySelector('.share-icon[data-share=pinterest]'),
    productImage = document.querySelector('.js-pdp-image');
  if (!pinButton || !productImage) {
    return;
  }

  var newUrl = !imageUrl ? productImage.getAttribute('src') : imageUrl;
  var href = url.parse(pinButton.href);
  var query = qs.parse(href.query);

  query.media = url.resolve(window.location.href, newUrl);
  query.url = window.location.href;

  var newHref = url.format(_.extend({}, href, {
    query: query, // query is only used if search is absent
    search: qs.stringify(query)
  }));

  pinButton.href = newHref;
}


/* @module image
 * @description this module handles the primary image viewer on PDP
 **/
module.exports = {
  init: function(params) {
    initializeParams(params);
    initializeCache();
    initializeDom();
    initializeEvents();
  },
  replaceImages: replaceImages
};
