'use strict';

var $cache = {},
  options = {},
  config = require('./carousel-config'),
  callbacks = ['onInitialize', 'onInitialized', 'onResize', 'onResized', 'onRefresh', 'onRefreshed', 'onDrag', 'onDragged', 'onTranslate', 'onTranslated', 'onChange', 'onChanged', 'onLoadLazy', 'onLoadedLazy', 'onStopVideo', 'onPlayVideo'];

function initializeParams(params) {
  options = $.extend(true, {}, config, params);
}

function initializeCache() {
  $cache.document = $(document);
}

function initOwlCarousel() {
  var $container = $(this),
    customConfig = $container.data('carouselOptions') || {},
    carouselOptions = $.extend({}, options, customConfig),
    $carousel = $container.find('.' + carouselOptions.baseClass),
    itemsLength = $carousel.find('.owl-carousel-item').length;

  for (var i in callbacks) {
    carouselOptions[callbacks[i]] = eventProxy($carousel, callbacks[i]);
  }

  carouselOptions.nav = itemsLength > 1 ? carouselOptions.nav : false;
  carouselOptions.loop = itemsLength > 1 ? carouselOptions.loop : false;

  $carousel.on('initialized.owl.carousel', function(e) {
    e.item.count < e.page.size ? $(this).addClass('owl-hide-controls') : $(this).removeClass('owl-hide-controls');
  });

  if ($(window).width() > 1024 && carouselOptions.showThumbnailsNavigation && itemsLength > 4) {
    carouselOptions.dots = false;
    carouselOptions.dotsContainer = '';

    $carousel.owlCarousel(carouselOptions).on('changed.owl.carousel', function(el) {
      syncPosition(el, $container);
    });

    initThumbnailsCarousel($container, $carousel);
  } else {
    $carousel.owlCarousel(carouselOptions);
  }


  // Button for scrolling a thumbnails list to the end instantly
  $(".js-scroll-pdp-thumbnails--bottom").click(function() {
    let thumbsContainer = $(".js-pdp-thumbnails");
    let scrollHeight = thumbsContainer[0].scrollHeight;
    thumbsContainer.animate({
      scrollTop: scrollHeight
    }, 400, 'swing');
  });

  $(".js-scroll-pdp-thumbnails--top").click(function() {
    let thumbsContainer = $(".js-pdp-thumbnails");
    thumbsContainer.animate({
      scrollTop: 0
    }, 400, 'swing');
  });

}

function initThumbnailsCarousel(container, carousel) {
  var thumbnails = container.find('.b-pdp-thumbnail_list');

  if (thumbnails.length) {
    thumbnails.on('initialized.owl.carousel', function() {
      thumbnails.find(".owl-item").eq(0).addClass("current");
    }).owlCarousel({
      navText: '',
      dots: false,
      nav: true,
      mouseDrag: false,
      slideBy: 2,
      items: 4,
      margin: 15

    });

    thumbnails.on("click", ".owl-item", function(e) {
      e.preventDefault();
      var number = $(this).index();
      carousel.trigger("to.owl.carousel", [number, 200, true]);
    }).bind(this);
  }
}

function syncPosition(el, container) {
  var current = el.item.index;
  var thumbnails = container.find('.b-pdp-thumbnail_list');

  thumbnails
    .find(".owl-item")
    .removeClass("current")
    .eq(current)
    .addClass("current");

  var onscreen = thumbnails.find('.owl-item.active').length - 1;
  var start = thumbnails.find('.owl-item.active').first().index();
  var end = thumbnails.find('.owl-item.active').last().index();

  if (current > end) {
    thumbnails.trigger("to.owl.carousel", [current, 200, true]);
  }
  if (current < start) {
    thumbnails.trigger("to.owl.carousel", [current - onscreen, 200, true]);
  }
}

function eventProxy(element, event) {
  return function() {
    $(element || document).trigger('carousel.' + event, this);
  };
}

function initAllCarousels() {
  $(this)
    .find(options.containerSelector)
    .each(initOwlCarousel);
}

function initAllRecommCarousels() {
  // Init owl carousels when Einstein has loaded
  document.addEventListener('recommendations.loaded', function() {
    let recommBlocks = $('.b-product_carousel .owl-carousel:not(.owl-loaded)');//
    if (recommBlocks.length > 0) {
      $.map(recommBlocks, function(block) {
        let carouselOptions = $(block).parent('.js-carousel-wrapper').data('carouselOptions');
        $(block).on('initialized.owl.carousel', function(e) {
          e.item.count < e.page.size ? $(this).addClass('owl-hide-controls') : $(this).removeClass('owl-hide-controls');
        });
        $(block).owlCarousel(carouselOptions);
      });
    }
  });
}


module.exports = {
  init: function(params) {
    initializeParams(params);
    initializeCache();
    initAllCarousels.call(params.container || document);
    initAllRecommCarousels();
  }
};
