'use strict';

var compareWidget = require('./../../../../../app_storefront_core/cartridge/js/compare-widget'),
	productTile = require('./../../product-tile'),
	progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
	util = require('./../../util'),
	tls = require('./../../tls'),
	carousel = require('./../../carousel'),
	layout = require('./../../layout'),
	_ = require('lodash'),
	$cache = {},
	options = {
		dynamicProductsContainer: '.js-search-result-items:last',
		uploadedChunk: false
	};

var loadingPlaceHolderSelector = '.infinite-scroll-placeholder[data-loading-state="unloaded"]',
	loadingTopPlaceHolderSelector = '.infinite-scroll-top-placeholder[data-loading-state="unloaded"]',
	timeOut;

function updateUrl(paramName, paramValue) {
	var url = location.href,
		paramExist = new RegExp("(?:&|\\?)" + paramName + "=(\\w*)"),
		paramReplace = new RegExp(paramName + "=\\w+(?=(&|$))"),
		paramDelete = new RegExp("&" + paramName + "=\\w*|" + paramName + "=\\w*&|\\?" + paramName + "=\\w*$"),
		existSubstr = location.search.match(paramExist),
		state = SitePreferences.LISTING_INFINITE_SCROLL ? {} : $cache.mainWrapper.html();
	if (existSubstr && (existSubstr[1] == "" || existSubstr[1] != paramValue)) {
		history.replaceState(state, "", paramValue ? url.replace(paramReplace, paramName + "=" + paramValue) : url.replace(paramDelete, ""));
	} else if (!existSubstr && paramValue) {
		history.replaceState(state, "", url + (location.search ? "&" : "?") + paramName + "=" + paramValue);
	}
}

function changePageParam() {
	var topBorderPosition = !!$cache.topPageElement.length ? $cache.topPageElement.get(0).getBoundingClientRect().bottom : 0,
		bottomBorderPosition = document.documentElement.clientHeight,
		maxDelta = -Infinity,
		currentVisibleElement = 0,
		pageItems = $('.js-search-result-items');
	pageItems.each(function(i) {
		var rect = this.getBoundingClientRect(),
			top = rect.top > topBorderPosition ? rect.top : topBorderPosition,
			bottom = rect.bottom < bottomBorderPosition ? rect.bottom : bottomBorderPosition,
			delta = bottom - top;
		if (delta > maxDelta) {
			maxDelta = delta;
			currentVisibleElement = i;
		}
	});
	var currentPageElement = pageItems.eq(currentVisibleElement),
		pageNumber = Math.floor(currentPageElement.data("pageNumber"));
	updateUrl("page", pageNumber!=1 ? pageNumber : '');
}

function loadChunk(loadingPlaceHolder, isTop) {
	// get url hidden in DOM
	var gridUrl = loadingPlaceHolder.attr('data-grid-url'),
		start = parseInt(util.getParameterValueFromUrl('start', gridUrl));
	// switch state to 'loading'
	// - switches state, so the above selector is only matching once
	// - shows loading indicator
	loadingPlaceHolder.attr('data-loading-state', 'loading');
	loadingPlaceHolder.addClass('infinite-scroll-loading');


	// named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
	var fillEndlessScrollChunk = function (html, isTop) {
		loadingPlaceHolder.removeClass('infinite-scroll-loading');
		loadingPlaceHolder.attr('data-loading-state', 'loaded');
		if (!isTop) {
			html.filter(".infinite-scroll-top-placeholder").attr('data-loading-state', 'loaded');
			$('div.search-result-content').append(html);
		} else {
			var elementPosition = loadingPlaceHolder.get(0).getBoundingClientRect().top;
			html.filter(".infinite-scroll-placeholder").attr('data-loading-state', 'loaded');
			$('div.search-result-content').prepend(html);
			window.scrollBy(0, loadingPlaceHolder.get(0).getBoundingClientRect().top - elementPosition + $cache.scrollAdjust);
			$cache.scrollAdjust = 0;
				}
				carousel.init({});
		$cache.window.trigger('infinitescroll.loaded');
	};

	var isCategoryUploading = loadingPlaceHolder.attr('data-is-category');
		if (isCategoryUploading === 'true') {
				var toUploadCategories = loadingPlaceHolder.attr('data-upload-categories');
				var originalCategory = loadingPlaceHolder.attr('data-original-categoty-id');

				if (!originalCategory) {
						return;
				}

				gridUrl = util.appendParamToURL(gridUrl, 'cgid', originalCategory);

				if (toUploadCategories) {
						gridUrl = util.appendParamToURL(gridUrl, 'categories', toUploadCategories);
				}
		}

	// old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
	// it was removed to temporarily address RAP-2649

	$.ajax({
		type: 'GET',
		dataType: 'html',
		url: gridUrl,
		success: function (response) {
			var response = $($.parseHTML(response));
			// put response into cache
			try {
				sessionStorage['scroll-cache_' + gridUrl] = response;
			} catch (e) {
				// nothing to catch in case of out of memory of session storage
				// it will fall back to load via ajax
			}
			// update UI
			initBannerContentSlot(response);
			productTile.init({container: response, start: start});
			fillEndlessScrollChunk(response, isTop);
		}
	});
}

function initializeCache() {
	$cache.searchContainer = $('.js-product_search-container');
	$cache.window = $(window);
	$cache.document = $(document);
	$cache.topPageElement = $('.js-sticky-refinements');
	$cache.scrollAdjust = 0;
	$cache.mainWrapper = $cache.main || $('#main');
}

function infiniteScroll() {
	changePageParam();

	// getting the hidden div, which is the placeholder for the next page
	var loadingPlaceHolder = $(loadingPlaceHolderSelector),
		loadingTopPlaceHolder = $(loadingTopPlaceHolderSelector),
		topPageElementBottom = (!!$cache.topPageElement.length && $cache.topPageElement.get(0)) ? $cache.topPageElement.get(0).getBoundingClientRect().bottom : 0;

		var loaderIndicatorSelector = $('.loader-indicator');

	// Start loading when placeholder is in viewport or it has already scrolled up to top
	if (!loaderIndicatorSelector.length && loadingPlaceHolder.length === 1 && (util.elementInViewport(loadingPlaceHolder.get(0), 500) || loadingPlaceHolder.offset().top < $cache.window.scrollTop())) {
		loadChunk(loadingPlaceHolder);
	} else if (loadingTopPlaceHolder.length === 1 && topPageElementBottom > 0 && topPageElementBottom - loadingTopPlaceHolder.get(0).getBoundingClientRect().top < 50) {
		loadChunk(loadingTopPlaceHolder, true);
	} else if (options.uploadedChunk && loadingPlaceHolder.length === 1 && (util.elementInViewport(loadingPlaceHolder.get(0), 500) || loadingPlaceHolder.offset().top < $cache.window.scrollTop())) {
		loadChunk(loadingPlaceHolder);
	}
}

function createLoadMoreLink($loadingPlaceHolder) {
	if ($loadingPlaceHolder.length) {
		var gridUrl = $loadingPlaceHolder.attr('data-grid-url'),
			$loadMoreLink = $('<a/>', {
				href: gridUrl,
				class: "b-button m-text b-load-more_products js-load_more_products",
				html: '<span class="b-button-inner">' + Resources.LOAD_MORE_PRODUCTS + '</span>'
			});
		$loadMoreLink.appendTo($loadingPlaceHolder);
	}
}
/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing(url) {
	if (!url || url === window.location.href) {
		return;
	}

	progress.show($('.search-result-content'));

	$cache.mainWrapper.load(util.appendParamToURL(url, 'format', 'ajax'), function (response, status, xhr ) {
		if (xhr.status === 302 && xhr.responseText) {
			try {
					var responseJSON = JSON.parse(xhr.responseText);
					if (responseJSON && responseJSON.redirectUrl) {
							window.location.href = responseJSON.redirectUrl;
					}
			} catch (error) {}
		}
		initBannerContentSlot();
		compareWidget.init();
		productTile.init();
		carousel.init({});
		updateImageViewLinks();
		$(document).trigger('search.update', [{url: url}]);
		progress.hide();
		if (SitePreferences.LISTING_INFINITE_SCROLL) {
			response = null;
		}
		history.pushState(response, '', url);
	});
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
	var $main = $('#main');

	// Disable auto scroll to previous position when user go back to PLP (in case infinity scroll)
	if ('scrollRestoration' in history) {
		history.scrollRestoration = SitePreferences.LISTING_INFINITE_SCROLL ? 'manual' : 'auto';
	}

	// compare checked
	$main.on('click', 'input[type="checkbox"].compare-check', function () {
		var cb = $(this);
		var tile = cb.closest('.js-product_tile');

		var func = this.checked ? compareWidget.addProduct : compareWidget.removeProduct;
		var itemImg = tile.find('.product-image a img').first();
		func({
			itemid: tile.data('itemid'),
			uuid: tile[0].id,
			img: itemImg,
			cb: cb
		});

	});

	// toggle grid view
	$main.on('click', '.js-toggle_grid', function (event) {
		if(event.target.dataset.option === 'wide') {
			$cache.searchContainer.addClass('wide-tiles');
			$(this).addClass('wide');
			updateUrl("viewMode", "wide");
		} else {
			$cache.searchContainer.removeClass('wide-tiles');
			$(this).removeClass('wide');
			updateUrl("viewMode", "");
		}

		initBannerContentSlot();
	});

	// handle events for updating grid
	$main.on('click', '.js-search_update, .refinements a, .pagination a, .breadcrumb-refinement-value a', function (e) {
		// don't intercept for category and folder refinements, as well as unselectable
		if ($(this).parents('.folder-refinement').length > 0 || $(this).parent().hasClass('unselectable')) {
			return;
		}
		e.preventDefault();
		updateProductListing(this.href);
	});

	// handle events item click. append params.
	$main.on('click', '.js-product_tile a:not("#quickviewbutton")', function () {
			var a = $(this);
			// get current page refinement values
			var refinements = a.parents(".js-search-result-content").data('refinements-query');
			var params = refinements ? util.getQueryStringParams(refinements) : {};
			if (!params.start) {
				params.start = 0;
			}
			// get the index of the selected item and save as start parameter
			var tile = a.closest('.js-product_tile');
			var idx = tile.data('idx') ? +tile.data('idx') : 0;

			// convert params.start to integer and add index
			params.start = (+params.start) + (idx + 1);
			// set the hash and allow normal action to continue
			a[0].hash = $.param(params);
			// set params in session storage for scroller after reload page
			var productPage = a.parents("ul#search-result-items").data("pageNumber");
			if (productPage != params.page) {
				updateUrl("page", productPage!=1 ? productPage : '');
			}
			sessionStorage.scrollParams = JSON.stringify({
				cgid: params.cgid,
				pid: tile.data("itemid"),
				position: tile.get(0).getBoundingClientRect().top

			});
		})
		.on('change', '.items-per-page select', function () {
			var refineUrl = $(this).find('option:selected').val();
			if (refineUrl === 'INFINITE_SCROLL') {
				$('html').addClass('infinite-scroll').removeClass('disable-infinite-scroll');
			} else {
				$('html').addClass('disable-infinite-scroll').removeClass('infinite-scroll');
				updateProductListing(refineUrl);
			}
		})
		.on('click', '.js-load_more_products', function (event) {
			event.preventDefault();
			$(this).remove();
			infiniteScroll();
		})
		.on('click', '.js-product_view', function (e) {
			e.preventDefault();
			var $viewItem = $(e.target),
				clickedType = $viewItem.data('type');

			writeViewType(clickedType);
			changeImageView(clickedType);
		});

	$(window).on('resize', _.debounce(function () {
		initBannerContentSlot();
	}, 300));
}

function writeViewType(viewType) {
	var existingTypes = tls.getCookie('viewTypes') || "{}",
		category = pageContext.categoryID;
	if(viewType && category) {
		try {
			existingTypes = JSON.parse(existingTypes);
			existingTypes[category] = viewType;
			tls.setCookie('viewTypes', JSON.stringify(existingTypes),  60 * 24 * 30);
		} catch(e) {
			tls.setCookie('viewTypes', null, -1);
			writeViewType(viewType);
		}
	}
}

function getViewType(category) {
	var existingTypes = tls.getCookie('viewTypes') || "{}",
		result;
	try {
		existingTypes = JSON.parse(existingTypes);
		if(category in existingTypes) {
			result = existingTypes[category];
		}
	} catch(e) {
		result = null;
	}
	return result;
}

/**
 * @private
 * @function
 * @description change image view for product tiles
 */
function changeImageView(viewType) {
	if(!viewType && pageContext.categoryID) {
		viewType = getViewType(pageContext.categoryID)
	}

	if (viewType === 'outfit') {
		$cache.searchContainer.find('.js-product_view-outfit').addClass('m-active').siblings().removeClass('m-active');
		$cache.searchContainer.removeClass('product-view').addClass('outfit-view');
	} else {
		$cache.searchContainer.find('.js-product_view-product').addClass('m-active').siblings().removeClass('m-active');
		$cache.searchContainer.removeClass('outfit-view').addClass('product-view');
	}
}

/**
 * @private
 * @function
 * @description set appropriate link as active according to selected image view
 */
function updateImageViewLinks() {
	$cache.searchContainer
		.find($cache.searchContainer.hasClass('outfit-view') ? '.js-product_view-outfit' : '.js-product_view-product')
		.addClass('m-active')
		.siblings()
		.removeClass('m-active');
}

/**
 * @private
 * @function
 * @description Initialisation inline banners inside products grid
 */
function initInlineContent() {
	function insertInlineContent($inlineBanners, $tilesContainer) {
		var $productTiles = $tilesContainer.find('.js-grid_tile'),
			minPosition = $tilesContainer.data('page-start'),
			ptSize = $productTiles.size();

		$inlineBanners.each(function() {
			var bannerPosition = $(this).data('position') - minPosition;
			if (bannerPosition >= 0 && bannerPosition < ptSize) {
				$(this).insertBefore($productTiles.eq(bannerPosition));
			}
		});
	}

	function updateInlineContent() {
		var $inlineBanners = $('.js-inline-banners').children();
		$cache.window.off('infinitescroll.loaded.inlinecontent');

		if ($inlineBanners.size() > 0) {
			insertInlineContent($inlineBanners, $(options.dynamicProductsContainer));

			$cache.window.on('infinitescroll.loaded.inlinecontent', function(e) {
				insertInlineContent($inlineBanners, $(options.dynamicProductsContainer));
			});
		}
	}

	updateInlineContent();
	$cache.document.on('search.update', updateInlineContent);
}

function scrollToPreviousPosition() {
	var scrollParams = JSON.parse(sessionStorage.scrollParams || null);
	if (scrollParams) {
		var searchParams = window.location.search,
			qsParams = (searchParams.length > 1) ? util.getQueryStringParams(searchParams.substr(1)) : {},
			previousProduct = $(".js-product_tile[data-itemid='" + scrollParams.pid + "']"),
			previousProductTop = previousProduct.length ? previousProduct.get(0).getBoundingClientRect().top : 0,
			scrollAdjust = previousProductTop - scrollParams.position;
		if (scrollParams.cgid == qsParams.cgid && scrollAdjust > 0) {
			window.scrollBy(0, scrollAdjust);
		} else {
			$cache.scrollAdjust = scrollAdjust;
		}
		delete sessionStorage.scrollParams;
	}
	infiniteScroll();
}

function initBannerContentSlot($container) {
	$container = $container && $container.filter('.js-search-result-items') || $('.js-search-result-items');
	var $banner = $('.js-plp-wide-slot');

	if ($banner.length) {
		for (var i = 0; i < $container.length; i++) {
			var $currentContainer = $($container[i]);
			var slotPositionConfig = $currentContainer.data('slotsPosition');

			if (slotPositionConfig && slotPositionConfig.showBannerSlot) {
				var skipTiles = slotPositionConfig.position[layout.getMode()];

				if (skipTiles) {
					if ($cache.searchContainer.hasClass('wide-tiles') && !$cache.searchContainer.hasClass('js-skip-wide')) {
						skipTiles = slotPositionConfig.isB2B ? skipTiles - (skipTiles % 4) : skipTiles - (skipTiles % 3);
					}

					if (skipTiles > 0) {
						$currentContainer.children('li:not(".js-plp-wide-slot"):eq(' + --skipTiles + ')').after($banner);
					} else {
						$currentContainer.prepend($banner);
					}

					$banner.removeClass('plp-wide-slot-hidden');

					break;
				}
			}
		}
	}
}

exports.init = function () {
	initializeCache();
	initInlineContent();
	changeImageView();
	initBannerContentSlot();
	compareWidget.init();

	if (SitePreferences.LISTING_INFINITE_SCROLL) {
		$(window).on('scroll', infiniteScroll);
		$(document).one('upload-products-chunk', function() {
			options.uploadedChunk = true;
		});
	} else {
		history.replaceState($cache.mainWrapper.html(), '', document.location.href);
	}
	productTile.init();
	initializeEvents();
	scrollToPreviousPosition();
};
