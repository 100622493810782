'use strict';

var util = require('./../../../app_storefront_core/cartridge/js/util'),
	ajax = require('./../../../app_storefront_core/cartridge/js/ajax'),
	$cache = {};

function initislizeCache(){
	$cache.stopFakeForm = $('#stopFakeForm');
	$cache.stopFakeInput = $('#stop-fake-input');
	$cache.stopFakeButton = $('#stop-fake-btn-search');
	$cache.stopFakeMsgContainer = $('#stop-fake-message-container');
	$cache.stopFakeFeedForm = $('.js-stop_fake_feed-form');
	$cache.fakeFeedMsgContainer = $('.js-stop_fake_feed-message');
	$cache.fakeFeedFields = $cache.stopFakeFeedForm.find("input[type=text], input[type=email], textarea");
}

function redirect(newURL) {
	setTimeout(function () {
		window.location.href = newURL;
	}, 0);
}

function refresh() {
	setTimeout(function () {
		window.location.assign(window.location.href);
	}, 500);
}

function getPageContextFromDOM() {
	/** 
	* Add data from remote includes to pageContext
	*
	* Example of setting data attribute in remote include rendering template:
	*	 <div class="js-page-context"
	*		 data-page-context='{any : data}'
	*	'>
	*	</div>
	*/
	if($('.js-page-context').length) {
		$('.js-page-context').each(function() {
			var data = $(this).data('pageContext');
			
			if(data) {
				$.extend(true, window.pageContext, data);
			}
		});
	}
}

function checkSiteForFake(e) {
	e.preventDefault();

	var url = $cache.stopFakeForm.attr('action');
	var siteName = $cache.stopFakeInput.find('input[type=text]').val();

	ajax.getJson({
		url: util.ajaxUrl(url),
		data: {
			siteUrl: siteName
		},
		callback: function (response) {
			if (response.valid) {
				//site is an official distributor
				$cache.stopFakeMsgContainer.removeClass('m-error').addClass('m-success');
			} else {
				//site is a fake
				$cache.stopFakeMsgContainer.removeClass('m-success').addClass('m-error');
			}

			$cache.stopFakeMsgContainer.html(response.message);
		}
	});
}

function sendSuspiciousUrl(e) {
	e.preventDefault();
	
	var $this = $(this),
		formData;
	
	if($this.validate().form()) {
		var formData = $this.serializeArray(),
			$actionButton = $this.find('button[type=submit]');
		
		//replace symbols
		var $result = $.grep(formData, function(e){
			return e.name.indexOf('_comment') > -1;
		});
		
		if($result.length) {
			var comment = $result[0];
			comment.value = comment.value.replace(/(\r\n|\n|\r)/gm," ");
		}
		
		formData.push({
			name: $actionButton.attr('name'),
			value: $actionButton.val()
		});
		
		$.ajax({
			url: $this.attr('action'),
			method: "POST",
			data: $.param(formData)
		}).done(function (data) {
			if (data) {
				if (data.success) {
					$cache.fakeFeedMsgContainer.removeClass('m-error').addClass('m-success');
					$cache.fakeFeedFields.val("");
				} else {
					$cache.fakeFeedMsgContainer.removeClass('m-success').addClass('m-error');
				}
				$cache.fakeFeedMsgContainer.text(data.msg);
			}
		});
	}
}
function initEvents() {
	$cache.stopFakeButton.on('click', checkSiteForFake);
	$cache.stopFakeFeedForm.on('submit', sendSuspiciousUrl);
}

function init() {
	getPageContextFromDOM();
	initislizeCache();
	initEvents();
}

module.exports = $.extend({
	title: '',
	type: ''
}, window.pageContext, {
	'params': util.getQueryStringParams(window.location.search.substr(1)),
	'redirect': redirect,
	'refresh': refresh,
	'init': init
});