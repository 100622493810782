'use strict';

/** internal dependency references */
var dialog = require('./'),
	util = require('./../util'),
	progress = require('./../../../../app_storefront_core/cartridge/js/progress');

/** private vars */
var initialized = false,
	options = dialog.settings || {},
	cache = {};

/** Components parameters initialization */
function initializeParams(params) {
	options = $.extend(true, {}, options, params);
}

/** DOM elements initialization */
function initializeDOM() {}

/** Cache initialization */
function initializeCache() {
	cache.$document = $(document);
}

/** Event handlers initialization */
function initializeEvents() {
	cache.$document
	// add class to body when dialog is open
	.on('dialogopen', function (event, ui) {
		$('body').addClass('js-dialog_opened');

		var $dialog = $(event.target);
		$dialog.on('click', '.js-dialog-close', function(e) {
			e.preventDefault();
			$dialog.dialog('close');
		});

		// reposition dialog when its height was changed
		util.watchProperty($dialog, 'clientHeight', function(e, oldHeight, newHeight) {
			$dialog.dialog("option", "position", $dialog.dialog("option", "position"));
		}, 500);
	})
	// remove class from body when dialog is closed
	.on('dialogclose', function (event, ui) {
		!$(':ui-dialog').dialog('isOpen') && $('body').removeClass('js-dialog_opened');

		util.stopWatchProperty($(event.target), 'clientHeight');
	})
	// attach global loader for dialog open action when ajax request should be executed
	.on('dialog.beforecontentload dialog.contentloaded', ':ui-dialog', function (event, data) {
		progress[event['namespace'] === 'beforecontentload' ? 'show' : 'hide']();
	})
	// global dialog element initialization hanlder
	.on('click', '.js-dialog', function (event, data) {
		var $this = $(this),
			dialogOptions = $this.data('dialogOptions'),
			target = $this.data('dialogTarget'),
			html = $this.data('dialogHtml'),
			$target = $(target),
			$html = $(html);

		if (typeof dialogOptions == 'string' && dialogOptions.length > 0) {
			dialogOptions = JSON.parse(dialogOptions);
		} else if (typeof dialogOptions != 'object') {
			dialogOptions = {};
		}

		!dialogOptions.title && $this.attr('title') && (dialogOptions.title = $this.attr('title'));

		event.preventDefault();
		dialog.open({
			'target' : $target.length ? $target : $this.data('dialogTarget'),
			'html' : $html.length ? $html.html() : html,
			'url' : $this.data('dialogUrl') || $this.attr('href'),
			'options' : $.extend(true, {}, options, dialogOptions)
		});
	});
}

module.exports = {
	init : function (params) {
		if (initialized) return;

		initializeParams(params);
		initializeCache();
		initializeDOM();
		initializeEvents();

		initialized = true;
	},
	reload : $.noop
};
