'use strict';

var dialog = require('./../../../app_storefront_core/cartridge/js/dialog'),
	page = require('./../../../app_storefront_core/cartridge/js/page'),
	validator = require('./../../../app_storefront_core/cartridge/js/validator');

var login = {
	/**
	 * @private
	 * @function
	 * @description init events for the loginPage
	 */
	init: function () {
		var createAccountForm = $('form.f-create_account');
		if (createAccountForm.length) {
			createAccountForm.find('input[name*="_passwordconfirm_"]').addClass('js-passwordconfirm_field');

			//confirm email validation
			createAccountForm.find('.js-original-email input').addClass('js-email_field');
			createAccountForm.find('.js-confirm-email input').addClass('js-emailconfirm_field');
		}
		//o-auth binding for which icon is clicked
		$('.oAuthIcon').bind('click', function () {
			$('#OAuthProvider').val(this.id);
		});

		//prevent double submit register form
		createAccountForm.submit(function(event) {
			var submitted = createAccountForm.data('submitted');
		    if (createAccountForm.valid() && !submitted) {
		    	createAccountForm.data('submitted', true);
		    }else {
		    	event.preventDefault();
		    }
		});

		//toggle the value of the rememberme checkbox
		$('#dwfrm_login_rememberme').bind('change', function () {
			if ($('#dwfrm_login_rememberme').attr('checked')) {
				$('#rememberme').val('true');
			} else {
				$('#rememberme').val('false');
			}
		});

		$('#password-reset').on('click', function (e) {
			e.preventDefault();
			dialog.open({
				url: $(e.target).attr('href'),
				options: {
					open: function () {
						validator.init();
						var $requestPasswordForm = $('[name$="_requestpassword"]');
						var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
						$($submit).on('click', function (e) {
							if (!$requestPasswordForm.valid()) {
								return;
							}
							e.preventDefault();
							var data = $requestPasswordForm.serialize();
							// add form action to data
							data += '&' + $submit.attr('name') + '=';
							// make sure the server knows this is an ajax request
							if (data.indexOf('ajax') === -1) {
								data += '&format=ajax';
							}
							$.ajax({
								type: 'POST',
								url: $requestPasswordForm.attr('action'),
								data: data,
								success: function (response) {
									if (typeof response === 'object' &&
											!response.success &&
											response.error === 'CSRF Token Mismatch') {
										page.redirect(Urls.csrffailed);
									} else if (typeof response === 'string') {
										dialog.$container.html(response);
									}
								},
								failure: function () {
									dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
								}
							});
						});
					}
				}
			});
		});
	}
}

module.exports = login;
