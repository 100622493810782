'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util'),
	formPrepare = require('./formPrepare'),
    progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
    zipCodeValidation = require('./zipCodeValidation');

var IN_STORE_PICKUP = 'IN_STORE_PICKUP';

var inStorePickupModule = {};
var self;

var $cache = {},
	options = {
		deliveryMethod: '.js-delivery-method',
		standardShippingContainer: '.js-standard-shipping',
		stateWrapper: '.js-store-form-states',
		stateSelect: '.js-store-form-states select',
		stateSelectedID: '.js-store-form-states select option:selected',
		storeSelect: '.js-available-stores select',
		storeSelectedID: '.js-available-stores select option:selected',
		storeInfo: '.js-store-info',
		storeInfoUrl: '.js-get-store-url',
		storeDataJSON: '.js-store-data',
		storeResultHolder: '.js-store-search-result-holder',
		globalResultHolder: '.js-global-search-result-holder',
		storeShippingForm: '.js-store-shipping-form',
		storeAddress1: '.js-store-address1',
		storeAddress2: '.js-store-address2',
		storeCity: '.js-store-city',
		storePostalCode: '.js-store-postalcode',
		storesState: '.js-store-statecode',
		countryCode: '.js-current-country-code',
		shippingMethods: '#shipping-method-list'
	};

function initCache() {
	$cache.document = $(document);
	$cache.body = $('body');
	$cache.storeSelect = $(options.storeSelect);
	$cache.deliveryMethods = $(options.deliveryMethod);
}

function initEvents() {
	$cache.body.on('change', options.stateSelect, onStateSelect);
	$cache.body.on('change', options.storeSelect, onStoreSelect);
	$cache.body.on('click', options.deliveryMethod, onDeliveryMethodSelect);

	onDeliveryMethodSelect();
}

function showStoreForm() {
	$(options.standardShippingContainer).addClass('h-hidden');
	$(options.storeShippingForm).removeClass('h-hidden');

	formPrepare.init(self.storeFormOpt);

	$cache.document.trigger('form.show');
}

function showAddressForm() {
	if( !$(options.storeShippingForm).hasClass('h-hidden') ) {
		$(options.storeShippingForm).addClass('h-hidden');
	}

	if( $(options.standardShippingContainer).hasClass('h-hidden') ) {
		$(options.standardShippingContainer).removeClass('h-hidden');
	}

    formPrepare.init(self.shippingFormOpt);

    if (SitePreferences.IS_ZIP_CODE_VALIDATION_ENABLED) {
		zipCodeValidation.init();
	}

	$cache.document.trigger('form.show');
}

function onDeliveryMethodSelect() {
	if (isStoreDeliverySelected()) {
        showStoreForm();

        $cache.storeSelect.trigger('change');
	} else {
		showAddressForm();
    }

    $cache.body.trigger('checkout.shipping.method.select');
}

/**
 * @function
 * @description Determines whether In Store Pickup is currently selected as a delivery method
 * @returns {Boolean}
 */
function isStoreDeliverySelected() {
    return getSelectedDeliveryMethodID() === IN_STORE_PICKUP;
}

/**
 * @function
 * @description Returns ID of selected delivery method
 * @returns {String}
 */
function getSelectedDeliveryMethodID() {
    var $activeOption = $cache.deliveryMethods.filter(':checked');

    if (!$activeOption.length) {
        $activeOption = $cache.deliveryMethods.first();
    }

    return $activeOption.val();
}

function onStateSelect() {
	var $stateID = $(this).val(),
		$stateStoreOptions = !!$stateID ? self.$storeOptions.filter('.f-option.empty, .f-option.' + $stateID) : self.$storeOptions,
		currentStore = $(options.storeSelectedID);

		$cache.storeSelect.empty().append($stateStoreOptions).val('');

		// if store already selected check his state
		if(currentStore.val() !== '0' && currentStore.hasClass($stateID)) {
			$cache.storeSelect.val( currentStore.val() );
		}
}

function onStoreSelect(e) {
	var storeID = $(options.storeSelectedID).val();

	if(storeID) {
		progress.show();

		$.ajax({
			url: $(options.storeInfoUrl).val(),
			method: 'POST',
			data: 'storeID=' + storeID
		}).done(function (data) {
			if(data){
				showStoreInfo(data);
				copyStoreInfoToFormInputs();
				setSelectedState();
			}

			progress.hide();

			//trigger validation
			setTimeout(function(){
				$(options.storeShippingForm).find('input[name$="_phone"]').trigger('change');
			}, 500);
		});
	}
}

function setSelectedState() {
	var stateSelect = $(options.stateSelect);

	if (stateSelect.is('visible')) {
		var selectedStateID = $(options.stateSelectedID).val();

		// if state is empty, select the store state value
		if( !selectedStateID ) {
			var storeState = $(options.storeSelectedID).attr('class').replace('f-option ', '');

			$(options.stateSelect).val(storeState);

			//filter stores
			$(options.stateSelect).trigger('change');
		}
	}
}

function initMapStyles() {
	try {
		return JSON.parse(SitePreferences.GOOGLE_MAP_STYLES);
	} catch(e) { return []; }
}

function showStoreInfo(responseData){
	var storeInfoJSONHolder = $(responseData).find(options.storeDataJSON);

	if(storeInfoJSONHolder.length){
		try{
			$(options.storeResultHolder).removeClass('hidden');

			var storeHTMLHolder = $(responseData).find('.js-store-content');
			var storeInfo = JSON.parse(storeInfoJSONHolder.html());
			var storePosition = {lat: storeInfo.lat, lng: storeInfo.lng};

			var map = new google.maps.Map(document.getElementById('map'), {
				center: storePosition,
				zoom: 17,
				disableDefaultUI: true,
				zoomControl: true,
				styles: initMapStyles()
			});

			var marker = new google.maps.Marker({
				id: storeInfo.ID,
				position: storePosition,
				map: map,
				title: storeInfo.name
			});

			marker.setMap(map);

			var $storeSearchResult = $(options.storeResultHolder);
			$storeSearchResult.html(storeHTMLHolder.html());

			if( $(options.storeInfo).hasClass('hidden') ) {
				$(options.storeInfo).removeClass('hidden')
			}
		}catch(e){
			$(options.globalResultHolder).addClass('hidden');
		}
	}
}

function copyStoreInfoToFormInputs() {
	var $form = $(options.storeShippingForm),
		address1 = $(options.storeAddress1).val(),
		address2 = $(options.storeAddress2).val(),
		city = $(options.storeCity).val(),
		postalcode = $(options.storePostalCode).val(),
		state = $(options.storesState).val();

		$form.find('input[name$="_address1"]').val(address1);

		if(address2) {
			$form.find('input[name$="_address2"]').val(address2);
		}

		$form.find('input[name$="_city"]').val(city);
		$form.find('input[name$="_postal"]').val(postalcode);
		$form.find('select[id$="_stateCode"]').val(state);
}

function processStateDisplay() {
	var countryCode = $(options.countryCode),
		stateSelect = $(options.stateSelect);

	if (countryCode.length && stateSelect.length) {
		var selectedCountry = countryCode.val(),
			allowedCountries = SitePreferences.COUNTRIES_WITH_STATES.split(',');

		if (allowedCountries.indexOf(selectedCountry) == -1) {
			stateSelect.val('');
			$(options.stateWrapper).hide();
		}
	}
}

/**
 * @function
 * @description Fetches address information entered in the form
 * @returns {Object}
 */
function getAddressFormData() {
    var $addressForm = $(options.storeShippingForm),
        $stateCode = $addressForm.find('select[id$="_stateCode"]');

    return {
		address1: $addressForm.find('input[name$="_address1"]').val(),
		address2: $addressForm.find('input[name$="_address2"]').val(),
		countryCode: $(options.countryCode).val(), // there is no country code field on form
		stateCode: $stateCode.length ? $stateCode.val() : null, // there may be no state code field on form
		postalCode: $addressForm.find('input[name$="_postal"]').val(),
		city: $addressForm.find('input[name$="_city"]').val()
	};
}

/**
 * @function
 * @description Returns ID of In Store Pickup shipping method
 * @returns {String}
 */
function getShippingMethodID() {
    return IN_STORE_PICKUP;
}

inStorePickupModule.init = function(shippingFormOpt){
	self = this;
	self.$storeOptions = $(options.storeSelect).children();
	self.shippingFormOpt = shippingFormOpt;
	self.storeFormOpt = {
		continueSelector: '[name$="storeshippingaddress_apply"]',
		formSelector: '[id$="storeshippingaddress"]'
	};

	initCache();
	processStateDisplay();
	initEvents();

	return this;
}

inStorePickupModule.isStoreDeliverySelected = isStoreDeliverySelected;
inStorePickupModule.getAddressFormData = getAddressFormData;
inStorePickupModule.getShippingMethodID = getShippingMethodID;

module.exports = inStorePickupModule;
