'use strict';

var $cache = {},
	options = {},
	isCreateExchangeSizes=false,
	addresscountry = require('./address/addresscountry'),
	util = require('./../util'),
	progress = require('./../../../../app_storefront_core/cartridge/js/progress');
var returnform = {
	init: function(params) {
		initializeConfig(params);
		if ($(options.returnForm).length) {
			initializeCache();
			toggleSubmitButton();
			changeEmailAddress();
			changeShippingAddress();
			deliveryServiceHandler();
			initializeEvents();
			unfreezeFrozenControls();
			addresscountry.hideStateField('.f-return');
			addresscountry.processStateDisplay('.f-return');
			addresscountry.showFullNameField('.f-return', true);
			initReturnResolution();
		}
	}
};

function initializeConfig(params) {
	$.extend(options, params || {});
}

function initializeCache() {
	$cache.returnForm = $(options.returnForm);
	$cache.deliveryServiceInput = $('.js_deliveryservice input');
	$cache.changeEmailAddress = $('.js-changeEmailAddress');
	$cache.changeShippingAddress = $('.js-changeShippingAddress');
	$cache.shippingAddress = $('.js-shippingAddress');
	$cache.newEmailAddress = $('.js-newEmailAddress');
	$cache.newShippingAddress = $('.js-shippingAddressFields');
	$cache.emailAddress = $('.js-emailAddress');
	$cache.cancelNewEmail = $('.js-cancelNewEmail');
	$cache.cancelNewShipping = $('.js-cancelNewShipping');
	$cache.shippinAddressForPickup = $('.js-shippinAddressForPickup');
	$cache.shippingAddressFields = $('.js-shippingAddressFields');
	$cache.selectItem = $('.js-selectItem');
	$cache.returnSubmitButton = $('.b-return-submit');
	$cache.returnBankTransfer = $('.js-return-bank');
	$cache.bicInput = $('#dwfrm_returnorder_bankTransfer_bic');
	$cache.ibanInput = $('#dwfrm_returnorder_bankTransfer_iban');
	$cache.emailInput = $('#dwfrm_returnorder_emailAddress');
	$cache.mandatoryReasons = $('.js-return_order-form') && $('.js-return_order-form').data() ? $('.js-return_order-form').data().mandatoryreasons : null;
	$cache.imgUrl = $('.js-return_order-form') && $('.js-return_order-form').data() ? $('.js-return_order-form').data().imgurl : null;
}

function initializeEvents() {
	$cache.deliveryServiceInput.on('change', deliveryServiceHandler);
	$cache.changeShippingAddress.on('change', changeShippingAddress);
	$cache.changeEmailAddress.on('change', changeEmailAddress);
	$cache.selectItem.on('change', onReturnItemSelect);
	$cache.cancelNewEmail.on('click', cancelNewEmail);
	$cache.cancelNewShipping.on('click', cancelNewShipping);
	$cache.returnForm.on('submit', submitReturnRequest);
	initValidation( $cache.returnForm);
}

function initializeEventsForDeleteItem($returnItem) {
		$returnItem.find('.js-delete_exchange_item').on('click', deleteExchangeItem);
}

/**
 * Enable form controls that were disabled until all form events are bound
 */
function unfreezeFrozenControls() {
		var $frozenControls = $cache.returnForm.find('.js-frozen-control');

		$frozenControls.each(function () {
				var $control = $(this);

				if ($control.is(':input')) {
						$control.removeAttr('disabled');
				} else {
						$control.find(':input').removeAttr('disabled');
				}

				$control.removeClass('js-frozen-control');
		});
}

function initValidation(form) {
	form.validate();

	if ($cache.returnBankTransfer.length) {
		$cache.bicInput.rules('add', {
			required: true,
			bankTransfer_bic: true
		});

		$cache.ibanInput.rules('add', {
			required: true,
			bankTransfer_iban: true
		});
	}

	$cache.emailInput.rules('add', {
		email: true
	});
}

function deliveryServiceHandler() {
	var checkedRadio = $('.js_deliveryservice input:checked');
	if (checkedRadio.val() == '1') {
		$cache.shippinAddressForPickup.hide();
	} else if(checkedRadio.length){
		$cache.shippinAddressForPickup.show();
	}
		toggleSubmitButton();
}

function changeShippingAddress() {
	if($cache.shippinAddressForPickup.find('input:checked').length) {
		$cache.changeShippingAddress.hide();
		$cache.shippingAddress.hide();
		$cache.shippingAddressFields.show();
	} else {
		$cache.shippingAddressFields.hide();
		$cache.changeShippingAddress.show();
		$cache.shippingAddress.show();
	}
}
function changeEmailAddress() {
	if($cache.changeEmailAddress.find('input:checked').length) {
		$cache.emailAddress.hide();
		$cache.newEmailAddress.show();
	} else {
		$cache.emailAddress.show();
		$cache.newEmailAddress.hide();
	}
}

function cancelNewEmail() {
	$cache.newEmailAddress.find('.input-text').val('');
	$cache.changeEmailAddress.find('input:checked').attr('checked', false);
	$cache.changeEmailAddress.trigger('change');
}

function cancelNewShipping() {
	$cache.changeShippingAddress.find('input:checked').attr('checked', false);
	$cache.changeShippingAddress.trigger('change');
}

function showAdditionalFields(e) {
	var returnItem = $(e.target).closest('.js-return-item');
	if (e.target.value == 'ORDER_EXCHANGE') {
				var $exchangeSizesWrapper = returnItem.find('.js-exchangeSizesWrapper');
				var isOnlyZeroSizeAvailable = $exchangeSizesWrapper.data('isonlyzerosizeavailable');

				if (!isOnlyZeroSizeAvailable) {
						returnItem.find('.js-returnItemQuantity select').attr("disabled","disabled");
						$exchangeSizesWrapper.show();
						var $exchangeItems = $exchangeSizesWrapper.find('.js-exchangeItem');

						if ($exchangeItems.length >= $exchangeSizesWrapper.data('allowablequantity')) {
								returnItem.find('.js-returnMore').hide();
						}
						if ($exchangeItems.length == 1) {
								$($exchangeItems[0]).find('.js-delete_exchange_item').attr("disabled","disabled");
						}
						var returnItemQuantity = returnItem.find('.js-quantityForExchange');
						returnItemQuantity.val($exchangeItems.length);
				}
	} else {
		returnItem.find('.js-exchangeSizesWrapper').hide();
		returnItem.find('.js-returnItemQuantity select').removeAttr('disabled');
	}
	if ($cache.returnBankTransfer.length) {
		toggleBankTransferFields();
	}
}

/**
 * Enables/disables form submit button based on form state
 */
function toggleSubmitButton() {
		if ($cache.returnForm.find('.js-selectItem input:checked').length) {
				$cache.returnSubmitButton.removeAttr('disabled');
		} else {
				$cache.returnSubmitButton.attr("disabled", "disabled");
		}
}

function returnMore(e){
	e.preventDefault();
	var returnMore = $(e.target);
	$.ajax({
		url: returnMore.attr('href')
	}).done(function(data){
		returnMore.closest('.js-return-item').find('.js-exchangeItems').append(data);
		var $exchangeSizesWrapper = $(e.target).closest('.js-exchangeSizesWrapper');
		var $exchangeItems = $exchangeSizesWrapper.find('.js-exchangeItem');
		if ($exchangeItems.length >= $exchangeSizesWrapper.data('allowablequantity')) {
			returnMore.hide();
		}
		if ($exchangeItems.length > 1) {
			$($exchangeItems[0]).find('.js-delete_exchange_item').removeAttr('disabled');
		}
		var returnItemQuantity = $(e.target).closest('.js-return-item').find('.js-quantityForExchange');
		returnItemQuantity.val($exchangeItems.length);
		initializeEventsForDeleteItem(returnMore.closest('.js-return-item'));
	});

}

function handleAvailabilityMsg(e){
	var $sizeSelect = $(e.target);
	$sizeSelect.removeClass('error');
	var $exchangeSizesWrapper = $sizeSelect.closest('.js-exchangeSizesWrapper');
	if (!$exchangeSizesWrapper.find('select.error').length) {
		$exchangeSizesWrapper.find('.js-exchange_sizes_error').addClass('hidden').removeClass('warning-message');
	}
}

function deleteExchangeItem(e) {
	var $exchangeSizesWrapper = $(e.target).closest('.js-exchangeSizesWrapper');
	$(e.target).closest('.js-exchangeItem').remove();
	var $exchangeItems = $exchangeSizesWrapper.find('.js-exchangeItem');
	if ($exchangeItems.length < $exchangeSizesWrapper.data('allowablequantity')) {
		$exchangeSizesWrapper.find('.js-returnMore').show();
	}
	if ($exchangeItems.length == 1) {
		$($exchangeItems[0]).find('.js-delete_exchange_item').attr("disabled","disabled");
	}
	if (!$exchangeSizesWrapper.find('select.error').length) {
		$exchangeSizesWrapper.find('.js-exchange_sizes_error').addClass('hidden').removeClass('warning-message');
	}
	var returnItemQuantity = $exchangeSizesWrapper.find('.js-quantityForExchange');
	returnItemQuantity.val($exchangeItems.length);
}

/**
 * Return item selection event handler
 *
 * @param {Event} e Event
 */
function onReturnItemSelect(e) {
		var $returnItem = $(e.target).closest('.js-return-item');

		toggleSubmitButton();
		toggleResolutionOptions($returnItem);
}

/**
 * Shows/hides resolution options for a return item
 *
 * @param {jQuery} $returnItem Return item
 */
function toggleResolutionOptions($returnItem) {
		var $returnItemCheckbox = $returnItem.find('.js-selectItem input');

		if ($returnItemCheckbox.is(':checked')) {
				$returnItem.find('.js-itemsDetailsForReturn').removeClass('hidden');
				$returnItem.find('input[value="REFUND"]').addClass('js-visibleRefund');
		} else {
				$returnItem.find('.js-itemsDetailsForReturn').addClass('hidden');
				$returnItem.find('input[value="REFUND"]').removeClass('js-visibleRefund');
		}

		toggleBankTransferFields();
}

/**
 * Shows/hides bank transfer fields
 */
function toggleBankTransferFields() {
		var $selectedRefundOpts = $('.js-visibleRefund[value="REFUND"]:checked');

		if ($selectedRefundOpts.length) {
				$cache.returnBankTransfer.show();
		} else {
				$cache.returnBankTransfer.hide();
		}
}

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);

	while(n--){
		u8arr[n] = bstr.charCodeAt(n);
	}

	return new File([u8arr], filename, {type:mime});
}

function retrieveUploadedImages($checkedItems) {
	var formData = new FormData();
	var orderId = $('.b-title_box-title').text().substring(1).toLowerCase();

	$checkedItems.each(function(index, item) {
		$(item).find('.MultiFile-preview').each(function(i, imgItem) {
			var fileName = orderId +'_file_' + index + '_' + i;
			var file = dataURLtoFile($(imgItem).attr('src'), fileName + '.jpg');
			formData.append(fileName, file);
		});
	});

	return formData;
}

function submitReturnRequest(e) {
	var $form = $('.js-return_order-form');
	var $checkedItems = $form.find('.js-selectItem input:checked').closest('.js-return-item');
	var attachedImages = $checkedItems.find('.MultiFile-preview');
	if (checkImgUploadingMandatory($checkedItems)) {
		e.preventDefault();
	}

	if (!options.uploadedImages && attachedImages.length) {
		e.preventDefault();
		var formData = retrieveUploadedImages($checkedItems);

		$.ajax({
			type: 'POST',
			url: $cache.imgUrl,
			data: formData,
			processData: false,
			contentType: false
		}).success(function (response) {
			options.uploadedImages = true;
			$( ".b-return-submit" ).trigger("click");
		})
	}
	if (!isCreateExchangeSizes && $checkedItems.length) {
		var isExchangeSizesAvailable=true,
			exchangeCaseIndex=0;
		$checkedItems.each(function() {
			if ($(this).find('.js-resolution input:checked').val() == 'ORDER_EXCHANGE') {
				e.preventDefault();
								var isOnlyZeroSizeAvailable = $(this).find('.js-exchangeSizesWrapper').data('isonlyzerosizeavailable');
				var $exchangeItems = $(this).find('.js-exchangeItem');
				var exchangeSizes = [];
				var variationGroupID = $(this).find('.js-variationGroupID').val();
								var $that = $(this);

								if (isOnlyZeroSizeAvailable) {
										//getting available product size
										var sizes = $(this).find('.js-exchangeSizesWrapper').data('productsizes');
										if (sizes) {
												//Item quantity for order exchange
												var returnItemQuantity = parseInt($that.find('.js-returnItemQuantity .input-select').val());
												//adding available product size for order exchange to array for submitting
												for (var i = 0; i < returnItemQuantity; i++){
														exchangeSizes.push(sizes[Object.keys(sizes)[0]]);
												}
										}
								} else {
										$exchangeItems.each(function() {
												exchangeSizes.push($(this).find('select').val());
												$that.find('.js-exchangeSizes').val(exchangeSizes.join(', '));
										})
								}

				$.ajax({
					type: 'POST',
					url: util.ajaxUrl(Urls.checkExchangeAvailability),
					data: 'variationGroupID='+variationGroupID+'&sizes='+exchangeSizes.join(',')
				}).success(function (response) {
					exchangeCaseIndex++;
					if (!response.success) {
						$that.find('.js-exchange_sizes_error').removeClass('hidden').addClass('warning-message');
						isExchangeSizesAvailable = false;
						var lowInStockSizesObj = JSON.parse(response.error),
							$sizeSelect,
							size;
						$exchangeItems.each(function(index) {
							$sizeSelect = $(this).find('select');
							size = $sizeSelect.val();
							if (lowInStockSizesObj.hasOwnProperty(size) && index >= +lowInStockSizesObj[size]) {
								$sizeSelect.addClass('error');
							}
						});
					}
					if (isExchangeSizesAvailable && (exchangeCaseIndex == $checkedItems.find(".js-resolution input:checked[value='ORDER_EXCHANGE']").length) ) {
						isCreateExchangeSizes = true;
						$( ".b-return-submit" ).trigger("click");
					}
				});
			}
		});
	}
}

function checkImgUploadingMandatory($checkedItems) {
	var isError = false;
	$('.js-upload-images-error').addClass('hidden');

	$checkedItems.each(function(i, item) {
		var uploadEl = $(item).find('.js-upload-images');
		var isRequired = uploadEl.attr('required');
		var attachedImages = $(item).find('.MultiFile-preview');

		if (!attachedImages.length && isRequired) {
			$($(item).find('.js-upload-images-error')).removeClass('hidden');
			isError = true;
		}
	});
	return isError;
}

/**
 * @function
 * @description init update view of return resolution form
 */
function initReturnResolution() {
		$('.js-return-reason').on('change', function(e) {
				updateReturnResolution($(this).parents('.js-return-item'), e.currentTarget.value)
		});
		var $returnItems = $('.js-return-item');
		$returnItems.each(function() {
				updateReturnResolution($(this));
		});
}

/**
 * @function
 * @description update view of return resolution form
 */
function updateReturnResolution($returnItem, returnReason) {
		var $returnResolution = $returnItem.find('.js-returnresolution-form'),
				$formWrapper = $('#primary'),
				indexItem = $returnItem.find('input[name$="_indexItem"]').val(),
				pliloopstateIndex = $returnItem.find('input[name$="_pliloopstateIndex"]').val(),
				url = util.appendParamsToUrl(Urls.returnResolution, {
						returnReason: returnReason,
						indexItem: indexItem,
						pliloopstateIndex: pliloopstateIndex
				});

		$formWrapper.css("position", "relative");
		progress.show($formWrapper);
		$returnResolution.load(url, function() {
				progress.hide();
				$formWrapper.css("position", "unset");
				$returnItem.find('.js-resolution').on('change', showAdditionalFields);
				$returnItem.find('.js-returnMore').on('click', returnMore);
				$returnItem.find('.js-exchangeItems select').on('change', handleAvailabilityMsg);
				initializeEventsForDeleteItem($returnItem);
				toggleResolutionOptions($returnItem);
	});
	if ($cache.mandatoryReasons && returnReason) {
		var uploadBtn = $returnItem.find('.js-upload-images');
		if ($cache.mandatoryReasons.indexOf(returnReason) > -1) {
			$(uploadBtn).prop('required', true);
		} else {
			$(uploadBtn).removeAttr('required');
		}
	}
}


module.exports = returnform;
