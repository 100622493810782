'use strict';

/**
 * Handles Contact Us forms (native SF and Service Cloud ones). 
 * A separate version of this module exists for forms opened in an iframe:
 * dw-plein\app_storefront_core_UI\cartridge\static\default\framesstatic\contactus.js
 */
var $cache = {},
  reCAPTCHA = require('./../recaptcha'),
  util = require('./../util'),
  contactUsValidator,
  contactUsForm = {
    init: function() {
      initCache();
      initEvents();
    }
  };

function initCache() {
  $cache.contactUsForm = $('.js-contact-form');
  $cache.serviceCloudForm = $('#serviceCloudForm');
  $cache.formRecaptcha = $('.js-recaptcha');
  $cache.validatorMsgs = $('#js-validator-messages');
  $cache.phoneCountry = $('.js-phone-field select');
  $cache.phoneInput = $('.js-phone-field input');
}

function initEvents() {
  // Handle native contact form
  if ($cache.contactUsForm.length) {
    initValidation($cache.contactUsForm);

    $cache.phoneCountry.on('change', function() {
      if ($cache.phoneInput.val().length) {
        // Phone country code has changed - trigger phone validation
        contactUsValidator.element($cache.phoneInput);
      }
    });
  }
  // Handle Service Cloud contact form
  if ($cache.serviceCloudForm.length && $cache.formRecaptcha) {
    validateServiceCloudForm($cache.serviceCloudForm);
  }
}

/* 
* This validates native SF contactus
*/
function initValidation($form) {
  var customMessages = $cache.validatorMsgs.data('messages'),
    options = {
      ignore: [':hidden:not(' + reCAPTCHA.getResponseInputSelector() + ')'],
      onfocusout: function(element) {
        if (!this.checkable(element)) {
          this.element(element);
        }
      },
      errorPlacement: function($errorLabel, $input) {
        if (reCAPTCHA.isResponseInput($input)) {
          // custom placement for CAPTCHAs
          reCAPTCHA.showError($errorLabel, $input);
        } else {
          // default placement
          $errorLabel.insertAfter($input);
        }
      },
      invalidHandler: function(event, validator) {
        var $input;
        if (validator.numberOfInvalids() === 1) {
          $input = $(validator.findLastActive() || validator.errorList.length && validator.errorList[0].element || []);

          if (reCAPTCHA.isResponseInput($input)) {
            reCAPTCHA.focusResponseInput($input);
          }
        }
      },
      submitHandler: function() {
        submitForm();
      }
    };

  if (customMessages) {
    $.extend($.validator.messages, customMessages);
  }

  contactUsValidator = $form.validate(options);
}

/* 
* This submits native SF contactus
*/
function submitForm() {
  var $form = $cache.contactUsForm,
    $formWrapper = $('#primary'),
    submitUrl = $form.attr('action'),
    formData,
    $receivedForm,
    submitBntName = $form.find('button').attr('name');

  formData = $form.serialize();
  formData += '&' + submitBntName + '=';
  formData += '&format=ajax';

  $.ajax({
    url: submitUrl,
    data: formData,
    method: "POST"
  }).done(function(data) {
    $form.off('submit');
    $formWrapper.html(data);

    $receivedForm = $formWrapper.find('.js-contact-form');

    if ($receivedForm.length) {
      contactUsForm.init();
      util.limitCharacters();
      reCAPTCHA.init();
    }

  });
}

/* 
* This validates order number, honeypot & reCaptcha 
* of Service Cloud form (which has an external action)
* app_storefront_core_ext/cartridge/templates/default/content/serviceCloudContactUsForm.isml,
* the other required fields here are validated by general jquery.validate 
*/
function validateServiceCloudForm($form) {
  var errPlaceholder = $('#recaptcha-error'),
      orderNumErr = $('#orderNumError'),
      orderNum = $('#00N0900000IQ6iS'),
      msgArea = $('#00N0900000IQ6iR'),
      pot = $('#valCode'),
      orderNumVal = '',
      msgAreaVal = '',
      potVal = '',
      orderNumRegex = /^(PP|PO|SO|PS|BLN)\d{10}$/,
      msgAreaRegex = /\s/,
      isCaptchaSolved = false,
      errTxt = $cache.validatorMsgs ? 
        $cache.validatorMsgs.data("messages").recaptcha : $cache.validatorMsgs.data("messages").required;
  
  $form.on('submit', function(e) {
    isCaptchaSolved = reCAPTCHA.getResponse($cache.formRecaptcha) !== '' ? true : false;
    orderNumVal = orderNum.val();
    msgAreaVal = msgArea.val();
    potVal = pot.val();
    // Check captcha
    if (!isCaptchaSolved) {
      e.preventDefault();
      reCAPTCHA.showErrorCustom(errPlaceholder, errTxt);
    }
    // Validate order number pattern & show relevant error
    if (orderNumVal !== '' && !orderNumRegex.test(orderNumVal)) {
      e.preventDefault();
      orderNumErr.show();
    } else if (orderNumVal === '' || (orderNumVal !== '' && orderNumRegex.test(orderNumVal))){
      orderNumErr.hide();
    }
    // Check if message area contains at least one whitespace
    if (msgAreaVal !== '' && !msgAreaRegex.test(msgAreaVal)) {
      e.preventDefault();
    }
    // Check if honeypot isn't filled in
    if (potVal !== '') {
      e.preventDefault();
    }
    // A specific spam attack check: when last options are selected for all 3 dropdowns
    if($('#00N0900000IQ6iN').val() === 'Åland Islands (+358)' && 
      $('#00N0900000IQ6iW').val() === 'Arabic' &&
      $('#00N0900000IQ6iX').val() === 'Other'){
      e.preventDefault();
    }
  });
}

module.exports = contactUsForm;
