'use strict';

var $cache = {},
	ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	util = require('./../../util');

function initializeCache() {
    $cache.zipCodeInput = $('#dwfrm_singleshipping_shippingAddress_addressFields_postal');
    $cache.isZipCodeAvailableForCountryInput = $('#js-is-zipcode-available-for-country');
}

function validateZipCode (value) {
    var url = util.appendParamsToUrl(Urls.validateZipCode, {
        zipcode: $.trim($(value).val())
    });

    return {
        url: url,
        type: 'POST',
        dataType: 'json',
        data: {error: Resources.VALIDATE_ZIPCODE}
    };
}

function isValidationApplicable() {
    return $cache.zipCodeInput.length !== 0 && $cache.zipCodeInput.is(':visible');
}

module.exports = {
	init: function () {
        initializeCache();

        if (isValidationApplicable()) {
            var rules = {
                required: true
            };

            if ( $cache.isZipCodeAvailableForCountryInput.val() == 'true' ) {
                rules['remote'] = validateZipCode;
            }

            $cache.zipCodeInput.rules('add', rules);
        }
    }
};
