'use strict';

var $cache = {},
    options = {
        addToCart: '.js-add-to-cart',
        addToCartDisabled: '.js-add-to-cart-disabled',
        addVariantToCart: '.js-add-variant-to-cart',
        sizeErrorMessage: '.js-select_size-message',
        productActionButtons: '.js-product_actions',
        pdpMainSelector: '#pdpMain',
        addAllToCart: '.js-add-all-to-cart',
        swatches: '.js-swatches',
        swatchSelected: '.js-swatch-selected',
        variations: '.js-variations',
        errorClass: 'error',
        tooltip: '.js-options-tooltip',
        stickedClass: 'm-sticked',
        fullWidthClass: 'm-full_width',

        quantityFieldSelector: 'input[name="Quantity"]',
        personaliseProductClass: 'js-personalised_product',

        pdpVariationsAttr: '.js-pdp-attribute',
        pdpSizeVariationAttr: '.attribute-size'
    },

    dialog = require('./../../dialog'),
    page = require('./../../page.js'),
    util = require('./../../util'),
    layout = require('./../../layout');

var addtocart = {
    init: function(params) {
        initializeConfig(params);

        initializeCache();
        initializeEvents();

        $(options.addToCart + ':not(.js-add-variant-to-cart)[disabled]').attr('title', $('.availability-msg').text());
        $(options.addVariantToCart).removeAttr('disabled').attr('type','submit');
    }
};

function initializeConfig(params) {
    $.extend(options, params || {});
}

function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.pdpMain = $(options.pdpMainSelector);
    $cache.addToCart = $(options.addToCart);
    $cache.addToCartDisabled = $(options.addToCartDisabled);
    $cache.addAllToCart = $(options.addAllToCart);
    $cache.productActionButtons = $(options.productActionButtons);
}

function initializeEvents() {
    deInitializeEvents();

    $cache.document.on('click', options.addToCart, addToCartHandler);
    $cache.document.on('click', options.addToCartDisabled, addToCartDisabledHandler);
    $cache.addAllToCart.off('click');
    $cache.document.on('click', options.addAllToCart, addAllToCartHandler);
    $cache.document.on('product.variation.changed', initializeCache);
}

// Disable double event listeners
// cancelling those added on page load by ~/js/components-config.js
function deInitializeEvents() {
  $cache.document.off('click', options.addToCart, addToCartHandler);
  $cache.document.off('click', options.addToCartDisabled, addToCartDisabledHandler);
  $cache.document.off('click', options.addAllToCart, addAllToCartHandler);
  $cache.document.off('product.variation.changed', initializeCache);
}
/**
 * @description Handler to handle the add to cart event
 */
function addToCartHandler(e) {
    e.preventDefault();
    var $actionButton = $(this);
    var $form = $actionButton.closest('form');
    var isQuickView = $actionButton.closest('#product-content').hasClass("js-product-content-quickview");

    if(isQuickView){
      $form = $('.js-product-content-quickview form');
    }

    if($form.valid()) {
        $cache.document.trigger('before.additem.tocart');
    } else {
        $cache.document.trigger('form.addtocart.invalid');
        return;
    }

    addItemToCart($form).fail(function (xhr, textStatus) {
        if (xhr.status === 302 && xhr.responseText) {
            try {
                var responseJSON = JSON.parse(xhr.responseText);
                if (responseJSON && responseJSON.redirectUrl) {
                    window.location.href = responseJSON.redirectUrl;
                }
            } catch (error) {
                window.alert(Resources.BAD_RESPONSE);
            }
        }
    }).then(function (response) {
        var $uuid = $form.find('input[name="uuid"]');
        if ($uuid.length > 0 && $uuid.val().length > 0) {
            page.refresh();
        } else {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.closeAll();
            }
            $cache.document.trigger('product.addedToCart', [ response, $actionButton, $form]);
        }
    }.bind(this));
}

/**
 * @description Handler to handle the click on the disabled add to cart button
 */
function addToCartDisabledHandler(e) {
  $(options.sizeErrorMessage).show();

  //show size variation block
  var $pdpVariations = $cache.pdpMain.find(options.pdpVariationsAttr),
    $pdpSizeVariationToggle = $pdpVariations.filter(options.pdpSizeVariationAttr).find('.toggle');

  if (!$pdpSizeVariationToggle.hasClass('expanded')) {
    $pdpVariations.find('.toggle').removeClass('expanded');
    $pdpSizeVariationToggle.addClass('expanded');
  }
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
function addItemToCart(form) {
    var $form = $(form),
        $qty = $form.find( options.quantityFieldSelector );

    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }

    return $.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    });
}

/**
 * @description Handler to handle the add all items to cart event
 */
function addAllToCartHandler(e) {
    var $productForms = [];
    var $allProductForms = $('#product-set-list').find('form').each(function() {
        $productForms.push(this);
    });
    e.preventDefault();

    if(!$productForms.length) {
        return;
    }

    addAllToCart($productForms, $productForms.pop());
}

function addAllToCart($allForms, $form) {
    addItemToCart($form).then(function(response) {
        if($allForms.length == 0) {
            dialog.closeAll();
            // show the final response only, which would include all the other items
            $cache.document.trigger('product.addedToCart', response);
        } else {
            addAllToCart($allForms, $allForms.pop());
        }
    });
}

module.exports = addtocart;
