/** The references object of all modules needed for components initialization */
var references = {
	/** Components from extension cartridge */
		'global.custom': require('./global.custom'),
    'header': require('./header'),
};

/** The components initialization configuration object */
var configuration = {
	'global': {
		'components': {
			'global.custom': {},
      'header' : {},
		}
	},
	'product': {
		'components': {
			'carousel': {},
			'image': {
				"options": {
					"enableImgDots": false,
				}
			}
		}
	}
};

module.exports = {
	'configuration': configuration,
	'references': references
};
