'use strict';

var ajax = require('./../../../../../app_storefront_core/cartridge/js/ajax'),
	tls = require('./../../tls'),
	util = require('./../../util');

/**
 * @description loads product's navigation
 **/
module.exports = function () {
	var $pidInput = $('.pdpForm input[name="pid"]').last(),
		$navContainer = $('#product-nav-container'),
		pid = $('.js_product-pdp-number').find('[data-masterid]').data('masterid'),
        cgid = util.getParameterValueFromUrl('cgid'),
		hash, navCookie, navCookieParts, url;

	if (window.location.hash.length >= 1) {
		hash = window.location.hash.substr(1);
		tls.setCookie('productNavHash', pid + '|' + hash);
	} else {
		navCookie = tls.getCookie('productNavHash');
		if (typeof navCookie === 'string') {
			navCookieParts = navCookie.split('|');
			if (navCookieParts[0] === pid) {
				hash = navCookieParts[1];
			}
		}
	}

	// if no hash exists, or no pid exists, or nav container does not exist, return
	if (!hash || $pidInput.length === 0 || $navContainer.length === 0) {
		return;
	}

	pid = $pidInput.val();
	url = (!!cgid) ?
		util.appendParamsToUrl(Urls.productNav + '?' + hash, {'pid' : pid, 'cgid': cgid}) :
		util.appendParamToURL(Urls.productNav + '?' + hash, 'pid', pid);

	ajax.load({
		url: url,
		target: $navContainer
	});
};