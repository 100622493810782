'use strict';

var util = require('./../../util'),
	shipping = require('./../../../../../app_storefront_core/cartridge/js/pages/checkout/shipping'),
	selectors = {
		addressForm: 'form.address',
		addressSelect: 'select[name$="_addressList"]',
		countrySelect: 'select[name$="_country"]',
	},
	$cache = null;

/**
 * Initializes cache
 */
function initCache() {
	$cache = {};

	$cache.addressForm = $(selectors.addressForm);
}

/**
 * Initializes address selector
 * 
 * @todo Move all CSS selectors to a corresponding module config
 */
function initAddressSelector() {
	$(selectors.addressSelect, $cache.addressForm).on('change', function () {
		var $creditCard = $('[data-method="CREDIT_CARD"]'),
			isCreditCardExist = $creditCard.length > 0,
			creditCardType; 
			
		var selected = $(this).children(':selected').first();
		
		var selectedAddress = $(selected).data('address'),
			$addressFieldsEl = $('*[name*="_addressFields_"]'),
			$emailFieldElement = $('*[name*="_email_"]');
		
 		if (!selectedAddress) { return; }
 		
 		//save cc type
 		creditCardType = isCreditCardExist ? $creditCard.find('select[name$="_type"] option:selected').val() : null;
 		
 		util.fillAddressFields(selectedAddress, $cache.addressForm);
 		shipping.updateShippingMethodList();
 		
 		// re-validate the address fields
		if($addressFieldsEl.length){
			$addressFieldsEl.valid();
		}
		
		if($emailFieldElement.length){
			$emailFieldElement.valid();
		}
		
		$cache.addressForm.find('input:visible').change();
		
		//restore cc type
		creditCardType && $creditCard.find('select[name$="_type"]').val(creditCardType);
 	});
}

/**
 * Initializes country selector
 */
function initCountrySelector() {
    $(selectors.countrySelect, $cache.addressForm).on('change', function () {
		var countryCode = $(this).val() || '';

        $cache.addressForm.trigger('form.address.country.change', {
            countryCode: countryCode
        });
    });
}

/**
 * @function
 * @description Selects the first address from the list of addresses
 */
exports.init = function () {
	initCache();

	//do not delete, saved for future implementation of global post code validation
	//$('input[name$="_postal"]', $form).addClass('postalcode');

	initAddressSelector();
	initCountrySelector();
};
